import React from "react";
import Nav from "./Nav";
import { Container, makeStyles } from "@material-ui/core";
import TopBar from "./Topbar";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "calc(100vh - 94px)",
        width: "100%",
        marginTop: 95,
        overflowY: "hidden",
        overflowX: "hidden",
    },
    withoutHeader: {
        minHeight: "100vh",
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        backgroundColor: "#FFFFFF",
    },
    container: {
        display: "flex",
        backgroundColor: "#FAFAFA",
    },
    pageContainer: {
        backgroundColor: "#FAFAFA",
    },
}));

export default function Main(props) {
    const classes = useStyles();
    console.log(props.pageTitle);
    return (
        <>
            <Container maxWidth={false} disableGutters className={classes.pageContainer}>
                <TopBar pageTitle={props.pageTitle} />
                <div className={classes.container}>
                    <Nav />

                    {/* <motion.div
                        initial={{ opacity: 0, x: "-100vh" }}
                        animate={{ opacity: 1, x: 0 }}
                    > */}
                    <div className={classes.root}>
                        <props.comp {...props} />
                    </div>

                    {/* </motion.div> */}
                </div>
            </Container>
        </>
    );
}
