/* eslint-disable default-case */
import { INVOICE_LIST_LOADED, INVOICE_LIST_LOADING } from "redux/actionTypes/actionTypes";

const initialState = {
    invoices: [],
    invoicesIsLoading: false,
};

export const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case INVOICE_LIST_LOADED:
            return {
                ...state,
                invoices: action.payload,
            };
        case INVOICE_LIST_LOADING:
            return {
                ...state,
                invoicesIsLoading: action.payload,
            };
        default:
            return state;
    }
};
