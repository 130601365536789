import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { fetchReverseRowsMovedByDate, fetchRowsMovedByDate } from "redux/actions/dashboardActions";
import ChartComponent from "./ChartComponent";
// import FeatureFlagHOC from "services/featureFlagHOC";
import moment from "moment";
const useStyles = makeStyles(() => ({
    tabs: {
        marginBottom: 1,
        marginTop: 5,
    },
    tab: {
        marginBottom: 40,
    },
}));

/**
 *
 * @param {*} props
 * @returns - This function Returns a "div" which acts as Panel for Tabs. Tabs are also provided IDs and other properties.
 */

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

/**
 *
 * @param {*} index
 * @function - This function recives an index param and returns ID based on the param, which helps in styling the HTML component to which the ID was returned.
 * @returns - ID
 */

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

/**
 *
 * @param {*} data
 * @function - Receives "data" Array, maps the array to create a new Array containing objects with appropriate keys.
 * @returns - parsedData Array.
 */

const parseData = (data) => {
    let parsedData = [];
    data.forEach((el) => {
        parsedData.push({
            name: el.datasource_name,
            rowsMoved: (el.rows_moved / 1000000).toFixed(2),
        });
    });
    return parsedData;
};

/**
 *
 * @param {*} data
 * @param {*} timezone
 * @function - Receives "data" Array and Timezone, maps the array to create a new Array containing objects with appropriate keys.
 * @returns - parsedData Array.
 */

const parseDateData = (data, timezone) => {
    let parsedData = [];
    data?.forEach((el) => {
        parsedData.push({
            date: moment(el.date).format("dddd MMM DD YYYY"),
            rowsMoved: (el.rows_moved / 1000000).toFixed(2),
        });
    });
    return parsedData;
};

/**
 *
 * @param {*} data
 * @function - Receives "data" Array, maps the array to create a new Array containing objects with appropriate keys.
 * @returns - parsedData Array.
 */

const parseReverseETLData = (data) => {
    let parsedData = [];
    data.forEach((el) => {
        parsedData.push({
            name: el.display_label,
            rowsMoved: (el.rows_moved / 1000000).toFixed(2),
        });
    });
    return parsedData;
};

export default function DataModel({ close, from, to, account_id ,workspace_id}) {
    const classes = useStyles();
    const timezone = useSelector((state) => state?.users?.timezone);
    const dispatch = useDispatch();
    const [higherTabValue, setHigherTabValue] = React.useState(0);
    const [lowerTabValue1, setLowerTabValue1] = React.useState(0);
    const [lowerTabValue2, setLowerTabValue2] = React.useState(0);
    const dashboard = useSelector((state) => state.dashboard);
    const dataMovedArray = parseData(dashboard.dataSources);
    const theme = useTheme();
    const handleLowerTabChange1 = (event, newValue) => {
        setLowerTabValue1(newValue);
    };
    const handleLowerTabChange2 = (event, newValue) => {
        setLowerTabValue2(newValue);
    };
    const handleHigherTabChange = (event, newValue) => {
        setHigherTabValue(newValue);
        setLowerTabValue1(0);
        setLowerTabValue2(0);
    };
    const rowsMovedByDate = parseDateData(dashboard.rowsMovedDatewise, timezone);
    const reverseRowsMovedByDate = parseDateData(dashboard.reverseRowsMovedDatewise, timezone);
    const reverseRowsMovedToDestinations = parseReverseETLData(dashboard.dataDestinations);

    /**
     * @function - This useEffect fetches Rows moved by dates on component mount.
     */

    React.useEffect(() => {
        const toString = moment(from).format("YYYY-MM-DD") + " 23:59:59";
        const fromString = moment(to).format("YYYY-MM-DD") + " 00:00:00";
        const queryString = `?from_date=${fromString}&to_date=${toString}`;
        dispatch(fetchRowsMovedByDate(queryString, account_id,workspace_id));
    }, []);

    /**
     * @function - This useEffect fetches (Reverse Rows and Forward Rows) moved by dates on component mount and whenever date range is changed.
     */

    React.useEffect(() => {
        const toString = moment(from).format("YYYY-MM-DD") + " 23:59:59";
        const fromString = moment(to).format("YYYY-MM-DD") + " 00:00:00";
        const queryString = `?from_date=${fromString}&to_date=${toString}`;
        dispatch(fetchRowsMovedByDate(queryString, account_id,workspace_id));
        dispatch(fetchReverseRowsMovedByDate(queryString, account_id,workspace_id));
    }, [from, to,workspace_id]);

    return (
        <Grid container justify="center" className="padding" style={{ padding: 20 }}>
            <Grid item xs={12}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography className="size16 boldText">
                            Rows Moved (In Millions){" "}
                            {dashboard.rowsMovedByDateIsLoading || dashboard.reverseRowsMovedByDateIsLoading ? (
                                <CircularProgress style={{ marginBottom: -5 }} size={18} />
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CloseIcon className="hoverable" onClick={() => close()} fontSize="small" />
                    </Grid>
                </Grid>
                {/* Higher tabs */}
                <Box className="tabs" style={{ marginBottom: 20 }}>
                    {/* <FeatureFlagHOC requiredFeature={["REVERSE"]}> */}
                    <Tabs
                        value={higherTabValue}
                        onChange={handleHigherTabChange}
                        indicatorColor="primary"
                        centered={false}
                        className={classes.tabs}
                    >
                        <Tab label="ETL" {...a11yProps(0)} />
                        <Tab label="Reverse ETL" {...a11yProps(1)} />
                    </Tabs>
                    {/* </FeatureFlagHOC> */}
                </Box>
                {/* 1st Higher Tab Pannel */}
                <TabPanel className={classes.tab} value={higherTabValue} index={0} dir={theme.direction}>
                    {/* lower tabs */}
                    <Box className="tabs" style={{ marginBottom: 20 }}>
                        <Tabs
                            value={lowerTabValue1}
                            onChange={handleLowerTabChange1}
                            indicatorColor="primary"
                            centered={false}
                            className={classes.tabs}
                        >
                            <Tab label="By Datasource" {...a11yProps(0)} />
                            <Tab label="By Date" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    {/* 1st Lower Tab Pannel */}
                    <TabPanel className={classes.tab} value={lowerTabValue1} index={0} dir={theme.direction}>
                        <Grid container justify="center">
                            <Grid item>
                                <ChartComponent
                                    data={dataMovedArray}
                                    containerHeight={320}
                                    xLabelValue="Data Sources"
                                    xLabelOffset={-50}
                                    yLabelValue="Rows Moved"
                                    yLabelOffset={10}
                                    dataKey="rowsMoved"
                                    isByDate={false}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* 2nd Lower Tab Pannel */}
                    <TabPanel className={classes.tab} value={lowerTabValue1} index={1} dir={theme.direction}>
                        <Grid container justify="center">
                            <Grid item style={{ position: "relative", top: 30 }}>
                                <ChartComponent
                                    data={rowsMovedByDate}
                                    containerHeight={360}
                                    containerStyle={{ top: 30 }}
                                    xLabelValue="Date"
                                    xLabelOffset={10}
                                    yLabelValue="Rows Moved"
                                    yLabelOffset={10}
                                    dataKey="rowsMoved"
                                    isByDate={true}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabPanel>
                {/* 2nd Higher Tab Pannel */}
                {/* <FeatureFlagHOC requiredFeature={["REVERSE"]}> */}
                <TabPanel className={classes.tab} value={higherTabValue} index={1} dir={theme.direction}>
                    {/* lower tabs */}
                    <Box className="tabs" style={{ marginBottom: 20 }}>
                        <Tabs
                            value={lowerTabValue2}
                            onChange={handleLowerTabChange2}
                            indicatorColor="primary"
                            centered={false}
                            className={classes.tabs}
                        >
                            <Tab label="By Datasource" {...a11yProps(0)} />
                            <Tab label="By Date" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    {/* 1st Lower Tab Pannel */}
                    <TabPanel className={classes.tab} value={lowerTabValue2} index={0} dir={theme.direction}>
                        <Grid container justify="center">
                            <Grid item>
                                <ChartComponent
                                    data={reverseRowsMovedToDestinations}
                                    containerHeight={320}
                                    xLabelValue="Data Sources"
                                    xLabelOffset={-50}
                                    yLabelValue="Rows Moved"
                                    yLabelOffset={10}
                                    dataKey="rowsMoved"
                                    isByDate={false}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* 2nd Lower Tab Pannel */}
                    <TabPanel className={classes.tab} value={lowerTabValue2} index={1} dir={theme.direction}>
                        <Grid container justify="center">
                            <Grid item style={{ position: "relative", top: 30 }}>
                                <ChartComponent
                                    data={reverseRowsMovedByDate}
                                    containerHeight={360}
                                    containerStyle={{ top: 30 }}
                                    xLabelValue="Date"
                                    xLabelOffset={10}
                                    yLabelValue="Rows Moved"
                                    yLabelOffset={10}
                                    dataKey="rowsMoved"
                                    isByDate={true}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabPanel>
                {/* </FeatureFlagHOC> */}
            </Grid>
        </Grid>
    );
}
