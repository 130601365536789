import { Button, ButtonBase, Grid, makeStyles, Switch } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PauseIcon from '@material-ui/icons/Pause';
import RowSelectTable from 'common/Table/RowSelectTable';
import UITable from 'common/Table/UiTable'
import React from "react";
import useApi from "hooks/useApi";
import Tooltip from "@material-ui/core/Tooltip";
// import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
// import MenuIcon from '@material-ui/icons/Menu';
// import GrayLayout from '../../assets/images/dashboard/layout-gray.svg'
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { changeStatusOfReportAdmin } from "services/Api/api";
import SelectColumnFilter from "../../../../common/Table/selectColumnFilter";
// import successSnackbar from "utils/successSnackbar";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction } from "redux/actions/NotifierActions";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
// import CheckPermissionFunction from "services/CheckPermissionFunction";
// import ConfiguredReportActionButtons from "components/common/ActionButtonGroups/ConfiguredReportActionButtons";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Config from "constants/config";
import Image from "../../../../common/UIComponents/Image";
import { fetchReportLogs } from "../../../../redux/actions/dashboardActions";
import moment from "moment";
import WarningPopup from "common/WarningPopup";
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";

const closeSnackbar = (dispatch, ...args) => dispatch(closeSnackbarAction(...args));
const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: 4,
        fontSize: 17,

        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
}));

const CustomSwitch = withStyles({
    switchBase: {
        color: "#A5AEB7",
        "&$checked": {
            color: "#23BE82",
        },
        "&$checked + $track": {
            backgroundColor: "#23BE82",
        },
    },
    checked: {},
    track: {},
})(Switch);

export default function ReportTable({ tableNavClick, datasourceSlug, account_id, logsData,workspace_id }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [statusLoading, setStatusLoading] = React.useState(false);
    const [reportLoading, setReportLoading] = React.useState(null);
    const [isSelected, setIsSelected] = React.useState(false)

    const [, resumeAccountIsLoading, resumeAllPipeline] = useApi();
    const [, pauseAccountIsLoading, pauseAllPipeline] = useApi();
    const [, deletePipelineIsLoading, deleteAllPipeline] = useApi()
    // const editPermission = CheckPermissionFunction(["report.edit"]);
    const [skipPageReset, setSkipPageReset] = React.useState(true);
    const [PauseConsentOpen, setPauseConsentOpen] = React.useState(false);
    const [ResumeConsentOpen, setResumeConsentOpen] = React.useState(false);
    const [deleteConsentOpen, setDeleteConsentOpen] = React.useState(false);

    let selecetedConfiguredId = []
    const resumePipelineHandler = async () => {
        try {
            await resumeAllPipeline({
                module: "bulkActions",
                method: "pipelineRun",
                returnResult: true,
                apiData: {
                    data: {
                        account_id: account_id,
                        configured_report_ids: selecetedConfiguredId
                    },

                    datasourceSlug: datasourceSlug
                },
                successToast: "Ran Selected Pipeline ",

            });
            dispatch(fetchReportLogs(logsData, account_id,workspace_id));

        }
        catch (e) {
            console.log(e)
        }
    };
    const pauseAllPipelineHandler = async () => {
        await pauseAllPipeline({
            module: "bulkActions",
            method: "pipelineStatusChange",
            returnResult: true,
            apiData: {
                data: {
                    account_id: account_id,
                    status: 'PAUSED',
                    configured_report_ids: selecetedConfiguredId
                },
                datasourceSlug: datasourceSlug
            },
            successToast: "Selected Pipeline Paused",

        })
        dispatch(fetchReportLogs(logsData, account_id));

    };
    const deleteAllPipelineHandler = async () => {
        await deleteAllPipeline({
            module: "bulkActions",
            method: "deleteAllPipeline",
            returnResult: true,
            apiData: {
                data: {
                    account_id: account_id,
                    configured_report_ids: selecetedConfiguredId
                },
                datasourceSlug: datasourceSlug
            },
            successToast: "Selected Pipeline Deleted",

        })
        dispatch(fetchReportLogs(logsData, account_id));

    };
    const handleStausChange = (checked, configured_report_id) => {
        let status = "";
        if (checked) {
            status = "ACTIVE";
        } else {
            status = "PAUSED";
        }
        const data = {
            status,
        };
        setStatusLoading(true);
        setReportLoading(configured_report_id);
        changeStatusOfReportAdmin(datasourceSlug, configured_report_id, data, account_id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(
                        enqueueSnackbarAction({
                            message: "Status changed successfully.",
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: "success",
                                action: (key) => (
                                    <CloseIcon
                                        className="hoverable"
                                        fontSize="small"
                                        onClick={() => closeSnackbar(dispatch, key)}
                                    />
                                ),
                            },
                        })
                    );
                    dispatch(fetchReportLogs(logsData, account_id));
                    tableNavClick(datasourceSlug);
                } else {
                    HttpErrorHandler(dispatch, "Status change failed. Please try again later.");
                }
                setStatusLoading(false);
                setReportLoading(null);
            })
            .catch((e) => {
                setStatusLoading(false);
                setReportLoading(null);
                if (e?.response?.data?.message === null || e?.response?.data?.message === undefined) {
                    HttpErrorHandler(dispatch, "Status change failed. Please try again later.");
                } else {
                    HttpErrorHandler(dispatch, e?.response?.data?.message);
                }
            });
    };
    const setSelectedCallback = (rows) => {
        let arr = []
        rows.forEach(element => {
            const ele = { ...element }
            arr.push(ele.original.id)
        });
        selecetedConfiguredId = arr
        console.log(selecetedConfiguredId)
    }
    const changeFormattingUnit = (unit) => {
        setSkipPageReset(true);
        switch (unit) {
            case "MB":
                setData((data) =>
                    data.map((el) => ({
                        ...el,
                        data_moved_readable: (el.data_moved / 1000000).toFixed(2) + " MB",
                    }))
                );
                break;
            case "GB":
                setData((data) =>
                    data.map((el) => ({
                        ...el,
                        data_moved_readable: (el.data_moved / 1000000000).toFixed(2) + " GB",
                    }))
                );
                break;
        }
    };
    const columns = [
        {
            Header: "Data Pipeline Name",
            accessor: "dataset_name",
            sortable: false,
            Actions: (props) => (<></>),
            Cell: (props) => (
                <Grid style={{ minWidth: 200 }} container justify="space-between" alignItems="center">
                    <Grid container xs={10} alignItems="center">
                        <Image
                            style={{ height: 20, width: 20, marginRight: 10 }}
                            src={`${Config.imageBaseUrl}images/connectors/icons/${datasourceSlug}.png`}
                            name={datasourceSlug}
                        />
                        {/* <Link to={`/datasource/${datasourceSlug}/detail/editreport/${props.row.original.id}`}> */}
                        <Typography
                            className={false ? "hoverable" : null}
                            // onClick={() =>
                            // 	editPermission
                            // 		? history.push(
                            // 				`/datasource/${datasourceSlug}/detail/editreport/${props.row.original.id}`
                            // 		  )
                            // 		: null
                            // }
                            variant="subtitle1"
                        >
                            {props.row.original.dataset_name}
                        </Typography>
                        {/* </Link> */}
                    </Grid>
                    {/* <Grid container xs={2} justify="flex-end">
						<Typography style={{ color: "#40C77E", marginRight: 15 }} variant="subtitle1">
							<img src={Check} />5{" "}
						</Typography>
						<Typography style={{ color: "#F77070" }} variant="subtitle1">
							<img src={Cross} />8{" "}
						</Typography>
					</Grid> */}
                </Grid>
            ),
        },
        // {
        //     Header: 'Reports Configured',
        //     accessor: 'num_reports',
        //     Filter: false,
        //     sortable: true
        // },
        {
            Header: "Last run status",
            accessor: "last_run_status",
            sortable: false,
            Filter: false,
            Actions: (props) => (<></>),
            Cell: (props) => (
                <Grid>
                    <Typography variant="subtitle1">
                        {props.row.original.last_run_status === null
                            ? props.row.original.no_of_runs === 0
                                ? "No run initiated"
                                : "Running"
                            : props.row.original.last_run_status}
                    </Typography>
                </Grid>
            ),
        },
        {
            Header: "Last run time",
            Filter: false,
            accessor: 'last_run_at',
            sortable: true,
            sortType: (a, b, name, desc) => {
                var a1 = new Date(a.values.last_run_at).valueOf()
                var b1 = new Date(b.values.last_run_at).valueOf()
                if (a.values.last_run_at === null) {
                    if (!desc) {
                        a1 = new Date("01-01-2500").valueOf()
                        console.log(a1)
                    } else {
                        a1 = new Date("01-01-1800").valueOf()
                    }
                }
                if (b.values.last_run_at === null) {
                    if (!desc) {
                        b1 = new Date("01-01-2500").valueOf()
                    } else {
                        b1 = new Date("01-01-1800").valueOf()
                    }
                }
                console.log(a1)
                if (desc) {
                    return a1 > b1 ? 1 : -1
                }
                return a1 < b1 ? -1 : 1;
            },
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center" style={{ minWidth: 100 }}>
                    <Typography variant="subtitle1">
                        {props.row.original.last_run_at === null ? "No run initiated" :
                            moment(props.row.original?.last_run_at)
                                .format("DD-MMM-YYYY hh:mm:ss A")}
                    </Typography>
                </Grid>
            ),
        },
        {
            Header: "Number of Runs",
            accessor: "no_of_runs",
            Filter: false,
            sortable: true,
            Actions: (props) => (<></>),
        },
        {
            Header: "Rows Moved",
            accessor: "rows_moved",
            Actions: (props) => (<></>),
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <>
                    <Typography style={{ fontSize: 12 }} variant="subtitle1">
                        {props.row.original.rows_moved.toLocaleString()}
                    </Typography>
                </>
            ),
        },
        {
            Header: (props) => {
                return (
                    <>
                        <Typography style={{ fontSize: 12 }} variant="subtitle1">
                            Data Moved
                        </Typography>
                        <Grid container>
                            <Button
                                variant="contained"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    changeFormattingUnit("GB");
                                }}
                                className={classes.headerButton}
                            >
                                GB
                            </Button>
                            <Button
                                variant="contained"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    changeFormattingUnit("MB");
                                }}
                                className={classes.headerButton}
                            >
                                MB
                            </Button>
                        </Grid>
                    </>
                );
            },
            Actions: (props) => (<></>),
            accessor: "data_moved",
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <>
                    <Typography style={{ fontSize: 12 }} variant="subtitle1">
                        {props.row.original.data_moved_readable}
                    </Typography>
                </>
            ),
        },
        {
            Header: (props) => {
                return (
                    <>
                        <Grid container xs={12}>

                            <Typography style={{ fontSize: 12, width: 150 }} variant="subtitle1">
                                Status
                            </Typography>
                            <CheckForPermissionHOC requiredPermissions={["bulk.pipeline"]}>

                                <Grid container xs={12} style={{ marginTop: 30, marginBottom: '20px' }} justifyContent='center'>
                                    <Tooltip title="Run Selected Pipeline">

                                        <Grid item xs={4} container justifyContent="center">
                                            {resumeAccountIsLoading ? (
                                                <CircularProgress size={15} />
                                            ) : (
                                                <ButtonBase onClick={(e) => {
                                                    e.stopPropagation();

                                                }} disableTouchRipple disabled={!isSelected}>

                                                    <PlayArrowIcon
                                                        className={classes.icon}
                                                        style={{ color: `${!isSelected ? "#A5AEB7" : '#2E394B'}` }}
                                                        fontSize="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setResumeConsentOpen(true)
                                                        }}

                                                    /></ButtonBase>)}
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Pause Seleted Pipeline">

                                        <Grid item xs={4} container justifyContent="center">
                                            {pauseAccountIsLoading ? (
                                                <CircularProgress size={15} />
                                            ) : (<ButtonBase onClick={(e) => {
                                                e.stopPropagation();

                                            }} disableTouchRipple disabled={!isSelected}>
                                                <PauseIcon
                                                    className={classes.icon}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setPauseConsentOpen(true)
                                                    }}
                                                    style={{ color: `${!isSelected ? "#A5AEB7" : '#2E394B'}` }}

                                                    fontSize="small"
                                                /></ButtonBase>)}
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Delete Selected Pipeline">

                                        <Grid item xs={4} container justifyContent="center">
                                            {deletePipelineIsLoading ? (
                                                <CircularProgress size={15} />
                                            ) : (
                                                <ButtonBase onClick={(e) => {
                                                    e.stopPropagation();

                                                }} disableTouchRipple disabled={!isSelected}>
                                                    <Delete
                                                        className={classes.icon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setDeleteConsentOpen(true)
                                                        }}
                                                        style={{ color: `${!isSelected ? "#A5AEB7" : '#2E394B'}` }}

                                                        fontSize="small"
                                                    /></ButtonBase>)}
                                        </Grid>
                                    </Tooltip>

                                </Grid>
                            </CheckForPermissionHOC>
                        </Grid>
                    </>
                );
            },

            Filter: false,
            sortable: false,
            Cell: (props) => {
                return (
                    <Grid style={{ minWidth: 130 }} container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.status}</Typography>
                        {statusLoading && props.row.original.id === reportLoading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <CustomSwitch
                                disabled={props.row.original.status === "BACKFILL_PAUSED"}
                                checked={props.row.original.status === "ACTIVE"}
                                onChange={(e) => handleStausChange(e.target.checked, props.row.original.id)}
                                size="small"
                            />
                        )}
                    </Grid>
                );
            },
            accessor: "status",
            Filter: SelectColumnFilter,
            filter: "includes",
        },
        // {
        //     Header: (props) => {
        //         return (
        //             <>
        //                 <Grid>
        //                     <Typography style={{ fontSize: 12, width: 150 }} variant="subtitle1">
        //                         Actions
        //                     </Typography>
        //                 </Grid>
        //                 </>
        //                 );
        //     },
        //                 accessor: "action",
        //                 Filter: false,
        //     Cell: (props) => (
        //                 <Grid container style={{ minWidth: 100 }}>
        //                     <ConfiguredReportActionButtons reportId={props.row.original.id} datasourceSlug={datasourceSlug} />
        //                 </Grid>
        //                 ),
        //                 sortable: false,
        // },
    ];
    const formatData = (data) =>
        data.map((el) => ({
            ...el,
            data_moved_readable: (el.data_moved / 1000000).toFixed(2) + " MB",
        }));
    const dashboard = useSelector((state) => state.dashboard);
    const [data, setData] = React.useState(formatData(dashboard.configuredReports));

    React.useEffect(() => {
        setData(formatData(dashboard.configuredReports));
    }, [dashboard.configuredReports]);

    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <Box>
            <WarningPopup openPopup={PauseConsentOpen} closePopup={() => setPauseConsentOpen(false)} headingText="Are you sure to pause selected pipeline" submitAction={pauseAllPipelineHandler} submitActionIsLoading={pauseAccountIsLoading} />
            <WarningPopup openPopup={ResumeConsentOpen} closePopup={() => setResumeConsentOpen(false)} headingText="Are you sure to run selected pipeline" submitAction={resumePipelineHandler} submitActionIsLoading={resumeAccountIsLoading} />
            <WarningPopup openPopup={deleteConsentOpen} closePopup={() => setDeleteConsentOpen(false)} headingText="Are you sure to delete selected pipeline" submitAction={deleteAllPipelineHandler} submitActionIsLoading={deletePipelineIsLoading} />

            <RowSelectTable
                columns={columns}
                data={data}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                componentName="configured_report"
                datasourceSlug={datasourceSlug}
                setSelectedCallback={setSelectedCallback}
                setIsSelected={setIsSelected}
            />
        </Box>
    );
}
