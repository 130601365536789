import { makeStyles, CircularProgress } from "@material-ui/core";
import { Button, Grid, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,

        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        borderRadius: 10,
        padding: theme.spacing(2, 4, 3),
    },
    font15: {
        fontSize: 15,
    },
}));
const WarningPopup = (props) => {
    const classes = useStyles()
    const clickHandler = async () => {
        await props.submitAction()
        props.closePopup()
    }
    return (<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openPopup}
        onClose={() => props.closePopup}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        disablePortal
    >
        <Fade in={props.openPopup} unmountOnExit>
            <Grid
                className={classes.paper}
                container
                item
                justifyContent="center"
                alignItems="center"
                style={{ width: "30vw", height: "25vh", overflowY: "scroll" }}
            >
                <Grid item xs={12} style={{ marginBotton: 30 }}>
                    <Typography variant="h3" align='center'>{props.headingText}</Typography>
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>

                        <Button variant="contained" fullWidth color="primary" onClick={clickHandler}>
                            {props.submitActionIsLoading ? (
                                <CircularProgress
                                    style={{
                                        marginRight: 10,
                                        color: "white",
                                    }}
                                    size={16}
                                />
                            ) : null}
                            Yes
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={() => props.closePopup()}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    </Modal>
    )
}

export default WarningPopup