import React from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	},
	dateContainer: {
		backgroundColor: "#FFFFFF",
		border: "1px solid #D6D6D6",
		borderRadius: 5,
		minWidth: 250,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	date: {
		padding: "5px 10px",
		fontSize: 12,
		fontFamily: "Nunito"
	},
	icon: {
		fontSize: 14,
		marginRight: 10,
		marginLeft: 10
	}
}));

export default function DateRange(props) {
	const classes = useStyles();
	const timezone = useSelector((state) => state?.users?.timezone);
	const [open, setOpen] = React.useState(false);
	const [dateRange, setDateRange] = React.useState({
		startDate: props.to,
		endDate: props.from
	});
	const handleChange = (range) => {
		setDateRange(range);
		props.setTo(range.startDate);
		props.setFrom(range.endDate);
		setOpen(false);
	};
	const toggle = () => setOpen(!open);
	return (
		<div>
			<Box className={classes.dateContainer + " hoverable"} onClick={toggle}>
				<Typography className={classes.date} variant="subtitle1">
					{dateRange
						? dateRange.startDate.toDateString() + " to " + dateRange.endDate.toDateString()
						: "Select Date Range"}
				</Typography>
				<CalendarTodayIcon className={classes.icon} fontSize="small" />
			</Box>
			<DateRangePicker
				wrapperClassName="dateRangePickerWrapper"
				initialDateRange={dateRange}
				open={open}
				toggle={toggle}
				maxDate={moment().tz(timezone)}
				onChange={(range) => handleChange(range)}
			/>
		</div>
	);
}
