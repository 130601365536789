import React from "react";

import MaUTable from "@material-ui/core/Table";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable, useFilters } from "react-table";
import { matchSorter } from "match-sorter";
import { TextField, Typography, Grid, makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useParams } from "react-router-dom";

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
        <TextField
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            label={`Search`}
            variant="outlined"
            margin="dense"
            fullWidth
        />
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const useStyles = makeStyles((theme) => ({
    tableHead: {
        backgroundColor: "#FAFAFA",
    },
    pagination: {
        marginTop: 10,
        marginBottom: 10,
        marginRight: 10,
    },
    tableCellContainer: {
        paddingRight: 15,
        paddingLeft: 15,
        verticalAlign: "middle",
    },
}));

const EnhancedTable = ({ columns, data, updateMyData, skipPageReset, componentName, datasourceSlug, reverseConnectorSlug }) => {
    const filterTypes = React.useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,

            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        state: { pageIndex },
        pageCount,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            autoResetPage: !skipPageReset,
            autoResetSortBy: !skipPageReset,
            autoResetFilters: !skipPageReset,
            autoResetRowState: !skipPageReset,
            updateMyData,
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect
    );

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage - 1);
    };

    const { dataSourceSlug } = useParams();

    const classes = useStyles();

    // Render the UI for your table
    return (
        <TableContainer>
            <MaUTable {...getTableProps()}>
                <TableHead className={classes.tableHead}>
                    {headerGroups.map((headerGroup, i) => (
                        <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, i) => (
                                <TableCell
                                    key={i}
                                    {...(!column.sortable
                                        ? column.getHeaderProps()
                                        : column.getHeaderProps(column.getSortByToggleProps()))}
                                >
                                    <Grid container>
                                        {column.render("Header")}
                                        {column.sortable ? (
                                            <TableSortLabel
                                                active={column.isSorted}
                                                // react-table has a unsorted state which is not treated here
                                                direction={column.id !== 'creation_time' ? (column.isSortedDesc ? "desc" : "asc") : (column.isSortedDesc ? "asc" : "desc")}
                                            />
                                        ) : null}
                                    </Grid>
                                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <TableRow key={i} {...row.getRowProps()} hover>
                                {row.cells.map((cell, i) => {
                                    return (
                                        <TableCell key={i} className={classes.tableCellContainer} {...cell.getCellProps()}>
                                            <Typography style={{ textAlign: "left" }} variant="subtitle1">
                                                {cell.render("Cell")}
                                            </Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </MaUTable>

            <Grid container justify="flex-end">
                <TableFooter>
                    <TableRow>
                        <Pagination
                            className={classes.pagination}
                            size="small"
                            count={pageCount}
                            page={pageIndex + 1}
                            color="primary"
                            onChange={handleChangePage}
                        />
                    </TableRow>
                </TableFooter>
            </Grid>
        </TableContainer>
    );
};

EnhancedTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
};

export default EnhancedTable;
