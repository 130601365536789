import axios from "axios";
import Cookies from "js-cookie";
import { refreshAccessToken } from "../services/Api/api";
import config from "../constants/config";

const axiosInstance = axios.create({
  baseURL: config.backendUrl,
  // baseURL: `http://localhost:4000/`
});

axiosInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axiosInstance.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, PUT, POST, DELETE, OPTIONS";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
  "accessToken"
)}`;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const res = await refreshAccessToken(Cookies.get("refreshToken"));
        Cookies.set("accessToken", res.data.access_token);
        Cookies.set("expiresIn", res.data.expiresIn * 1000);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.access_token}`;
        originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
        return axiosInstance(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
