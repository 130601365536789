import React, { useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Grid, Typography } from "@material-ui/core";
import useFilterAndSort from "hooks/useFilterAndSort";
import MenuItem from "@material-ui/core/MenuItem";
import UserCard from "./UserCard";
import useApi from "hooks/useApi";

const useStyles = makeStyles((theme) => ({
    toolBar: {
        display: "flex",
        paddingLeft: 10,
    },
    appBar: {
        backgroundColor: "#FAFAFA",
        marginBottom: 5,
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        backgroundColor: "#FAFAFA",
    },
    search: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        backgroundColor: "white",
        height: 33,
        marginRight: 20,
        border: "1px solid #D6D6D6",
    },
    searchIcon: {
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 10,
    },
    inputRoot: {
        color: "inherit",
        flex: 1,
        height: 45,
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
    autoComplete: {
        height: 10,
    },
    autoCompleteLabel: {
        fontSize: 14,
        height: 35,
    },
    textfield: {
        backgroundColor: "white",
    },
    button: {
        marginLeft: 18,
        fontSize: 12,
    },
    input1: {
        height: 16,
    },
}));

export default function UsersList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [users, usersIsLoading, getUser] = useApi()
    const [resultArray, onFilterChange, onChangeSort, setList, setResultArray] =
        useFilterAndSort(users, "name", "name");;
    const [activeStatus, setActiveStatus] = useState('ALL')

    useEffect(() => {
        async function func() {
            await getUser({
                module: "accountDetails",
                method: "getAccountUsers",
                returnResult: true,
                apiData: {
                    account_id: props.account_id,
                },
            })
        }
        func()
    }, []);
    useEffect(() => {
        console.log(users)
    }, [users])
    React.useEffect(() => {
        const onSelectChange = (e) => {
            let temp = []


            users?.forEach((e) => {
                if (e.status === activeStatus || activeStatus === 'ALL') {
                    temp.push(e)
                }
            }
            )

            console.log(temp)
            setResultArray(temp)
        }
        onSelectChange()

    }, [activeStatus])


    if (usersIsLoading && users?.length === 0) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }
    if (users?.length === 0) {
        return (
            <Grid container justify="center" align="center">
                <Typography className="fullHeight" variant="h6">
                    Nothing to show here. Probably a Server Error{" "}
                </Typography>
            </Grid>
        );
    }
    return (
        <>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar className={classes.toolBar}>
                    <Grid container item xs={12} justifyContent='space-evenly' alignItems="center">
                        <Grid xs={5} className={classes.search}>
                            <Grid className={classes.searchIcon}>
                                <SearchIcon />
                            </Grid>
                            <InputBase
                                placeholder="Type here to search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ "aria-label": "search" }}
                                onChange={(e) => onFilterChange(e.target.value)}
                            />
                        </Grid>
                        {/* <Autocomplete
						id="sort-users"
						options={[
							{ title: "A to Z", value: "ASC" },
							{ title: "Z to A", value: "DESC" }
						]}
						getOptionLabel={(option) => option.title}
						style={{ flex: 0.3 }}
						classes={{
							input: classes.autoComplete
						}}
						onChange={(event, newValue) =>
							newValue === undefined || newValue === null
								? onChangeSort(null)
								: onChangeSort(newValue.value)
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Sort Users"
								variant="outlined"
								InputLabelProps={{
									classes: {
										root: classes.autoCompleteLabel
									}
								}}
								className={classes.textfield}
							/>
						)}
					/> */}

                        <Grid item xs={3}>
                            <TextField
                                id="sort-users"
                                label="Sort User"
                                fullWidth
                                style={{ flex: 0.2 }}
                                size="small"
                                // defaultValue="ASC"
                                onChange={(event) => onChangeSort(event.target.value)}
                                variant="outlined"
                                InputProps={{ classes: { input: classes.input1 } }}
                                select
                            >
                                <MenuItem value="ASC">A-Z</MenuItem>
                                <MenuItem value="DESC">Z-A</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="sort-users"
                                fullWidth
                                label="Select Status"
                                style={{ flex: 0.2 }}
                                size="small"
                                // defaultValue="ASC"
                                onChange={(event) => setActiveStatus(event.target.value)}
                                variant="outlined"
                                InputProps={{ classes: { input: classes.input1 } }}
                                select
                            >

                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                                <MenuItem value="PENDING">PENDING</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            {usersIsLoading ? <LinearProgress /> : null}

            <Paper className={classes.root} elevation={0}>

                {resultArray?.length === 0 ? (<Grid container justify="center" align="center">
                    <Typography className="fullHeight" variant="h6">
                        Nothing to show here. Probably a Server Error{" "}
                    </Typography>
                </Grid>) :
                    (resultArray?.map((u, i) => (
                        <UserCard
                            key={i}
                            params={u}

                        />
                    )))}

            </Paper>
        </>
    );
}
