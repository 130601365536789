import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TransformTable from "../../../../common/Table/TranformTable";
import { Grid, makeStyles, Button, Switch } from "@material-ui/core";
// import Shuffle from "../../assets/images/transformations/shuffle.svg"
// import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
// import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from "@material-ui/core/styles";
// import CheckPermissionFunction from "services/CheckPermissionFunction";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeStatusOfTransformation, changeStatusOfTransformationAdmin } from "services/Api/api";
import { fetchTransformations } from "redux/actions/dashboardActions";
import { enqueueSnackbar as enqueueSnackbarAction, closeSnackbar as closeSnackbarAction } from "redux/actions/NotifierActions";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        transition: "initial",
        transitionDuration: "0.2",
        marginRight: 4,
        fontSize: 17,
        "&:hover": {
            cursor: "pointer",
            fontSize: 20,
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
}));

const closeSnackbar = (dispatch, ...args) => dispatch(closeSnackbarAction(...args));

const CustomSwitch = withStyles({
    switchBase: {
        color: "#A5AEB7",
        "&$checked": {
            color: "#23BE82",
        },
        "&$checked + $track": {
            backgroundColor: "#23BE82",
        },
    },
    checked: {},
    track: {},
})(Switch);

export default function TransformationTable({ tableData, account_id }) {
    const classes = useStyles();
    const timezone = useSelector((state) => state?.users?.timezone);
    // const editPermission = CheckPermissionFunction(["transformation.edit"]);
    // const addPermission = CheckPermissionFunction(["transformation.add"]);

    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [deleteLoading, setDaleteLoading] = React.useState(false);
    const [statusLoading, setStatusLoading] = React.useState(false);
    const [reportLoading, setReportLoading] = React.useState(null);

    // console.log(tableData, "tableData");

    const handleStausChange = (checked, configured_report_id) => {
        let status = "";
        if (checked) {
            status = "ACTIVE";
        } else {
            status = "PAUSED";
        }
        const data = {
            status,
        };
        setStatusLoading(true);
        setReportLoading(configured_report_id);
        changeStatusOfTransformationAdmin(configured_report_id, data, account_id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(
                        enqueueSnackbarAction({
                            message: "Status changed successfully.",
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: "success",
                                action: (key) => (
                                    <CloseIcon
                                        className="hoverable"
                                        fontSize="small"
                                        onClick={() => closeSnackbar(dispatch, key)}
                                    />
                                ),
                            },
                        })
                    );
                    dispatch(fetchTransformations(account_id));
                } else {
                    HttpErrorHandler(dispatch, "Status change failed. Please try again later.");
                }
                setStatusLoading(false);
                setReportLoading(null);
            })
            .catch((e) => {
                setStatusLoading(false);
                setReportLoading(null);
                HttpErrorHandler(dispatch, "Status change failed. Please try again later.");
            });
    };

    const columns = [
        {
            Header: "Name",
            accessor: "name",
            sortable: false,
            Cell: (props) => (
                <Grid style={{ minWidth: 150 }} container justify="space-between" alignItems="center">
                    <Grid container>
                        {/* <Link to={`/transformations/edit/${props.row.original.id}`}> */}
                        <Typography
                            className={false ? "hoverable" : null}
                            // onClick={() =>
                            // 	editPermission
                            // 		? history.push(`/transformations/edit/${props.row.original.id}`)
                            // 		: null
                            // }
                            variant="subtitle1"
                        >
                            {props.row.original.name}
                        </Typography>
                        {/* </Link> */}
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Last run status",
            accessor: "last_run_status",
            sortable: true,
            Filter: false,
            Actions: (props) => <></>,
            Cell: (props) => (
                <Grid>
                    <Typography variant="subtitle1">
                        {props.row.original.last_run_status === undefined ? "No run initiated" : ""}
                        {props.row.original.last_run_status === null
                            ? props.row.original.no_of_runs === 0
                                ? "No run initiated"
                                : "Running"
                            : props.row.original.last_run_status}
                    </Typography>
                </Grid>
            ),
        },
        {
            Header: "Last run time",
            Filter: false,
            accessor: "last_run_at",
            sortable: true,
            sortType: (a, b, name, desc) => {
                var a1 = new Date(a.values.last_run_at).valueOf();
                var b1 = new Date(b.values.last_run_at).valueOf();
                if (a.values.last_run_at === null) {
                    if (!desc) {
                        a1 = new Date("01-01-2500").valueOf();
                        console.log(a1);
                    } else {
                        a1 = new Date("01-01-1800").valueOf();
                    }
                }
                if (b.values.last_run_at === null) {
                    if (!desc) {
                        b1 = new Date("01-01-2500").valueOf();
                    } else {
                        b1 = new Date("01-01-1800").valueOf();
                    }
                }
                console.log(a1);
                if (desc) {
                    return a1 > b1 ? 1 : -1;
                }
                return a1 < b1 ? -1 : 1;
            },
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center" style={{ minWidth: 100 }}>
                    <Typography variant="subtitle1">
                        {props.row.original.last_run_at === null || props.row.original.last_run_at === undefined
                            ? "No run initiated"
                            : moment(props.row.original?.last_run_at).format("DD-MMM-YYYY hh:mm:ss A")}
                    </Typography>
                </Grid>
            ),
        },
        // {
        //     Header: 'Reports Configured',
        //     accessor: 'num_reports',
        //     Filter: false,
        //     sortable: true
        // },
        {
            Header: "Created By",
            accessor: "username",
            Filter: false,
            sortable: false,
        },
        {
            Header: "Created On",
            accessor: "created_at",
            Filter: false,
            sortable: false,
            Cell: (props) => (
                <Grid style={{ minWidth: 150 }} container justify="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">
                            {moment(props.row.original.created_at).format("YYYY-MM-DD, hh:mm:ss A")}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        // {
        // 	Header: "Last Run",
        // 	accessor: "last_run",
        // 	Filter: false,
        // 	sortable: false
        // },
        // {
        // 	Header: "Last Modified By",
        // 	accessor: "last_modified_by",
        // 	Filter: false,
        // 	sortable: false
        // },
        {
            Header: "Status",
            Filter: false,
            sortable: false,
            Cell: (props) => {
                return (
                    <Grid style={{ minWidth: 130 }} container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.status}</Typography>
                        {statusLoading && props.row.original.id === reportLoading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <CustomSwitch
                                checked={props.row.original.status !== "PAUSED"}
                                onChange={(e) => handleStausChange(e.target.checked, props.row.original.id)}
                                size="small"
                            />
                        )}
                    </Grid>
                );
            },
            accessor: "status",
            // Filter: SelectColumnFilter,
            // filter: 'includes',
        },
        // {
        // 	Header: "Actions",
        // 	Filter: false,
        // 	Cell: (props) => (
        // 		<Grid container style={{ minWidth: 100 }}>
        // 			<TransformationActionButtons transformationId={props.row.original.id} />
        // 			{/* <MenuIcon className={classes.icon} fontSize="small" />
        //                 <ScheduleOutlinedIcon className={classes.icon} fontSize="small" /> */}
        // 		</Grid>
        // 	),
        // 	sortable: false
        // }
    ];
    const [data, setData] = React.useState(null);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <Box>
            <TransformTable
                columns={columns}
                data={tableData}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                link="/transformations/add"
                showCreateBtn={false}
            />
        </Box>
    );
}
