import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";
import reverseAxios from 'config/reverseAxios'

class BulkActionsEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `admin_app`;
        this.transformer = null;
    }
    pipelineStatusChange(data) {
        return axios.post(`${this.resourceEndpoint}/datasources/${data.apiData.datasourceSlug}/bulk/status`, data.apiData.data);
    }
    syncStatusChange(data) {
        return reverseAxios.post(`${this.resourceEndpoint}/syncs/${data.apiData.reverseConnectorSlug}/bulk/status`, data.apiData.data);
    }
    pipelineRun(data) {
        return axios.post(`${this.resourceEndpoint}/datasources/${data.apiData.datasourceSlug}/bulk/run`, data.apiData.data);
    }
    deleteAllPipeline(data) {
        return axios.post(`${this.resourceEndpoint}/datasources/${data.apiData.datasourceSlug}/bulk/delete`, data.apiData.data);
    }
    syncRun(data) {
        return reverseAxios.post(`${this.resourceEndpoint}/syncs/${data.apiData.reverseConnectorSlug}/bulk/run`, data.apiData.data);
    }
    deleteSelectedSync(data) {
        return reverseAxios.post(`${this.resourceEndpoint}/syncs/${data.apiData.reverseConnectorSlug}/bulk/delete`, data.apiData.data);
    }
    changeConfiguredSyncStatusAdmin(data) {
        let admin_query = `?account_id=${data.apiData.account_id}&is_admin=${true}`
        return reverseAxios.post(
            `/reverse-connectors/${data.apiData.slug}/configured-syncs/${data.apiData.configuredSyncId}/status${admin_query}`,
            data.apiData.data
        );
    }
}

export default BulkActionsEndpoints;
