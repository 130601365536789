const checkForRoles = (requiredRoles, userRole = null) => {
    let check = false;
    console.log(userRole)
    if (!Array.isArray(requiredRoles)) {
        return true;
    }
    requiredRoles.forEach((requiredRole) => {
        console.log(userRole)
        userRole?.forEach((uR) => {
            if (requiredRole === uR) {
                check = true;
            }
        })

    });
    return check;
}
export default checkForRoles