import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "common/Table/Table";
import { Grid } from "@material-ui/core";
import moment from "moment";
import InvoiceActionButtons from "common/ActionButtonGroups/InvoiceActionButtons";
import SelectColumnFilter from "common/SelectFilter";

export default function InvoiceTable({ tableData }) {
    const columns = [
        {
            Header: "Invoice Number",
            accessor: "number",
            Filter: true,
            sortable: true,
        },
        {
            Header: "Email",
            accessor: "email",
            sortable: true,
            Cell: (props) => (
                <Grid style={{ minWidth: 150 }} container justify="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">{props.row.original.email}</Typography>
                    </Grid>
                </Grid>
            ),
        },

        {
            Header: "Created At",
            accessor: "creation_time",
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <Grid style={{ minWidth: 150 }} container justify="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">
                            {moment(props.row.original.created_time).format("YYYY-MM-DD, hh:mm:ss A")}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Amount",
            accessor: "total",
            Filter: true,
            sortable: true,
        },
        {
            Header: "Balance",
            accessor: "balance",
            Filter: true,
            sortable: true,
        },
        {
            Header: "Due Date",
            accessor: "due_date",
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <Grid style={{ minWidth: 120 }} container justify="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">
                            {props.row.original.due_date}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Status",
            accessor: "status",
            Filter: SelectColumnFilter,
            sortable: true,
        },
        {
            Header: "Transaction Type",
            accessor: "transaction_type",
            Filter: SelectColumnFilter,
            sortable: true,
            Cell: (props) => (
                <Grid style={{ minWidth: 120 }} container justify="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">
                            {props.row.original.transaction_type}</Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Actions",
            Filter: false,
            Cell: (props) => (
                <Grid container style={{ minWidth: 100 }}>
                    <InvoiceActionButtons invoiceId={props.row.original.invoice_id} customerId={props.row.original.customer_id} isPending={props.row.original.status === 'pending'} />
                    {/* <MenuIcon className={classes.icon} fontSize="small" />
                        <ScheduleOutlinedIcon className={classes.icon} fontSize="small" /> */}
                </Grid>
            ),
            sortable: false,
        },
    ];
    const [data, setData] = React.useState(null);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <Box>
            <Table
                columns={columns}
                data={tableData}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
            />
        </Box>
    );
}
