import { Typography, LinearProgress, CircularProgress } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { fetchReverseSyncDashboardData } from "../../../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import NoData from "../../../../common/NoData/NoData";
import { AnimatePresence, motion } from "framer-motion";
import ReverseSyncTable from "./ReverseSyncTable";
import ReverseConnectorTable from "./ReverseConnectorTable";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        padding: 20,
        marginBottom: 30,
        minHeight: 300,
    },
}));

/**
 *
 * @prop - from, to
 * @component {This component renders either REVERSE CONNECTOR Table or REVERSE SYNC Table based on "reverseTable" state.}
 */

const ReverseStats = ({ from, to, account_id,workspace_id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => state.dashboard);
    const [reverseTable, setReverseTable] = React.useState("REVERSE CONNECTOR");
    const [reverseSyncSlug, setReverseSyncSlug] = React.useState(null);

    /**
     *
     * @param {*} reverse_sync_slug
     * @function - This function fetches Reverse Sync data and changes "reverseTable" state in order to display REVERSE SYNC Table.
     */

    const reverseTableNavClick = (reverse_sync_slug) => {
        setReverseTable("SYNC");
        console.log(from, to);
        setReverseSyncSlug(reverse_sync_slug);
        // const toString = from.toISOString().split("T")[0] + " 23:59:59";
        // const fromString = to.toISOString().split("T")[0] + " 00:00:00";
        // const queryString = `?from_date=${fromString}&to_date=${toString}`;
        // const data = {
        //     queryString,
        //     reverse_sync_slug,
        // };
        // dispatch(fetchReverseSyncDashboardData(data));
    };

    /**
     *
     * @param - none
     * @function - This function empties Reverse Sync data logs and changes "reverseTable" state in order to display REVERSE CONNECTOR Table.
     */

    const gobackForReverse = () => {
        setReverseTable("REVERSE CONNECTOR");
        dispatch({ type: "FETCH_REVERSE_SYNC_REPORTS_LIST", payload: [] });
        setReverseSyncSlug(null);
    };

    /**
     * @function - This useEffect fetches Reverse Sync Data Logs on component mount(if reverseSyncSlug exists) and whenever date range is changed.
     */

    React.useEffect(() => {
        if (reverseSyncSlug !== null) {
            const toString = from.toISOString().split("T")[0] + " 23:59:59";
            const fromString = to.toISOString().split("T")[0] + " 00:00:00";
            const queryString = `?from_date=${fromString}&to_date=${toString}`;
            const data = {
                queryString,
                reverse_sync_slug: reverseSyncSlug,
            };
            dispatch(fetchReverseSyncDashboardData(data, account_id,workspace_id));
        }
    }, [from, to,workspace_id]);
    React.useEffect(() => {
        if (reverseSyncSlug !== null && reverseTable === "SYNC") {
            const toString = from.toISOString().split("T")[0] + " 23:59:59";
            const fromString = to.toISOString().split("T")[0] + " 00:00:00";
            const queryString = `?from_date=${fromString}&to_date=${toString}`;
            const data = {
                queryString,
                reverse_sync_slug: reverseSyncSlug,
            };
            dispatch(fetchReverseSyncDashboardData(data, account_id,workspace_id));
        }
    }, [reverseTable]);
    const prepareData = (reverseSyncSlug) => {
        const toString = from.toISOString().split("T")[0] + " 23:59:59";
        const fromString = to.toISOString().split("T")[0] + " 00:00:00";
        const queryString = `?from_date=${fromString}&to_date=${toString}`;
        const data = {
            queryString,
            reverse_sync_slug: reverseSyncSlug,
        };
        return data
    }

    return (
        <Grid className={classes.tableContainer}>
            {reverseTable === "REVERSE CONNECTOR" ? (
                <Typography className="size16" variant="h3">
                    Reverse Connectors
                </Typography>
            ) : (
                <Grid container>
                    <KeyboardBackspaceIcon style={{ marginRight: 10 }} className="hoverable" onClick={() => gobackForReverse()} />
                    <Typography variant="h3">Configured Syncs</Typography>
                </Grid>
            )}
            {dashboard.dataDestinations.length === 0 ? (
                <NoData
                    link="/reverse-connectors?tab=explore_connectors"
                    showButton={true}
                    height={300}
                    componentType="destination"
                    routeData={{ tab: "exploreConnectors" }}
                />
            ) : (
                <AnimatePresence>
                    {reverseTable === "REVERSE CONNECTOR" ? (
                        <ReverseConnectorTable reverseTableNavClick={reverseTableNavClick} account_id={account_id} />
                    ) : (
                        <motion.div key="collapsed">
                            {dashboard.reverseSyncReportsIsLoading && dashboard.reverseSyncReports.length === 0 ? (
                                <Grid container justify="center" alignItems="center" style={{ height: 500 }}>
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                <>
                                    {dashboard.reverseSyncReportsIsLoading ? (
                                        <>
                                            <LinearProgress />
                                        </>
                                    ) : null}

                                    <ReverseSyncTable
                                        reverseSyncSlug={reverseSyncSlug}
                                        reverseTableNavClick={reverseTableNavClick}
                                        account_id={account_id}
                                        logsData={prepareData(reverseSyncSlug)}
                                    />
                                </>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </Grid>
    );
};

export default ReverseStats;
