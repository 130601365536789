import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class LogsDataEndpoint extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `admin_app`;
        this.transformer = null;
    }
    fetchLogsData() {
        return axios.get(`${this.resourceEndpoint}/accounts/logs_data`);
    }
}

export default LogsDataEndpoint;
