import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    overrides: {
        MuiDataGrid: {
            root: {
                fontSize: 11,
            },
        },
        MuiIconButton: {
            root: {
                padding: 8,
            },
        },
        MuiFormControlLabel: {
            root: {
                marginRight: 0,
            },
        },
        MuiOutlinedInput: {
            inputMarginDense: {
                paddingTop: 8.5,
                paddingBottom: 8.5,
                fontSize: 12,
            },
        },
        MuiInputLabel: {
            outlined: {
                fontSize: 10,
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: 12,
            },
        },
        MuiTextField: {
            root: {
                marginTop: "8px",
                marginBottom: "8px",
                color: "#909090",
                fontSize: 12,
            },
        },
        MuiButton: {
            root: {
                marginTop: "15px",
                marginBottom: "15px",
                minWidth: "unset",
                textTransform: "unset",
            },
            containedPrimary: {
                color: "#FFFFFF",
            },
            contained: {
                boxShadow: "0px 3px 10px #0000000F",
            },
            outlined: {
                color: "#FFFFFF",
                border: "1px solid #FFFFFF",
            },
        },
        MuiDrawer: {
            paper: {
                position: "relative",
            },
        },
        MuiTimelineDot: {
            root: {
                boxShadow: "unset",
            },
            defaultGrey: {
                backgroundColor: "#FFFFFF",
            },
        },
        MuiTimelineItem: {
            missingOppositeContent: {
                "&:before": {
                    flex: "unset",
                    padding: 0,
                },
            },
        },
        MuiTimelineConnector: {
            root: {
                backgroundColor: "#B8C2CC",
                width: 1,
            },
        },
        MuiTableCell: {
            root: {
                verticalAlign: "baseline",
                borderBottom: "unset",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                padding: 8,
                fontSize: 12,
            },
        },
        MuiTableContainer: {
            root: {
                boxShadow: "0px 3px 10px #0000000F",
                border: "1px solid #EDEDED",
                borderRadius: "4px",
                marginTop: 16,
            },
        },
        MuiTable: {
            root: {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
        },
        MuiTab: {
            root: {
                fontWeight: 600,
                fontSize: 13,
            },
        },
        MuiBox: {
            root: {
                padding: "unset",
            },
        },
        MuiTabs: {
            flexContainer: {
                borderBottom: "1px solid #B8C2CC",
            },
        },
        MuiTablePagination: {
            caption: {
                fontWeight: 500,
            },
        },
        MuiTab: {
            root: {
                textTransform: "unset",
            },
        },
        MuiAppBar: {
            colorPrimary: {
                color: "#2c2c2c",
            },
        },
        MuiInputBase: {
            input: {
                fontSize: 12,
            },
        },
        // MuiTableCell: {
        // 	head: {
        // 		fontSize: 12
        // 	}
        // }
    },
    palette: {
        primary: {
            main: "#FD9567",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#2E394B",
            contrastText: "#FFFFFF",
        },
        brand: {
            main: "#2E394B",
        },
        action: {
            main: "#EAFFEE",
        },
    },
    typography: {
        fontFamily: ["Montserrat", "Nunito"],
        h2: {
            fontSize: "22px",
            lineHeight: "29px",
            fontWeight: 600,
            letterSpacing: "0px",
            color: "#626262",
        },
        h3: {
            // fontFamily: "Montserrat",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 600,
            letterSpacing: "0px",
            color: "#2C2C2C",
        },
        h4: {
            textAlign: "left",
            // fontFamily: "Montserrat",
            fontSize: "30px",
            lineHeight: "44px",
            fontWeight: "normal",
            letterSpacing: "0px",
            opacity: 1,
            color: "#626262",
        },
        h5: {},
        h6: {
            textAlign: "left",
            // fontFamily: "Montserrat",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: "normal",
            letterSpacing: "0px",
            opacity: 1,
            color: "#626262",
        },
        subtitle1: {
            // fontFamily: "Montserrat",
            fontSize: "12px",
            lineHeight: "19px",
            fontWeight: 500,
            letterSpacing: "0px",
            color: "#2C2C2C",
        },
        subtitle2: {
            // fontFamily: "Montserrat",
            fontSize: "9px",
            lineHeight: "14px",
            fontWeight: "normal",
            letterSpacing: "0px",
            opacity: 1,
            color: "#626262",
        },
        body1: {
            fontSize: "14px",
        },
    },
});

export default theme;
