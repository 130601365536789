import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";

const CustomSelect = withStyles({
    root: {
        fontSize: 12,
        paddingTop: 6,
        paddingBottom: 6,
    },
})(Select);

export default function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <>
            <CustomSelect
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                }}
                displayEmpty
                fullWidth
                inputProps={{ "aria-label": "Without label" }}
                variant="outlined"
                style={{
                    marginTop: 10,
                }}
                margin="dense"
            >
                <MenuItem value="" disabled>
                    Filter
                </MenuItem>
                <MenuItem value="">All</MenuItem>
                {options.map((option, i) => (
                    <MenuItem key={i} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </CustomSelect>
        </>
    );
}
