import React from 'react'
import checkForRoles from './CheckForRoles';
import { useSelector } from 'react-redux';
const CheckForRolesHOC = (props) => {
    const user = useSelector((state) => state.user.user)
    const userRole = user.roles
    console.log(userRole)
    if (props?.requiredRoles?.length === 0) {
        console.log(props?.requiredRoles)
        return props.children;
    }
    if (userRole == null) {
        console.log(props?.requiredRoles)
        return null;
    }
    if (checkForRoles(props.requiredRoles, userRole)) {
        console.log(props?.requiredRoles)

        return props.children;
    }

    if (props.redirect) {
        return <div></div>
    }
    return null;


}

export default CheckForRolesHOC