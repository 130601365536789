import { Typography, Paper, LinearProgress, CircularProgress } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "./common/Card";
import DateRangePicker from "../../../../common/DateRangePicker";
import {
    fetchDatasourceStats,
    fetchOverAllStats,
    fetchOverAllRunStats,
    fetchReverseConnectorDashboardData,
    fetchAccountOverAllRunStats,
    fetchAccountOverAllStats
} from "../../../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import ForwardStats from "./ForwardStats";
import ReverseStats from "./ReverseStats"
import Transformation from './Transformation'
import DBTStats from "./DBTStats";
import Orchestration from "./Orchestrations";
const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
    container: {
        width: "95%",
    },
    activityContainer: {
        marginTop: 25,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    dateContainer: {
        marginBottom: 15,
    },
    tableContainer: {
        padding: 20,
        marginBottom: 30,
        minHeight: 500,
    },
}));

/**
 *
 * @prop - none
 * @component {This component renders the Dashboard on UI and uses multiple smaller components to render different aspects of dashboard.}
 */

const Dashboard = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const dashboard = useSelector((state) => state.dashboard);
    const [from, setFrom] = React.useState(new Date());
    const [to, setTo] = React.useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));

    /**
     * @function - This useEffect fetches various data and stats on component mount and whenever date range is changed.
     */

    React.useEffect(() => {
        const toString = moment(from).format("YYYY-MM-DD") + " 23:59:59";
        const fromString = moment(to).format("YYYY-MM-DD") + " 00:00:00";
        const queryString = `?from_date=${fromString}&to_date=${toString}`;
        
        if (props.workspace_id) {
            dispatch(fetchDatasourceStats(queryString, props.account_id, props.workspace_id));
            dispatch(fetchOverAllRunStats(queryString, props.account_id, props.workspace_id));
            dispatch(fetchOverAllStats(queryString, props.account_id, props.workspace_id));
            dispatch(fetchReverseConnectorDashboardData(queryString, props.account_id, props.workspace_id));
        }
        else{
            dispatch(fetchAccountOverAllRunStats(queryString, props.account_id));
            dispatch(fetchAccountOverAllStats(queryString, props.account_id));
        }
    }, [from, to, props.workspace_id]);

    const [cardData, setCardData] = React.useState([]);

    /**
     * @function - This useEffect sets "cardData" on component mount(if overallStats andoverallRunStats exist) and whenever dashboard state is changed.
     */

    React.useEffect(() => {
        if (dashboard.overallStats !== null && dashboard.overallRunStats !== null) {
            setCardData([
                {
                    msg: "Successful Runs",
                    number: dashboard.overallRunStats.success,
                    check: "success",
                    hasCard: false,
                },
                {
                    msg: "Total reports configured",
                    number: dashboard.overallStats.num_reports,
                    check: "configured",
                    hasCard: true,
                },
                {
                    msg: "Runs with errors",
                    number: dashboard.overallRunStats.error,
                    check: "errors",
                    hasCard: true,
                },
                {
                    msg: "Data Moved",
                    number: (dashboard.overallStats.data_moved / 1000000000).toFixed(2) + " GB",
                    check: "success",
                    hasCard: true,
                },
                {
                    msg: "Total datasources configured",
                    number: dashboard.overallStats.configured_datasources,
                    check: "configured",
                    hasCard: false,
                },
                {
                    msg: "Rows Moved",
                    number: (dashboard.overallStats.rows_moved / 1000000).toFixed(2) + " M",
                    check: "success",
                    hasCard: true,
                },
            ]);
        }
    }, [dashboard]);
    return (
        <Grid container justify="center">
            <Grid item className={classes.container}>
                {(dashboard.overallStatsIsLoading && dashboard.overallStats !== null) ||
                    (dashboard.overallRunStatsIsLoading && dashboard.overallRunStats !== null) ||
                    (dashboard.dataSourcesIsLoading && dashboard.dataSources.length !== 0) ||
                    (dashboard.dataDestinationsIsLoading && dashboard.dataDestinations.length === 0) ? (
                    <LinearProgress style={{ marginBottom: 10 }} />
                ) : null}
                <Grid container justify="space-between" alignItems="center" className={classes.dateContainer}>
                    <Typography className="size16 boldText blackText" variant="h5">
                        {/* Hi, {user?.name} ! */}
                    </Typography>
                    <DateRangePicker from={from} to={to} setFrom={setFrom} setTo={setTo} />
                </Grid>
                {(dashboard.overallStatsIsLoading && dashboard.overallStats === null) ||
                    (dashboard.overallRunStatsIsLoading && dashboard.overallRunStats === null) ||
                    (dashboard.dataSourcesIsLoading && dashboard.dataSources.length === 0) ||
                    (dashboard.dataDestinationsIsLoading && dashboard.dataDestinations.length === 0) ? (
                    <Grid container justify="center" alignItems="center" style={{ height: "80vh" }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid container spacing={2}>
                            {cardData.map((el, i) => (
                                <Grid key={i} item xs={3}>
                                    <Card
                                        from={from}
                                        to={to}
                                        num={el.number}
                                        msg={el.msg}
                                        check={el.check}
                                        hasCard={el.hasCard}
                                        account_id={props.account_id}
                                        workspace_id={props.workspace_id}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        {props.workspace_id && <Grid container className={classes.activityContainer} spacing={2}>
                            <Grid item xs={12}>
                                <Paper>
                                    <ForwardStats from={from} to={to} account_id={props.account_id} workspace_id={props.workspace_id} />
                                </Paper>
                            </Grid>
                        </Grid>}

                        {props.workspace_id && <Grid container className={classes.activityContainer} spacing={2}>
                            <Grid item xs={12}>
                                <Paper>
                                    <ReverseStats from={from} to={to} account_id={props.account_id} workspace_id={props.workspace_id} />
                                </Paper>
                            </Grid>
                        </Grid>}
                       {props.workspace_id && <Grid container className={classes.activityContainer} spacing={2}>
                            <Grid item xs={12}>
                                <Paper>
                                    <Transformation from={from} to={to} account_id={props.account_id} workspace_id={props.workspace_id} />
                                </Paper>
                            </Grid>
                        </Grid>}
                        {props.workspace_id && <Grid container className={classes.activityContainer} spacing={2}>
                            <Grid item xs={12}>
                                <Paper>
                                    <DBTStats from={from} to={to} account_id={props.account_id} workspace_id={props.workspace_id} />
                                </Paper>
                            </Grid>
                        </Grid>}
                        {props.workspace_id && <Grid container className={classes.activityContainer} spacing={2}>
                            <Grid item xs={12}>
                                <Paper>
                                    <Orchestration account_id={props.account_id} workspace_id={props.workspace_id} />
                                </Paper>
                            </Grid>
                        </Grid>}

                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default Dashboard;
