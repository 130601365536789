import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Typography, Paper } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const AddonsList = (props) => {
    return (
        <div style={{ width: "100%" }}>
            <Typography variant="h5">Selected addons</Typography>
            <Paper elevation={1} style={{ marginTop: "20px" }}>
                <List style={{ padding: "0px" }}>
                    {props.selectedAddons.map((e, i) => (
                        <ListItem>
                            <ListItemText primary={e} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon onClick={() => props.deleteSelectedAddon(i)} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                <List style={{ padding: "0px" }}>
                    {props.createdAddons.map((e, i) => (
                        <ListItem>
                            <ListItemText primary={e.name} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon onClick={() => props.deleteCreatedAddon(i)} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </div>
    );
};

export default AddonsList;
