class HttpErrorHandler {
    constructor(response, navigate) {
        this.response = response;
        this.navigate = navigate;
    }

    getErrorMessage() {
        if (this?.response?.response?.status === 404) {
            this.navigate("/not-found");
            return;
        }
        if (this?.response?.response?.status !== 422) {
            if (typeof this?.response?.response?.data?.message === "string") {
                return this?.response?.response?.data?.message;
            } else {
                return "Something went wrong, please try again later.";
            }
        } else {
            return undefined;
        }
    }
}

export default HttpErrorHandler;
