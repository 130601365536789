import React from 'react'
import { useSelector } from 'react-redux'
import CheckForPermission from './CheckForPermission'

const CheckForPermissionHOC = (props) => {
    const user = useSelector((state) => state.user.user)
    const permissions = user.permissions
    if (props.requiredPermissions[0] === 'usage.view') {
        console.log(permissions)
        console.log(props.requiredPermissions)
        console.log(CheckForPermission(props.requiredPermissions, permissions))
    }
    if (props?.requiredPermissions?.length === 0) {
        console.log("1")

        return props.children;
    }
    if (permissions == null) {
        console.log("2")

        return null;
    }
    if (Array.isArray(permissions) && permissions.length === 0) {
        console.log("3")

        return null;
    }
    if (CheckForPermission(props.requiredPermissions, permissions)) {
        console.log(props.requiredPermissions)
        console.log("fsdjkfljs")
        return props.children;
    }

    if (props.redirect) {
        return <div></div>
    }
    return null;

}

export default CheckForPermissionHOC