import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class SubscriptionEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `subscription`;
        this.transformer = null;
    }
    getAllPlansItemPrice(data) {
        return axios.get(`${this.resourceEndpoint}/get_all_plans_item_price`);
    }
    getAllAddonsItemPrice(data) {
        return axios.get(`${this.resourceEndpoint}/get_all_addons_item_price`);
    }
    createPlan(data) {
        return axios.post(`${this.resourceEndpoint}/create_plan`, data.apiData.data);
    }
    subscribe(data) {
        return axios.post(`${this.resourceEndpoint}/subscribe`, data.apiData.data);
    }
    updatePaymentMethod(data) {
        console.log(data);
        return axios.post(`${this.resourceEndpoint}/update_payment_method/${data.apiData.subscription_id}`, data.apiData);
    }
    getAllCustomers() {
        return axios.get(`${this.resourceEndpoint}/zoho/customers`);
    }
    getAllSubscriptions() {
        return axios.get(`${this.resourceEndpoint}/zoho/subscriptions`);
    }
    getAllInvoices() {
        return axios.get(`${this.resourceEndpoint}/zoho/invoices`);
    }
    getAllSubscriptionsItems(data) {
        return axios.post(`${this.resourceEndpoint}/admin/subscription_items/${data.apiData.subscription_id}/account/${data.apiData.account_id}`);
    }
    editSubscription(data) {
        return axios.post(`${this.resourceEndpoint}/edit/${data.apiData.subscription_id}/account/${data.apiData.account_id}`, data.apiData);
    }
    addUsage(data) {
        return axios.post(`${this.resourceEndpoint}/add_usage/${data.apiData.invoice_id}`, data.apiData);
    }
    getInvoice(data) {
        return axios.post(`${this.resourceEndpoint}/invoice/${data.apiData.invoice_id}`);
    }
    sentInvoiceMail(data) {
        return axios.post(`${this.resourceEndpoint}/invoice/${data.apiData.invoice_id}/mail`);
    }
    getInvoicesForACustomer(data) {
        return axios.post(`${this.resourceEndpoint}/zoho/customer/${data.apiData.customer_id}/invoices`);
    }
    getSubscriptionId(data) {
        return axios.post(`${this.resourceEndpoint}/${data.apiData.customerId}/get_subcription_id`);
    }
    getDataUsage(data) {
        return axios.post(`${this.resourceEndpoint}/${data.apiData.invoice_id}/get_data_usage`);
    }
    cancel(data) {
        return axios.post(`${this.resourceEndpoint}/cancel/${data.apiData.subscription_id}`);
    }

    extendTrial(data) {
        return axios.post(`${this.resourceEndpoint}/${data.apiData.subscription_id}/extend_trial`, data.apiData);
    }
}

export default SubscriptionEndpoints;
