import React from "react";
import { withStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const NewTabIcon = () => {
    return <LaunchIcon color="primary" style={{ fontSize: 14 }} />;
};

const componentTOMenuMapper = {
    datasource_list: (data) => (
        <div>
            <StyledMenuItem component={Link} to={`/datasource/${data.datasourceSlug}`}>
                <ListItemText primary={<Typography variant="h6">Datasource Detail</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/datasource/${data.datasourceSlug}`} target="_blank">
                        <IconButton edge="end" aria-label="new tab">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem component={Link} to={`/datasource/${data.datasourceSlug}?tab=configured_reports`}>
                <ListItemText primary={<Typography variant="h6">Configured Report</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/datasource/${data.datasourceSlug}?tab=configured_reports`} target="_blank">
                        <IconButton edge="end" aria-label="new tab">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem component={Link} to={`/datasource/${data.datasourceSlug}`}>
                <ListItemText primary={<Typography variant="h6">Credentials</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/datasource/${data.datasourceSlug}`} target="_blank">
                        <IconButton edge="end" aria-label="new tab">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
        </div>
    ),
    configured_report: (data) => (
        <div>
            <StyledMenuItem component={Link} to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row.original.id}`}>
                <ListItemText primary={<Typography variant="h6">View/Edit Details</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row.original.id}`} target="_blank">
                        <IconButton edge="end" aria-label="new tab">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem
                component={Link}
                to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row.original.id}?tab=last_run`}
            >
                <ListItemText primary={<Typography variant="h6">Last Run Status</Typography>} />
                <ListItemSecondaryAction>
                    <Link
                        to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row.original.id}?tab=last_run`}
                        target="_blank"
                    >
                        <IconButton edge="end" aria-label="new tab">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem
                component={Link}
                to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row.original.id}?tab=change_history`}
            >
                <ListItemText primary={<Typography variant="h6">Change History</Typography>} />
                <ListItemSecondaryAction>
                    <Link
                        to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row.original.id}?tab=change_history`}
                        target="_blank"
                    >
                        <IconButton edge="end" aria-label="new tab">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem
                component={Link}
                to={`/datasource/${data?.datasourceSlug}/report/new?configured_report_id=${data.row.original.id}`}
            >
                <ListItemText primary={<Typography variant="h6">Copy</Typography>} />
                <ListItemSecondaryAction>
                    <Link
                        to={`/datasource/${data?.datasourceSlug}/report/new?configured_report_id=${data.row.original.id}`}
                        target="_blank"
                    >
                        <IconButton edge="end" aria-label="new tab">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
        </div>
    ),
    configured_syncs: (data) => (
        <div>
            <StyledMenuItem
                component={Link}
                to={`/reverse-connectors/${data?.reverseConnectorSlug}/sync/${data.row.original.id}`}
            >
                <ListItemText primary={<Typography variant="h6">Edit Sync</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/reverse-connectors/${data?.reverseConnectorSlug}/sync/${data.row.original.id}`} target="_blank">
                        <IconButton edge="end" aria-label="new tab">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
        </div>
    ),
};

const getMenuForComponent = (componentName, state, handleClose, data) => {
    if (typeof componentTOMenuMapper[componentName] === typeof undefined) {
        return null;
    }
    return (
        <StyledMenu
            id="customized-menu"
            anchorReference="anchorPosition"
            anchorPosition={
                state.mouseY !== null && state.mouseX !== null ? { top: state.mouseY, left: state.mouseX } : undefined
            }
            open={state.mouseY !== null}
            keepMounted
            onClose={handleClose}
        >
            {componentTOMenuMapper[componentName](data)}
        </StyledMenu>
    );
};

const initialState = {
    mouseX: null,
    mouseY: null,
};

export default function ContextMenu(props) {
    const [state, setState] = React.useState(initialState);

    const handleClick = (event) => {
        event.preventDefault();
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };

    const childrenWithProps = React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                "aria-controls": "simple-menu",
                "aria-haspopup": "true",
                onContextMenu: handleClick,
                color: "primary",
            });
        }
        return child;
    });

    if (typeof componentTOMenuMapper[props.componentName] === typeof undefined) {
        return props.children;
    }

    return (
        <>
            {childrenWithProps}
            {getMenuForComponent(props.componentName, state, handleClose, props.data)}
        </>
    );
}
