import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "common/ServerSideTable";
import useTableApi from "hooks/useTableApi";
import { Grid, makeStyles, Button, Switch, CircularProgress, Tooltip } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import UiTable from 'common/Table/UiTable'
import { useSelector } from "react-redux";
import CustomerActionItems from "common/ActionButtonGroups/CustomerActionButton";
import moment from "moment";
import DBCustomerActionItems from "common/ActionButtonGroups/DBCustomerActionButton";
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import SelectColumnFilter from "common/SelectFilter";
import { defaultColumn } from "react-table";
import Badge from '@material-ui/core/Badge';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';

const warehouseSlugImgMapper = {
    redshift: "https://assets.website-files.com/60c8b5ba6f2cc2a0922f9214/60e4aa44595fd0ae6cb87f18_redshift_icon.png",
    mysql: "https://static.javatpoint.com/mysql/images/mysql-tutorial.png",
    bigquery: "https://cdn.worldvectorlogo.com/logos/google-bigquery-logo-1.svg",
    snowflake: "https://companieslogo.com/img/orig/SNOW-35164165.png?t=1634190631",
};

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        transition: "initial",
        transitionDuration: "0.2",
        marginRight: 4,
        fontSize: 17,
        "&:hover": {
            cursor: "pointer",
            fontSize: 20,
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
    boxSuccess: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#23BE82",
        marginRight: 10,
    },
    boxFailure: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#F77070",
        marginRight: 10,
    },
    partialSuccess: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#548EEA",
        marginRight: 10,
    },
    boxRunning: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#FD9E74",
        marginRight: 10,
    },
}));
const StyledBadge = withStyles((theme) =>
    createStyles({
        badge: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            // '&::after': {
            //     position: 'absolute',
            //     top: 0,
            //     left: 0,
            //     width: '100%',
            //     height: '100%',
            //     borderRadius: '50%',
            //     animation: '$ripple 1.2s infinite ease-in-out',
            //     border: '1px solid currentColor',
            //     content: '""',
            // },
        },
        // '@keyframes ripple': {
        //     '0%': {
        //         transform: 'scale(.8)',
        //         opacity: 1,
        //     },
        //     '100%': {
        //         transform: 'scale(2.4)',
        //         opacity: 0,
        //     },
        // },
    }),
)(Badge);

/**
 * Component to render runs table
 * @component
 * @param {object} param0
 */
export default function AllAccountsTable({ tableNavClick, refresh }) {
    const classes = useStyles();
    const timezone = useSelector((state) => state?.users?.timezone);
    const { reportId } = useParams();
    const navigate = useNavigate()
    const columns = React.useMemo(
        () => [
            {
                Header: "Id",
                sortable: true,
                accessor: "account_id",

                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                            {props.row.original?.account_id}
                        </Typography>
                    </Grid>
                ),
            },
            {
                Header: "Account name",
                accessor: "name",
                sortable: false,
                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center" onClick={() => navigate(`/accounts/details/${props.row.original?.account_id}`)} style={{ cursor: 'pointer' }} >
                        <Typography variant="subtitle1">
                            {props.row.original?.name}
                        </Typography>
                    </Grid >
                ),
            },
            {
                Header: "Reports",
                accessor: "configured_reports",
                Filter: false,
                sortable: true,
                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                            {props.row.original?.configured_reports}
                        </Typography>
                    </Grid>
                ),
            },
            {
                Header: "Syncs",
                sortable: true,
                accessor: "configured_syncs",
                Filter: false,
                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                            {props.row.original?.configured_syncs}
                        </Typography>
                    </Grid>
                ),
            },
            {
                Header: "Warehouses",
                Filter: false,
                sortable: true,
                accessor: "datawarehouse",

                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                            {props.row.original?.datawarehouse}
                        </Typography>
                    </Grid>
                ),
            },
            {
                Header: "Warehouses type",
                Filter: false,
                sortable: false,
                Cell: (props) => (
                    <Grid container justifyContent="space-evenly" alignItems="center" style={{ minWidth: 100 }}>
                        <AvatarGroup total={24}>
                            {props.row.original?.data_destination_user.map(ele => (
                                <Tooltip title={`${ele} Warehouse`}>
                                    <Avatar alt={ele} style={{ width: 20, height: 20 }} src={warehouseSlugImgMapper[ele.toLowerCase()]} /></Tooltip>
                            ))}

                        </AvatarGroup>
                        <AvatarGroup total={24}>
                            {props.row.original?.data_destination.map(ele => (
                                <Tooltip title={`DC Managed ${ele} Warehouse`}>
                                    <StyledBadge
                                        overlap="circular"
                                        // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        variant="dot"
                                    >  <Avatar alt={ele} style={{ width: 20, height: 20 }} src={warehouseSlugImgMapper[ele.toLowerCase()]} /></StyledBadge></Tooltip>
                            ))}

                        </AvatarGroup>
                    </Grid>
                ),
            },
            {
                Header: "Account type",
                accessor: "account_type",
                Filter: SelectColumnFilter,
                filter: 'includes',

                sortable: true,
                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                            {props.row.original?.account_type === 'generic' ? 'Self-Serve' : 'Custom'}
                        </Typography>
                    </Grid>
                ),
            },
            {
                Header: "Users",
                Filter: false,
                accessor: "users",

                sortable: true,
                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                            {props.row.original?.users}
                        </Typography>
                    </Grid>
                ),
            },
            {
                Header: "Created On",
                Filter: false,
                accessor: 'created_at',
                sortable: true,
                sortType: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                    if (a1 < b1)
                        return 1;
                    else if (a1 > b1)
                        return -1;
                    else
                        return 0;
                },
                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                            {moment(props.row.original?.created_at)
                                .format("DD-MMM-YYYY hh:mm:ss A")}
                        </Typography>
                    </Grid>
                ),
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: SelectColumnFilter,
                sortable: true,
                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Grid container xs={9}>
                            <Typography variant="subtitle1">{props.row.original.status}</Typography>
                        </Grid>
                    </Grid>
                ),
            },
            {
                Header: "Plan Name",
                accessor: "plan_name",
                Filter: SelectColumnFilter,
                sortable: true,
                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Grid container xs={12}>
                            <Typography variant="subtitle1">{props.row.original.plan_name}</Typography>
                        </Grid>
                    </Grid>
                ),
            },
            {
                Header: "Subscription Status",
                accessor: "subscription_status",
                Filter: SelectColumnFilter,
                filter: 'equals',
                sortable: false,
                Cell: (props) => (
                    <Grid container justify="space-between" alignItems="center">
                        <Grid container xs={9}>
                            <Typography variant="subtitle1">{props.row.original.subscription_status}</Typography>
                        </Grid>
                    </Grid>
                ),
            },
            {
                Header: "Actions",
                Filter: false,
                Cell: (props) => (
                    <Grid container style={{ minWidth: 100 }} alignItems="center">
                        {props.row.original.plan_code !== 'unlimited' ? (
                            <CustomerActionItems customerId={props.row.original?.zoho_customer_id} account_id={props.row.original.account_id} subscription_status={props.row.original.subscription_status}
                            />
                        )
                            : <DBCustomerActionItems
                                account_id={props.row.original.account_id}
                                subscription_status={props.row.original.subscription_status}
                            />}
                        {/* <MenuIcon className={classes.icon} fontSize="small" />
                            <ScheduleOutlinedIcon className={classes.icon} fontSize="small" /> */}
                    </Grid>
                ),
                sortable: false,
            },

        ],
        []
    );

    const [tableData, count, page, pageSize, tableLoading, onPageChange, onFilterChange, onPageSizeChange] = useTableApi(
        `/admin_app/get_db_customer`,
        refresh
    );

    const [data, setData] = React.useState(null);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };
    if (tableLoading) {
        return <Grid container style={{ width: '85vw', height: '85vh' }} justifyContent='center' alignItems="center">
            <Grid item >
                <CircularProgress />
            </Grid>
        </Grid >
    }

    return (
        <Box>
            {/* <Table
                columns={columns}
                data={tableData}
                count={count}
                tableLoading={tableLoading}
                onPageChange={onPageChange}
                currentPage={page}
                onFilterChange={onFilterChange}
                perPage={pageSize}
                onPageSizeChange={onPageSizeChange}
            /> */}

            <UiTable
                columns={columns}
                data={tableData}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                showCount={true}
            />
        </Box>
    );
}
