import {
    SET_USER_IS_LOADING,
    SET_USER,
    ME_USER_IS_LOADING,
} from "../actionTypes/actionTypes";

const initialState = {
    user: null,
    usersIsLoading: false,
    meUserIsLoading: false,
};

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_IS_LOADING:
            return {
                ...state,
                usersIsLoading: action.payload,
            };
        case ME_USER_IS_LOADING:
            return {
                ...state,
                meUserIsLoading: action.payload,
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload,
            };
        default:
            return state;
    }
};
