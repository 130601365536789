import CloseIcon from "@material-ui/icons/Close";

import {
    enqueueSnackbar as enqueueSnackbarAction,
    closeSnackbar as closeSnackbarAction,
} from "../../redux/actions/NotifierActions";

const closeSnackbar = (dispatch, ...args) => dispatch(closeSnackbarAction(...args));

export const HttpErrorHandler = (dispatch, msg) => {
    dispatch(
        enqueueSnackbarAction({
            message: msg,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
                action: (key) => (
                    <CloseIcon className="hoverable" fontSize="small" onClick={() => closeSnackbar(dispatch, key)} />
                ),
            },
        })
    );
};
