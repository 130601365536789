import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid, Typography, CircularProgress, IconButton } from "@material-ui/core";
import Cookies from "js-cookie";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import config from "constants/config";
import axios from "axios";
import data_channel_logo from "assets/data_channel_logo.png";
import { useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import { ButtonBase } from "@material-ui/core";
import logoutIcon from 'assets/images/common/logout.svg'

const drawerWidth = 140;

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "white",
        color: theme.palette.grey[700],
        zIndex: theme.zIndex.drawer + 1,
        marginLeft: drawerWidth,
        marginRight: 20,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolbar: {
        paddingRight: 24,
        paddingLeft: 24,
    },
    title: {
        flexGrow: 1,
        fontWeight: "bold",
        fontSize: 16,
    },
    caption: {
        color: "#707070",
    },
    userLabel: {
        margin: 10,
        textOverflow: "ellipsis",
        maxWidth: "6vw",
        overflow: "hidden",
    },
    overflowEllipse: {
        textOverflow: "ellipsis",
    },
    logout: {
        margin: 10,
    },
    userAvatar: {
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        marginLeft: 10,
    },
    popover: {
        // maxHeight: "90vh"
    },
    notificationContainer: {
        padding: 20,
        width: "25vw",
        maxHeight: "80vh",
        overflowX: "hidden",
        overflowY: "scroll",
        [theme.breakpoints.up("xl")]: {
            width: "20vw",
        },
        // minHeight: "60vh"
    },
    notification: {
        marginTop: 25,
    },
    hr: {
        marginTop: 11,
        marginBottom: 12,
        color: "#D9D9D9",
    },
    logo: {
        display: "flex",
        justifyContent: "center",
    },
}));

export default function TopBar(props) {
    let full = null;
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = useSelector((state) => state.user)
    const navigate = useNavigate();
    if (props.fullWidth) {
        full = {
            width: "calc(100% - 40px)",
        };
    }

    const handleLogout = async () => {
        try {
            setLoading(true);
            await axios.post(`${config.authUrl}logout`, { refresh_token: Cookies.get("refreshToken") });
            setLoading(false);
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");
            Cookies.remove("expiresIn");
            window.localStorage.clear();
            window.location.href = `${config.authUrl}auth?grant_type=${config.responseType}&client_id=${config.clientId}&redirect_url=${config.redirectUrl}&scopes=${config.scopes}`;
        } catch (e) {
            setLoading(false);
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");
            Cookies.remove("expiresIn");
            window.localStorage.clear();
            window.location.href = `${config.authUrl}auth?grant_type=${config.responseType}&client_id=${config.clientId}&redirect_url=${config.redirectUrl}&scopes=${config.scopes}`;
        }
    };
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();
    return (
        <AppBar className={classes.appBar} style={full}>
            <Toolbar className={classes.toolbar}>
                {props.subscription && (
                    <Box className={classes.logo}>
                        <Link to="/" color="primary" underline="none">
                            <img src={data_channel_logo} alt="DataChannel Logo"></img>
                        </Link>
                        <Grid container item justifyContent="center" alignItems="center" style={{ marginLeft: 10 }}>
                            <Grid item>
                                <Typography variant="h3">Subscription</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                    {props.pageTitle}
                </Typography>

                <Grid item xs={10} container justifyContent="flex-end">
                    {/* <Grid item style={{ marginRight: 10 }}>
                        <Tooltip title="Logout">
                            <IconButton onClick={handleLogout} style={{ padding: 10 }}>
                                {!loading ? <ExitToAppIcon fontSize="large" /> : <CircularProgress size={24} />}
                            </IconButton>
                        </Tooltip>
                    </Grid> */}
                    <Avatar
                        ari
                        a-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClickMenu}
                        className={classes.userAvatar + " hoverable"}
                        style={{ cursor: 'pointer' }}
                    >
                        {user.user.name[0]}
                    </Avatar>
                    <Menu
                        style={{ marginTop: 50 }}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >

                        <Grid
                            container
                            item
                            xs={12}
                            style={{ width: "320px", margin: 0, marginTop: '-8px' }}
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >
                            <Grid item container justifyContent="flex-start" alignItems='center' xs={12} style={{ margin: 0, backgroundImage: "linear-gradient(180deg, #EFF4FF 0%, rgba(239, 244, 255, 0) 100%)", paddingLeft: 10 }}>
                                <Grid item xs={3}>

                                    <Avatar
                                        variant="circle"
                                        className={classes.userAvatar + " hoverable"}
                                        style={{
                                            width: 50,
                                            height: 50,
                                            margin: 0,
                                            borderRadius: "50%",

                                        }}
                                    >
                                        {user.user?.name[0]}
                                    </Avatar>
                                </Grid>

                                <Grid item xs={8}>
                                    <Grid item container justifyContent="flex=start">
                                        <Typography variant="caption" style={{ fontSize: 14, fontWeight: 500, color: "#000000" }}>
                                            {user.user?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="flex-start">
                                        <Typography variant="caption" style={{
                                            'overflow': 'hidden',
                                            'textOverflow': 'ellipsis',
                                            color: '#627082'
                                        }}>
                                            {user.user?.email}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} container justifyContent="flex-start">
                                        <Typography variant="caption" style={{ textAlign: "left", color: '#627082' }}>
                                            <span style={{ fontWeight: 500 }}> Roles</span>  - {user.user.roles.join(' , ')}
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid container item xs={12} >


                                <hr style={{ width: '100%' }} />
                                <Grid container item xs={12} >
                                    <ButtonBase style={{ width: '100%' }}>
                                        <Grid container item xs={12} style={{ margin: 5, paddingLeft: 15 }} justifyContent='flex-start' onClick={handleLogout}>
                                            <Grid container item xs={2} justifyContent='flex-start'>
                                                <img src={logoutIcon}></img>
                                            </Grid>
                                            <Grid container item xs={10} justifyContent='flex-start'>
                                                <Typography variant="caption" style={{ fontSize: 16 }}>Log Out</Typography>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>



                                </Grid>

                            </Grid>


                        </Grid>


                    </Menu>

                </Grid>
            </Toolbar>
        </AppBar >
    );
}
