import React from "react";
import OrchestrationTable from "./OrchestrationTable";

import { useDispatch, useSelector } from "react-redux";
import { fetchOrchestrations } from "redux/actions/dashboardActions";
import {
    Grid,
    makeStyles,
    Button,
    Switch,
    withStyles,
    Typography,
    Box,
    CircularProgress,
    LinearProgress,
} from "@material-ui/core";

import NoData from "common/NoData/NoData";
import { Link } from "react-router-dom";

const Orchestration = (props) => {
    const orchestration = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();

    //fetch data on the first mount if not fethched before
    React.useEffect(() => {
        dispatch(fetchOrchestrations(props.account_id, props.workspace_id));
    }, []);

    //loading indicator
    if (orchestration.orchestrationsIsLoading && orchestration.orchestrations?.length === 0) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    if (orchestration.orchestrations?.length === 0) {
        return <NoData showButton={true} height={"85vh"} componentType="orchestrations" />;
    }

    return (
        <>
            <Grid className="marginBottom" container justifyContent="center">
                <Grid item xs={12} className="pageContainer" style={{ padding: 24 }}>
                    <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 10 }}>
                        <Typography className="size16" variant="h3">
                            Orchestrations
                        </Typography>
                    </Grid>
                    {orchestration.orchestrationsIsLoading ? <LinearProgress /> : null}

                    <OrchestrationTable tableData={orchestration.orchestrations} />
                </Grid>
            </Grid>
        </>
    );
};

export default Orchestration;
