import React, { useState } from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Field, Form, Formik } from "formik";
import { CircularProgress, FormGroup, Grid, Typography, FormLabel, Tooltip } from "@material-ui/core";
import { object, string, } from "yup";
import { makeStyles } from '@material-ui/core';
import FileCopyIcon from "@material-ui/icons/FileCopy"
import useApi from 'hooks/useApi';
const usestyles = makeStyles((theme) => ({
    left: {
        backgroundColor: "#FBFBFB",
        padding: 10,
    },
    right: {
        backgroundColor: "white",
        padding: 20,
        borderRadius: 10,
    },
    button: {
        backgroundColor: "red",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[6],
        borderRadius:10,
        padding: theme.spacing(4, 4, 4),

    },
    container: {
        paddingTop:20
    }
}));
const initialValues = {
    email: "",
};
const VerficationLink = () => {
    const classes = usestyles()
    const [email, setEmail] = useState()
    const [copied, copy] = useState(false)
    const [, linkIsLoading, getLink] = useApi()
    const handleSubmit = async (values, helpers) => {
        try {
            const link = await getLink({
                initialValues: [],
                module: 'user',
                method: 'getVerificationLink',
                returnResult: true,
                apiData: {
                    email: values.email
                },
                throwError: true
            })
            setEmail(link?.link)
            copy(false)
        }
        catch (e) {
            console.log(e.response.data.message)
            helpers.setErrors({
                email: e.response?.data?.message
            });
        }

    }
    let validationSchema = {};
    validationSchema = object({
        email: string().required().email(),
    });
    return (
        <Grid container xs={12} direction="column" justify="center" alignItems="center" className={classes.container}>
            <Grid container item sm={5} xs={12} justify="center" style={{ minWidth: "400px", marginTop: 30 }} className={classes.paper}>
                <Grid item xs={10}>
                    <Typography variant='h3' style={{ fontWeight: 500, marginBottom: 15 }}>
                        Get verfication link
                    </Typography>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={(values, helpers) => handleSubmit(values, helpers)}
                        enableReinitialize={true}
                    >
                        {({ values, errors, isSubmitting, isValidating, touched, handleChange, setFieldValue }) => (
                            <Grid container item xs={12} >
                                <Form style={{ width: '100%' }}>
                                    <FormLabel>

                                    </FormLabel>
                                    <FormGroup style={{ width: '100%' }}>
                                        <Field
                                            error={touched.email && errors.hasOwnProperty("email")}
                                            name="email"
                                            as={TextField}
                                            fullWidth
                                            label="Email Id"
                                            variant="outlined"
                                            margin="dense"
                                            helperText={touched.email && errors?.email}
                                        />
                                    </FormGroup>
                                    <Grid container xs={12} justifyContent='flex-start'>
                                    <Grid item xs={6} style={{marginTop:10}}>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                    >
                                        {isSubmitting && <CircularProgress size='small' style={{ color: 'white' }} />}
                                        Get Link
                                    </Button>
                                    </Grid>
                                    </Grid>

                                </Form>
                            </Grid>
                        )
                        }
                    </Formik >
                    {email && <Typography variant='h3' style={{ fontWeight: 500, marginBottom: 15, marginTop: 15 }}>
                        Verfication link
                    </Typography>}
                    {email && <Grid
                        item
                        container
                        xs={12}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ marginBottom: 10, marginTop: 10 }}
                    >

                        <Grid item xs={10} style={{ backgroundColor: "#DCDCDC", padding: 10, borderRadius: 5 }}>
                            <Typography
                                variant="body1"
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {" "}
                                {email}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} container alignItems="center" spacing={1}>
                            <Tooltip title="Copy">
                                <FileCopyIcon
                                    onClick={() => {
                                        navigator.clipboard.writeText(email);
                                        copy(true);
                                    }}
                                    style={{ cursor: "pointer", color: "gray" }}
                                ></FileCopyIcon>
                            </Tooltip>
                            <Grid item xs={1}>
                                {copied && <Typography variant="subtitle2">copied</Typography>}
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default VerficationLink