import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "common/Table/Table";
import { Grid } from "@material-ui/core";
import moment from "moment";
import InvoiceActionButtons from "common/ActionButtonGroups/InvoiceActionButtons";
import SelectColumnFilter from "common/SelectFilter";

export default function InvoiceTable({ tableData }) {
    const columns = [
        {
            Header: "User id",
            Filter: false,
            sortable: true,
            accessor: "user_id",
            Cell: (props) => (
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">{props.row.original.user_id}</Typography>
                </Grid>
            ),
        },
        {
            Header: "User name",
            sortable: true,
            accessor: "user_name",
            Cell: (props) => (
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">{props.row.original.user_name}</Typography>
                </Grid>
            ),
        },
        {
            Header: "No of Workspaces ",
            Filter: false,
            sortable: true,
            accessor: "workspace_count",
            Cell: (props) => (
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">{props.row.original.workspace_count}</Typography>
                </Grid>
            ),
        },

        {
            Header: "No of Questions",
            Filter: false,
            sortable: true,
            accessor: "n_questions",
            Cell: (props) => (
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">{props.row.original.n_questions}</Typography>
                </Grid>
            ),
        },
        {
            Header: "Tokens",
            Filter: false,
            sortable: true,
            accessor: "sum_tokens",
            Cell: (props) => (
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">{props.row.original.sum_tokens}</Typography>
                </Grid>
            ),
        },
    ];
    const [data, setData] = React.useState(null);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <Box>
            <Table
                columns={columns}
                data={tableData}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
            />
        </Box>
    );
}
