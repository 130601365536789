
import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

import PauseIcon from '@material-ui/icons/Pause';
import Tooltip from "@material-ui/core/Tooltip";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import clsx from "clsx";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useApi from "hooks/useApi";
import { useDispatch } from "react-redux";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,

        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
}));

export default function SyncssActionButtons({ reverseConnectorSlug, account_id, fontSize }) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [, resumeAccountIsLoading, resumeAllPipeline] = useApi();
    const [, pauseAccountIsLoading, pauseAllPipeline] = useApi();
    const dispatch = useDispatch()

    const resumePipelineHandler = async () => {
        try {
            await resumeAllPipeline({
                module: "bulkActions",
                method: "syncRun",
                returnResult: true,
                apiData: {
                    data: {
                        account_id: account_id,
                    },
                    reverseConnectorSlug: reverseConnectorSlug
                },
                successToast: "All Sync Ran",

            });
        }
        catch (e) {
            console.log(e)
        }
    };
    const pauseAllPipelineHandler = async () => {
        await pauseAllPipeline({
            module: "bulkActions",
            method: "syncStatusChange",
            returnResult: true,
            apiData: {
                data: {
                    account_id: account_id,
                    status: 'PAUSED',
                },
                reverseConnectorSlug: reverseConnectorSlug
            },
            successToast: "All Sync Paused",

        })
    };
    return (
        <div>
            <CheckForPermissionHOC requiredPermissions={["bulk.pipeline"]}>

                <Tooltip title="Run All Syncs">
                    {resumeAccountIsLoading ? (
                        <CircularProgress size={15} />
                    ) : (
                        <PlayArrowIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            onClick={(e) => {
                                e.stopPropagation();
                                resumePipelineHandler();
                            }}
                            fontSize="small"
                        />
                    )}
                </Tooltip>
            </CheckForPermissionHOC>
            <CheckForPermissionHOC requiredPermissions={["bulk.pipeline"]}>

                <Tooltip title="Pause All Syncs">
                    {pauseAccountIsLoading ? (
                        <CircularProgress size={15} />
                    ) : (
                        <PauseIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            onClick={(e) => {
                                e.stopPropagation();
                                pauseAllPipelineHandler();
                            }}
                            fontSize="small"
                        />
                    )}
                </Tooltip>
            </CheckForPermissionHOC>

        </div>
    );
}
