import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

import ReceiptIcon from "@material-ui/icons/Receipt";

import Tooltip from "@material-ui/core/Tooltip";

import clsx from "clsx";
import DetailsIcon from "@material-ui/icons/Details";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useApi from "hooks/useApi";
import config from "constants/config";
import PaymentIcon from "@material-ui/icons/Payment";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,

        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
}));

export default function CustomerActionItems({ customerId, fontSize, account_id }) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [loadingCard, setLoadingCard] = React.useState(false);
    const [subscription, subscriptionIsLoading, getSubscriptionId] = useApi();
    const [subscriptionLoading, setSubscriptionLoading] = React.useState(false);
    const [up, UpdatePaymentMethodisLoading, updatePaymentMethod] = useApi();
    const fetchUpdatePaymentMethod = async () => {
        console.log("here");
        console.log(loadingCard);
        console.log("this is callled");
        try {
            const subcriptionId = await getSubscriptionId({
                module: "subscription",
                method: "getSubscriptionId",
                returnResult: true,
                apiData: {
                    customerId: customerId,
                },
            });
            const res = await updatePaymentMethod({
                module: "subscription",
                method: "updatePaymentMethod",
                throwError: true,
                apiData: {
                    subscription_id: subcriptionId.subscription_id,
                    redirect_url: config.frontendUrl + "customers",
                },
                returnResult: true,
            });
            console.log("fsjkdfjsdk");
            console.log(res);
            return res;
        } catch (e) {
            console.log(e);
        }
    };

    const updateCard = async (data) => {
        try {
            setLoadingCard(true);
            console.log(data);
            console.log(loadingCard);
            const hostedPageResult = await fetchUpdatePaymentMethod();
            console.log(hostedPageResult);
            setLoadingCard(false);
            if (hostedPageResult.code === 0) {
                window.location.href = hostedPageResult.hostedpage.url;
            }
        } catch (err) {
            setLoadingCard(false);
        }
    };
    const fetchSubscriptionId = async () => {
        setSubscriptionLoading(true);
        try {
            const subcriptionId = await getSubscriptionId({
                module: "subscription",
                method: "getSubscriptionId",
                returnResult: true,
                apiData: {
                    customerId: customerId,
                },
            });
            setSubscriptionLoading(false);

            navigate(`/subscription/${subcriptionId.subscription_id}/${account_id}`);
        } catch (e) {
            setSubscriptionLoading(false);
        }
    };
    return (
        <div>
            <CheckForPermissionHOC requiredPermissions={["invoice.view"]}>
                <Tooltip title="Get Invoices">
                    <ReceiptIcon
                        className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/customer/invoices/${customerId}`);
                        }}
                        fontSize="small"
                    />
                </Tooltip>
            </CheckForPermissionHOC>
            <CheckForPermissionHOC requiredPermissions={["subscription.view"]}>

                <Tooltip title="Subscription details">
                    {subscriptionLoading ? (
                        <CircularProgress size={15} />
                    ) : (
                        <DetailsIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            onClick={(e) => {
                                e.stopPropagation();
                                fetchSubscriptionId();
                            }}
                            fontSize="small"
                        />
                    )}
                </Tooltip>
            </CheckForPermissionHOC>
            <CheckForPermissionHOC requiredPermissions={["usage.view"]}>

                <Tooltip title="Account details">
                    <AccountCircleIcon
                        className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                        onClick={(e) => {

                            navigate(`/accounts/details/${account_id}`);
                        }}
                        fontSize="small"
                    />
                </Tooltip>
            </CheckForPermissionHOC>
            <CheckForPermissionHOC requiredPermissions={["invoice.edit"]}>

                <Tooltip title="Update Payment Method ">
                    {loadingCard ? (
                        <CircularProgress size={15} />
                    ) : (
                        <PaymentIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            onClick={(e) => {
                                e.stopPropagation();
                                updateCard();
                            }}
                            fontSize="small"
                        />
                    )}
                </Tooltip>
            </CheckForPermissionHOC>

        </div>
    );
}
