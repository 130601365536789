import { Typography, Grid, makeStyles } from "@material-ui/core";
import React from "react";

const styles = makeStyles({
    root: {},
});
const Dashboard = () => {
    const classes = styles();
    return (
        <Grid container className={classes.root} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography align="center" variant="h4">
                    Coming Soon....
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
