import {
    SET_USER,
    ME_USER_IS_LOADING,
} from "../actionTypes/actionTypes";

import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchUser = () => async (dispatch) => {
    apiWrapper(dispatch, ME_USER_IS_LOADING, SET_USER, {
        module: "user",
        method: "getUser",
    });
};

export { fetchUser };
