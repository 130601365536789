import { Typography, LinearProgress, CircularProgress } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TransformationTable from "./DBTTransformationTable";
import { fetchDbtTransformations } from "redux/actions/dashboardActions";
import { useSelector, useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        padding: 20,
        marginBottom: 30,
        minHeight: 500,
    },
}));
const DBTStats = (props) => {
    const dbtTransformations = useSelector((state) => state.dashboard);
    const classes = useStyles();
    console.log(dbtTransformations.dbtTransformations);
    const dispatch = useDispatch();
    //fetch data on the first mount if not fethched before
    React.useEffect(() => {
        dispatch(fetchDbtTransformations(props.account_id, props.workspace_id));
    }, []);

    return (
        <Grid className={classes.tableContainer}>
            <Typography className="size16" variant="h3" style={{ marginBottom: 10 }}>
                DBT Transformations
            </Typography>
            {dbtTransformations.DBTisLoading && dbtTransformations?.dbtTransformations.length === 0 ? null : (
                <div>
                    {dbtTransformations?.DBTisLoading && dbtTransformations?.dbtTransformations?.length === 0 ? (
                        <Grid container justify="center" alignItems="center" style={{ height: 500 }}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            {dbtTransformations?.dbtTransformations?.length > 0 && !dbtTransformations.DBTisLoading && (
                                <TransformationTable
                                    tableData={dbtTransformations.dbtTransformations}
                                    account_id={props.account_id}
                                />
                            )}
                        </>
                    )}{" "}
                    {dbtTransformations?.DBTisLoading ? <LinearProgress style={{ marginTop: 15 }} /> : null}
                </div>
            )}
        </Grid>
    );
};

export default DBTStats;
