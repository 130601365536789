import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, LinearProgress } from "@material-ui/core";
import LogsDataTable from "./NeoAccountUsageTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogsData } from "redux/actions/invoiceActions";
import CheckForPermission from "utils/CheckForPermission";
import DateRangePicker from "../../../common/DateRangePicker/index";
import checkForRoles from "utils/CheckForRoles";
import NeoAccountsTable from "./NeoAccountUsageTable";

const UseStyle = makeStyles((theme) => ({
    root: {},
}));

const NeoAccounts = () => {
    const classes = UseStyle();
    const dcLogs = useSelector((state) => state.logsData);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const permissions = user.permissions;
    const [from, setFrom] = React.useState(new Date());
    const [to, setTo] = React.useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    const role = useSelector((state) => state.user.user.roles);

    // React.useEffect(() => {
    //     dispatch(fetchLogsData());
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    // if (!CheckForPermission(["logs_data.view"], permissions)) {
    //     return <div></div>;
    // }
    // if (dcLogs.logsDataIsLoading && dcLogs.logsData?.length === 0) {
    //     return (
    //         <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
    //             <CircularProgress />
    //         </Grid>
    //     );
    // }

    return (
        <Grid container className={classes.root} direction="row" alignItems="flex-start" justifyContent="center">
            <Grid container xs={12} style={{ paddingLeft: 40, paddingRight: 40 }} justifyContent="center">
                <Grid container xs={12} justifyContent="flex-end">
                    <Grid item>
                        <DateRangePicker from={from} to={to} setFrom={setFrom} setTo={setTo} />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    {dcLogs.neoAccountsDataIsLoading ? <LinearProgress /> : null}
                </Grid>
                <Grid item xs={12}>
                    <NeoAccountsTable tableData={dcLogs.neoAccountsData} to={to} from={from} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NeoAccounts;
