import React, { Component } from "react";
import Cookies from "js-cookie";
import CircularProgress from "@material-ui/core/CircularProgress";
import { refreshAccessToken } from "./Api/api";
import axios from "config/axios";
import config from "constants/config";
import { Grid } from "@material-ui/core";

class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accessGranted: null,
            isLoading: false,
        };
    }

    async componentDidMount() {
        // window.alert("will mount");
        const accessToken = Cookies.get("accessToken");
        if (accessToken === undefined || accessToken === null) {
            this.setState({ ...this.state, accessGranted: false });
            console.log("fsdkp");
        } else if (new Date().getTime() > Cookies.get("expiresIn")) {
            this.setState({ ...this.Cookiesstate, isLoading: true });
            try {
                const res = await refreshAccessToken(Cookies.get("refreshToken"));
                console.log(res.data);
                Cookies.set("accessToken", res.data.access_token);
                Cookies.set("expiresIn", new Date().getTime() + res.data.expires_in * 1000);
                axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.access_token}`;
                console.log(this.state);
                this.setState({ ...this.state, isLoading: false });
                this.setState({ ...this.state, accessGranted: true });
            } catch (e) {
                this.setState({ ...this.state, isLoading: false });
                this.setState({ ...this.state, accessGranted: false });
            }
        } else {
            this.setState({ ...this.state, accessGranted: true });
        }
    }

    componentDidUpdate() {
        if (this.state.accessGranted === false || new Date().getTime() > Cookies.get("expiresIn")) {
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");
            Cookies.remove("expiresIn");
            window.localStorage.clear();
            window.location.href = `${config.authUrl}auth?grant_type=${config.responseType}&client_id=${config.clientId}&redirect_url=${config.redirectUrl}&scopes=${config.scopes}`;
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.accessGranted !== this.state.accessGranted;
    }

    render() {
        return (
            <>
                {/* {this.state.accessGranted === null || this.state.isLoading ? <CircularProgress /> : this.props.children} */}
                {this.state.isLoading ? (
                    <Grid justify="center" alignItems="center" className="fullHeight">
                        <CircularProgress />
                    </Grid>
                ) : this.state.accessGranted ? (
                    this.props.children
                ) : null}
            </>
        );
    }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     notify: () => HttpErrorHandler(dispatch, "Please login to continue."),
//   };
// };

// export default connect(null, mapDispatchToProps)(Auth);
export default Auth;
