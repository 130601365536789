import React from "react";
import { Link } from "react-router-dom";
import MaUTable from "@material-ui/core/Table";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";

import Pagination from "@material-ui/lab/Pagination";

import {
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
	useFilters,
	useAsyncDebounce
} from "react-table";
import { matchSorter } from "match-sorter";
import { TextField, Typography, Grid, makeStyles } from "@material-ui/core";
import { Tooltip, IconButton } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
	const count = preFilteredRows.length;

	return (
		<TextField
			value={filterValue || ""}
			onChange={(e) => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			label={`Search Data Source or Records`}
			variant="outlined"
			margin="dense"
			fullWidth
		/>
	);
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const useStyles = makeStyles((theme) => ({
	tableHead: {
		backgroundColor: "#FAFAFA"
	},
	tableRow: {
		backgroundColor: "#FFFFFF",
		"&:hover": {
			backgroundColor: "#FAFAFA"
		}
	},
	pagination: {
		marginTop: 10,
		marginBottom: 10,
		marginRight: 10
	},
	tableCellContainer: {
		paddingRight: 15,
		paddingLeft: 15
	}
}));

const EnhancedTable = ({ columns, data, updateMyData, skipPageReset, link, showCreateBtn }) => {
	const filterTypes = React.useMemo(
		() => ({
			fuzzyText: fuzzyTextFilterFn,

			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true;
				});
			}
		}),
		[]
	);
	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter
		}),
		[]
	);
	const {
		getTableProps,
		headerGroups,
		prepareRow,
		page,
		gotoPage,
		setPageSize,
		state: { pageIndex, pageSize, selectedRowIds, globalFilter },
		pageCount,
		state,
		preGlobalFilteredRows,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			autoResetPage: !skipPageReset,
			updateMyData
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect
	);

	const handleChangePage = (event, newPage) => {
		gotoPage(newPage - 1);
	};

	const handleChangeRowsPerPage = (event) => {
		setPageSize(Number(event.target.value));
	};

	const classes = useStyles();

	const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
		const count = preGlobalFilteredRows.length;
		const [value, setValue] = React.useState(globalFilter);
		const onChange = useAsyncDebounce((value) => {
			setGlobalFilter(value || undefined);
		}, 100);
		return (
			<Grid spacing={1} container alignItems="center">
				<Grid item xs={11}>
					<TextField
						style={{ backgroundColor: "#FFFFFF" }}
						id="outlined-basic"
						label="Search name here"
						variant="outlined"
						value={value}
						fullWidth
						autoFocus={value ? true : false}
						onChange={(e) => {
							setValue(e.target.value);
							onChange(e.target.value);
						}}
						margin="dense"
					/>
				</Grid>
				{/* <Grid xs={1}>
					<Tooltip title="This filter searches for everything in the table.">
						<IconButton>
							<InfoOutlinedIcon style={{ fontSize: 13 }} />
						</IconButton>
					</Tooltip>
				</Grid> */}
			</Grid>
		);
	};

	// Render the UI for your table
	return (
		<>
			<Grid container justify="flex-end">
				<TableFooter className="fullWidth">
					<Grid container justify="space-between">
						<Grid item xs={4}>
							<Grid container alignItems="center">
								<Grid item xs={2}>
									<Typography className="boldText" variant="h6">
										Filter :{" "}
									</Typography>
								</Grid>
								<Grid item xs={10}>
									<GlobalFilter
										preGlobalFilteredRows={preGlobalFilteredRows}
										globalFilter={state.globalFilter}
										setGlobalFilter={setGlobalFilter}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container>
								<Grid item>
									<TableRow>
										<TablePagination
											className="transtable"
											style={{ paddingRight: 20, borderRight: "unset" }}
											rowsPerPageOptions={[5, 10, 25, { label: "All", value: data.length }]}
											colSpan={3}
											count={data.length}
											rowsPerPage={pageSize}
											page={pageIndex}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
											labelDisplayedRows={({ from, to, count }) =>
												`Showing ${from}-${to} of ${
													count !== -1 ? count : `more than ${to}`
												} records`
											}
										/>
									</TableRow>
								</Grid>
								{showCreateBtn ? (
									<Grid item>
										<Link to={link}>
											<Button style={{ marginTop: 7 }} variant="contained" color="primary">
												Create New
											</Button>
										</Link>
									</Grid>
								) : null}
							</Grid>
						</Grid>
					</Grid>
				</TableFooter>
			</Grid>
			<TableContainer>
				<MaUTable {...getTableProps()}>
					<TableHead className={classes.tableHead}>
						{headerGroups.map((headerGroup, i) => (
							<TableRow key={i} style={{ height: 50 }} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, i) => (
									<TableCell
										key={i}
										{...(!column.sortable
											? column.getHeaderProps()
											: column.getHeaderProps(column.getSortByToggleProps()))}
									>
										<Grid container>
											{column.render("Header")}
											{column.sortable ? (
												<TableSortLabel
													active={column.isSorted}
													// react-table has a unsorted state which is not treated here
													direction={column.isSortedDesc ? "desc" : "asc"}
												/>
											) : null}
										</Grid>
										{/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<TableRow key={i} className={classes.tableRow} {...row.getRowProps()} hover>
									{row.cells.map((cell, i) => {
										return (
											<TableCell
												key={i}
												className={classes.tableCellContainer}
												{...cell.getCellProps()}
											>
												<Typography style={{ textAlign: "left" }} variant="subtitle1">
													{cell.render("Cell")}
												</Typography>
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</MaUTable>
				<Grid container justify="flex-end">
					<TableFooter>
						<TableRow>
							<Pagination
								className={classes.pagination}
								size="small"
								count={pageCount}
								page={pageIndex + 1}
								color="primary"
								onChange={handleChangePage}
							/>
						</TableRow>
					</TableFooter>
				</Grid>
			</TableContainer>
		</>
	);
};

EnhancedTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired
};

export default EnhancedTable;
