import React, { useState } from "react";
import { Grid, Typography, makeStyles, Paper, Button, CircularProgress, TextField } from "@material-ui/core";
import moment from "moment";
import useApi from "hooks/useApi";
import Details from "./Details";
import { useParams } from "react-router";
import CheckForPermission from "utils/CheckForPermission";
import { useSelector } from "react-redux";

const AccountDetails = (props) => {
    const user = useSelector((state) => state.user.user)
    const permissions = user.permissions
    const [subscriptionItems, subscriptionItemsIsLoading, fetchSubscriptionItems] = useApi(
        React.useMemo(
            () => ({
                initialValues: [],
                module: "accountDetails",
                method: "getAccount",
                apiData: {
                    account_id: props.account_id
                },
            }),
            []
        )
    );
    if (!CheckForPermission(['usage.view'], permissions)) {
        console.log(CheckForPermission(['usage.view'], permissions))
        return <div></div>
    }

    if (subscriptionItemsIsLoading) {
        return (
            <Grid container item style={{ width: '100vw', height: '100vh' }} justifyContent='center' alignItems="center">
                <CircularProgress />
            </Grid>
        )
    }

    return (
        <Details account={subscriptionItems} account_id={props.account_id} />
    );
};

export default AccountDetails;
