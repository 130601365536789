import React from "react";
import { Button, CircularProgress, Grid, makeStyles, Select, Tooltip, Typography, MenuItem, TextField } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import useApi from "hooks/useApi";
import { useNavigate } from "react-router";
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";
import AccountStats from "./AccountStats/index";
import { Autocomplete } from "@material-ui/lab";
import config from "constants/config";

import UsersList from "./Users/UsersList";
import WarningPopup from "common/WarningPopup";

const useStyles = makeStyles((theme) => ({
    left: {
        backgroundColor: "#FBFBFB",
        padding: 10,
    },
    right: {
        backgroundColor: "white",
        padding: 20,
        borderRadius: 10,
    },
    button: {
        backgroundColor: "red",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));
const warehouseSlugImgMapper = {
    redshift: "https://assets.website-files.com/60c8b5ba6f2cc2a0922f9214/60e4aa44595fd0ae6cb87f18_redshift_icon.png",
    mysql: "https://static.javatpoint.com/mysql/images/mysql-tutorial.png",
    bigquery: "https://cdn.worldvectorlogo.com/logos/google-bigquery-logo-1.svg",
    snowflake: "https://companieslogo.com/img/orig/SNOW-35164165.png?t=1634190631",
};
const addonFeatureMapper = {
    REVERSE: "Reverse ETL",
    DC_MANAGED_WAREHOUSE: "DC Managed Warehouse",
};

const Details = (props) => {
    const classes = useStyles();
    const account = props.account;
    const navigate = useNavigate();
    const [, resumeAccountIsLoading, resumeAccount] = useApi();
    const [, pauseAccountIsLoading, pauseAccount] = useApi();
    const [loadingCard, setLoadingCard] = React.useState(false);
    const [PauseConsentOpen, setPauseConsentOpen] = React.useState(false);
    const [ResumeConsentOpen, setResumeConsentOpen] = React.useState(false);

    const resumeAccountHandler = async () => {
        try {
            await resumeAccount({
                module: "auth",
                method: "resumeAccount",

                returnResult: true,
                apiData: {
                    account_id: account?.account_id,
                },
                successToast: "Account Resumed",
            });
        } catch (e) {
            console.log(e);
        }
    };
    const pauseAccountHandler = async () => {
        await pauseAccount({
            module: "auth",
            method: "pauseAccount",
            returnResult: true,
            apiData: {
                account_id: account?.account_id,
            },
            successToast: "Account Paused",
        });
    };
    const [subscription, subscriptionIsLoading, getSubscriptionId] = useApi();
    const [subscriptionLoading, setSubscriptionLoading] = React.useState(false);
    const [workspace, setWorkspace] = React.useState(null);
    const [up, UpdatePaymentMethodisLoading, updatePaymentMethod] = useApi();

    const handleSelectWorkspace = (value) => {
        setWorkspace(value);
    };
    const fetchUpdatePaymentMethod = async () => {
        console.log("here");
        console.log(loadingCard);
        console.log("this is callled");
        try {
            const subcriptionId = await getSubscriptionId({
                module: "subscription",
                method: "getSubscriptionId",
                returnResult: true,
                apiData: {
                    customerId: account?.customer_id,
                },
            });
            const res = await updatePaymentMethod({
                module: "subscription",
                method: "updatePaymentMethod",
                throwError: true,
                apiData: {
                    subscription_id: subcriptionId.subscription_id,
                    redirect_url: config.frontendUrl + "customers",
                },
                returnResult: true,
            });
            console.log("fsjkdfjsdk");
            console.log(res);
            return res;
        } catch (e) {
            console.log(e);
        }
    };
    React.useEffect(() => {
        if (account?.workspaces?.length <= 1) {
            account?.workspaces?.forEach((element) => {
                if (element.is_default) {
                    setWorkspace(element);
                }
            });
        }
    }, [account?.workspaces]);

    const updateCard = async (data) => {
        try {
            setLoadingCard(true);
            console.log(data);
            console.log(loadingCard);
            const hostedPageResult = await fetchUpdatePaymentMethod();
            console.log(hostedPageResult);
            setLoadingCard(false);
            if (hostedPageResult.code === 0) {
                window.location.href = hostedPageResult.hostedpage.url;
            }
        } catch (err) {
            setLoadingCard(false);
        }
    };
    const fetchSubscriptionId = async () => {
        if (account?.plan_name === "Unlimited") {
            navigate(`/subscription/unlimited/${account?.account_id}`);
            return;
        }
        navigate(`/subscription/${account?.subscription_id}/${account?.account_id}`);
    };

    return (
        <Grid container justify="center">
            <Grid container item xs={12} style={{ marginRight: 50, marginTop: 10 }} justifyContent="flex-end">
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: 0, marginBottom: 10 }}
                        onClick={() => navigate("/accounts")}
                    >
                        {<ArrowBackIcon />}Back
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <WarningPopup
                    openPopup={PauseConsentOpen}
                    closePopup={() => setPauseConsentOpen(false)}
                    headingText="Are you sure to pause this account"
                    submitAction={pauseAccountHandler}
                    submitActionIsLoading={pauseAccountIsLoading}
                />
                <WarningPopup
                    openPopup={ResumeConsentOpen}
                    closePopup={() => setResumeConsentOpen(false)}
                    headingText="Are you sure to resume this account"
                    submitAction={resumeAccountHandler}
                    submitActionIsLoading={resumeAccountIsLoading}
                />
                <Grid item xs={2} className={classes.left}>
                    <Grid item xs={12} style={{ padding: 10 }}>
                        <Grid>
                            <Typography variant="h3" style={{ marginTop: 0, marginBottom: 10 }}>
                                {account?.account_name}
                            </Typography>
                            <Grid style={{ marginBottom: 10 }}>
                                <Autocomplete
                                    options={account?.workspaces}
                                    name="Roles"
                                    openOnFocus={true}
                                    getOptionLabel={(option) => `${option?.name}`}
                                    value={workspace}
                                    // getOptionSelected={(option, value) => {
                                    //     return value?.id === option?.id;
                                    // }}
                                    fullWidth
                                    onChange={(e, newValue) => handleSelectWorkspace(newValue)}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" label={"Workspace"} variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Typography style={{ marginBottom: 20 }}>Account ID - {account?.account_id}</Typography>
                            <Typography variant="h3" style={{ marginTop: 40, marginBottom: 10 }}>
                                Created On
                            </Typography>
                            <Typography variant="body1">{account?.created_at}</Typography>

                            <Typography variant="h3" style={{ marginTop: 40 }}>
                                {account?.status}
                            </Typography>
                            {account?.plan_name !== "Unlimited" && (
                                <Typography variant="body1" style={{ marginBottom: 10, marginTop: 10 }}>
                                    Expiry - {account?.status === "trial" ? account?.trial_end_date : account?.expiry_date}
                                </Typography>
                            )}
                            {account?.plan_name === "Unlimited" ? (
                                <Typography variant="body1" style={{ marginTop: 10 }}>
                                    {account?.plan_name}
                                </Typography>
                            ) : (
                                <Typography variant="body1">
                                    {account?.plan_name} - ${account?.plan_price}
                                </Typography>
                            )}
                            {account?.plan_name !== "Unlimited" && (
                                <Typography variant="h3" style={{ marginTop: 40, marginBottom: 10 }}>
                                    Add Ons
                                </Typography>
                            )}
                            {account?.addons.map((e, i) => {
                                if (account.account_type === "custom" && account?.plan_name !== "Unlimited") {
                                    return (
                                        <Grid style={{ marginTop: 5 }}>
                                            <Typography variant="body1">
                                                {i + 1}. {e.name}
                                            </Typography>
                                            <Typography variant="body1" style={{ fontWeight: 500, paddingLeft: 10 }}>
                                                ({addonFeatureMapper[e.feature]})
                                            </Typography>
                                        </Grid>
                                    );
                                }
                                return (
                                    <Typography variant="body1">
                                        {i + 1}. {e.name}
                                    </Typography>
                                );
                            })}
                            <Grid>
                                <Typography variant="h3" style={{ marginTop: 40 }}>
                                    Actions
                                </Typography>
                                <Grid container item xs={12} justifyContent="center">
                                    <CheckForPermissionHOC requiredPermissions={["invoice.view"]}>
                                        <Grid item xs={11}>
                                            {account?.plan_name !== "Unlimited" && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(`/customer/invoices/${account?.customer_id}`);
                                                    }}
                                                >
                                                    <Typography variant="body1" style={{ fontSize: 13, fontWeight: 500 }}>
                                                        Get Invoices
                                                    </Typography>
                                                </Button>
                                            )}
                                        </Grid>
                                    </CheckForPermissionHOC>
                                    <CheckForPermissionHOC requiredPermissions={["invoice.edit"]}>
                                        <Grid item xs={11}>
                                            {account?.plan_name !== "Unlimited" && (
                                                <Button variant="contained" color="primary" fullWidth onClick={updateCard}>
                                                    {loadingCard && (
                                                        <CircularProgress color="white" size={16} style={{ marginRight: 5 }} />
                                                    )}
                                                    <Typography variant="body1" style={{ fontSize: 13, fontWeight: 500 }}>
                                                        Payment Method
                                                    </Typography>
                                                </Button>
                                            )}
                                        </Grid>
                                    </CheckForPermissionHOC>
                                    <CheckForPermissionHOC requiredPermissions={["usage.view"]}>
                                        <Grid item xs={11}>
                                            {
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={fetchSubscriptionId}
                                                >
                                                    <Typography variant="body1" style={{ fontSize: 13, fontWeight: 500 }}>
                                                        Subscriptions
                                                    </Typography>
                                                </Button>
                                            }
                                        </Grid>
                                    </CheckForPermissionHOC>
                                    <CheckForPermissionHOC requiredPermissions={["account.pause"]}>
                                        <Grid item xs={11}>
                                            <Button
                                                disabled={account?.status === "unpaid" || account?.status === undefined}
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() => setPauseConsentOpen(true)}
                                            >
                                                {pauseAccountIsLoading && (
                                                    <CircularProgress color="white" size={16} style={{ marginRight: 5 }} />
                                                )}
                                                <Typography variant="body1" style={{ fontSize: 13, fontWeight: 500 }}>
                                                    Pause Account
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </CheckForPermissionHOC>
                                    <CheckForPermissionHOC requiredPermissions={["account.pause"]}>
                                        <Grid item xs={11}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() => setResumeConsentOpen(true)}
                                            >
                                                {resumeAccountIsLoading && (
                                                    <CircularProgress color="white" size={16} style={{ marginRight: 5 }} />
                                                )}
                                                <Typography variant="body1" style={{ fontSize: 13, fontWeight: 500 }}>
                                                    Resume Account
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </CheckForPermissionHOC>
                                </Grid>
                            </Grid>
                            <Typography variant="h3" style={{ marginTop: 40 }}>
                                Warehouses
                            </Typography>
                            {account?.dw.map((e, i) => {
                                return (
                                    <Grid container style={{ marginTop: 10 }} alignItems="center">
                                        <Grid item>
                                            <Avatar
                                                alt={e["ds_name"]}
                                                style={{ width: 20, height: 20 }}
                                                src={warehouseSlugImgMapper[e["ds_name"]?.toLowerCase()]}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography variant="body1" style={{ marginLeft: 10, wordWrap: "break-word" }}>
                                                {e["warehouse_name"]}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {e["is_dc_managed"] === true && (
                                                <Tooltip title="DC Managed">
                                                    <CheckCircleIcon style={{ color: "23BE82" }} />
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                {account?.status !== "unpaid" && account?.status !== undefined ? (
                    <Grid container item xs={10} justifyContent="center" alignItems="start" className={classes.right}>
                        <AccountStats account_id={props.account_id} workspace_id={workspace?.workspace_id} />
                    </Grid>
                ) : (
                    <Grid container item xs={10} justifyContent="center" alignItems="start">
                        <Typography style={{ fontSize: 20, fontWeight: 500, marginTop: 60 }} variant="h3">
                            Account is paused or unpaid, Please resume it to view details
                        </Typography>
                    </Grid>
                )}
                <Grid></Grid>
            </Grid>
        </Grid>
    );
};

export default Details;
