import React from "react";
import TransformTable from "common/Table/UiTable";
import { Grid, makeStyles, Button, Switch, withStyles, Typography, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeStatusOfTransformation } from "services/Api/api";
import { fetchOrchestrations } from "redux/actions/dashboardActions";
import { Link } from "react-router-dom";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import { useDispatch, useSelector } from "react-redux";
import useApi from "hooks/useApi";

const moment = require("moment-timezone");
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        transition: "initial",
        transitionDuration: "0.2",
        marginRight: 4,
        fontSize: 17,
        "&:hover": {
            cursor: "pointer",
            fontSize: 20,
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
}));

const CustomSwitch = withStyles({
    switchBase: {
        color: "#A5AEB7",
        "&$checked": {
            color: "#23BE82",
        },
        "&$checked + $track": {
            backgroundColor: "#23BE82",
        },
    },
    checked: {},
    track: {},
})(Switch);

export default function TransformationTable({ tableData }) {
    const dispatch = useDispatch();
    const [, statusLoading, changeStatus] = useApi();
    const timezone = useSelector((state) => state?.users?.timezone);
    console.log(timezone);

    const [reportLoading, setReportLoading] = React.useState(null);

    const handleStausChange = async (checked, orcehstration_id) => {
        let status = "";
        if (checked) {
            status = "ACTIVE";
        } else {
            status = "PAUSED";
        }
        const data = {
            status,
        };
        setReportLoading(orcehstration_id);
        await changeStatus({
            module: "accountDetails",
            method: "OrchestrationchangeStatus",
            successToast: "Orchestration status changed successfully.",
            apiData: {
                data: data,
                orchestrationId: orcehstration_id,
            },
            returnResult: true,
        });
        setReportLoading(null);
        dispatch(fetchOrchestrations());
    };

    const columns = [
        {
            Header: "Name",
            accessor: "label",
            sortable: false,
            Cell: (props) => (
                <Grid style={{ minWidth: 250 }} container justifyContent="space-between" alignItems="center">
                    <Grid container>
                        <Typography
                            // className={editPermission ? "hoverable" : null}
                            // onClick={() =>
                            // 	editPermission
                            // 		? history.push(`/transformations/edit/${props.row.original.id}`)
                            // 		: null
                            // }
                            variant="subtitle1"
                        >
                            {props.row.original.label}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Created On",
            accessor: "created_at",
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <Grid style={{ minWidth: 250 }} container justifyContent="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">
                            {moment(props.row.original.created_at).format("YYYY-MM-DD, hh:mm:ss A")}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Last run status",
            accessor: "last_run_status",
            sortable: true,
            Filter: false,
            Actions: (props) => <></>,
            Cell: (props) => (
                <Grid>
                    <Typography variant="subtitle1">
                        {props.row.original.last_run_status === null
                            ? props.row.original.no_of_runs === 0
                                ? "No run initiated"
                                : "Running"
                            : props.row.original.last_run_status}
                    </Typography>
                </Grid>
            ),
        },
        {
            Header: "Last run time",
            Filter: false,
            accessor: "last_run_timestamp",
            sortable: true,
            sortType: (a, b, name, desc) => {
                var a1 = new Date(a.values.last_run_timestamp).valueOf();
                var b1 = new Date(b.values.last_run_timestamp).valueOf();
                if (a.values.last_run_timestamp === null) {
                    if (!desc) {
                        a1 = new Date("01-01-2500").valueOf();
                        console.log(a1);
                    } else {
                        a1 = new Date("01-01-1800").valueOf();
                    }
                }
                if (b.values.last_run_timestamp === null) {
                    if (!desc) {
                        b1 = new Date("01-01-2500").valueOf();
                    } else {
                        b1 = new Date("01-01-1800").valueOf();
                    }
                }
                console.log(a1);
                if (desc) {
                    return a1 > b1 ? 1 : -1;
                }
                return a1 < b1 ? -1 : 1;
            },
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center" style={{ minWidth: 100 }}>
                    <Typography variant="subtitle1">
                        {props.row.original.last_run_timestamp === null
                            ? "No run initiated"
                            : moment(props.row.original?.last_run_timestamp).format("DD-MMM-YYYY hh:mm:ss A")}
                    </Typography>
                </Grid>
            ),
        },
        {
            Header: "Status",
            Filter: false,
            sortable: false,
            Cell: (props) => {
                return (
                    <Grid style={{ minWidth: 130 }} container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.status}</Typography>
                        {statusLoading && props.row.original.id === reportLoading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <CustomSwitch
                                checked={props.row.original.status !== "PAUSED"}
                                onChange={(e) => handleStausChange(e.target.checked, props.row.original.id)}
                                size="small"
                            />
                        )}
                    </Grid>
                );
            },
            accessor: "status",
            // Filter: SelectColumnFilter,
            // filter: 'includes',
        },
    ];
    const [data, setData] = React.useState(null);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <Box>
            <TransformTable
                columns={columns}
                data={tableData}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                link="/"
                showCreateBtn={false}
                componentName="orchestration"
            />
        </Box>
    );
}
