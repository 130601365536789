import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { getErrorsForDatasources } from "services/Api/api";
import moment from "moment";
import { Link } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Brush } from "recharts";
import useApi from "hooks/useApi";

const parseData = (data) => {
	let parsedData = [];
	data.forEach((el) => {
		if (el.error !== 0) {
			parsedData.push({
				name: el.datasource_name,
				runs: el.error
			});
		}
	});
	return parsedData;
};

const useStyles = makeStyles(() => ({
	headContainer: {
		margin: "15px 20px"
	},
	borderbottom: {
		borderBottom: "1px solid #B8C2CC"
	},
	chartContainer: {
		// paddingTop: 50,
		// paddingBottom: 50,
		borderRight: "1px solid #B8C2CC"
	},
	hr: {
		marginTop: 10,
		marginBottom: 10
	},
	dateContainer: {
		backgroundColor: "#F7F7F7",
		marginTop: 10,
		marginBottom: 10
	},
	date: {
		padding: "5px 10px"
	},
	padding: {
		padding: "0px 20px"
	}
}));

const getDatasourceSlugFromName = (name, dataSources) => {
	let slug;
	dataSources.forEach((el) => {
		if (el.datasource_name === name) {
			slug = el.datasource_slug;
		}
	});
	return slug;
};

const groupErrorsGroupedByDate = (errorsList, timezone) => {
	if (errorsList !== undefined) {
		let groupedData = [];
		let errors = errorsList.sort((a, b) => new Date(b.date) - new Date(a.date));
		errors.forEach((el) => {
			let date = new Date(el.date).toDateString();
			let filteredDate = groupedData.filter(
				(element) =>
					new Date(element.date === "Today" ? new Date().toDateString() : element.date).toDateString() ===
					date
			);
			if (filteredDate.length !== 0) {
				filteredDate[0].errors.push({
					reportName: el.dataset_name,
					msg: "Missing Data",
					timeStamp:
						new Date(el.date).toDateString() === new Date().toDateString()
							? moment(el.date).tz(timezone).fromNow()
							: moment(el.date).tz(timezone).format("h:mm a"),
					reportId: el.report_id
				});
			} else {
				groupedData.push({
					date:
						new Date(el.date).toDateString() === new Date().toDateString()
							? "Today"
							: moment(el.date).tz(timezone).format("dddd MMM DD YYYY"),
					errors: [
						{
							reportName: el.dataset_name,
							msg: "Missing Data",
							timeStamp:
								new Date(el.date).toDateString() === new Date().toDateString()
									? moment(el.date).tz(timezone).fromNow()
									: moment(el.date).tz(timezone).format("h:mm a"),
							reportId: el.report_id
						}
					]
				});
			}
		});
		return groupedData;
	}
};

export default function DataModel({ close, from, to,workspace_id}) {
	const classes = useStyles();
	const timezone = useSelector((state) => state?.users?.timezone);
	const dashboard = useSelector((state) => state.dashboard);
	const dataMovedArray = parseData(dashboard.dataSources);
	const [currentDatasource, setCurrentDatasource] = React.useState(null);
	const [currentDatasourceName, setCurrentDatasourceName] = React.useState(null);
	// const [datasourceErrors, setDatasourceErrors] = React.useState(null);
	// const [errorsIsLoading, setErrorsIsLoading] = React.useState(false);

	//useApi initializations
	const [datasourceErrors, errorsIsLoading, getErrors, setDatasourceErrors] = useApi();

	const handleDatasourceClick = (e) => {
		const datasourceSlug = getDatasourceSlugFromName(e.name, dashboard.dataSources);
		setCurrentDatasource(datasourceSlug);
		setCurrentDatasourceName(e.name);
	};
	React.useEffect(() => {
		(async () => {
			if (currentDatasource !== null) {
				// setErrorsIsLoading(true);
				// const toString = moment(from).format("YYYY-MM-DD") + " 23:59:59";
				// const fromString = moment(to).format("YYYY-MM-DD") + " 00:00:00";
				// const queryString = `?from_date=${fromString}&to_date=${toString}`;
				// const errors = await getErrorsForDatasources({
				// 	queryString,
				// 	datasourceSlug: currentDatasource
				// });
				// const groupedErrors = groupErrorsGroupedByDate(errors.data.data, timezone);
				// setDatasourceErrors(groupedErrors);
				// setErrorsIsLoading(false);
				await getErrors({
					module: "datasource",
					initialValue: [],
					method: "getErrorsForDatasource",
					apiData: {
						datasourceSlug: currentDatasource,
						data: {
							from: from,
							to: to
						}
					},
					transformationData: {
						timezone: timezone
					}
				});
			}
		})();
	}, [currentDatasource]);
	// React.useEffect(() => {
	// 	if (datasourceErrors !== undefined) {
	// 		console.log(datasourceErrors);
	// 		const groupedErrors = groupErrorsGroupedByDate(datasourceErrors, timezone);
	// 		setDatasourceErrors(groupedErrors);
	// 	}
	// }, [datasourceErrors]);
	return (
		<Grid container justify="center">
			<Grid item xs={12}>
				<Grid container justify="space-between" className={classes.borderbottom} alignItems="center">
					<Grid item className={classes.headContainer}>
						<Typography className="size16 boldText">Errors</Typography>
					</Grid>
					<Grid className={classes.headContainer} item>
						<CloseIcon className="hoverable" onClick={() => close()} fontSize="small" />
					</Grid>
				</Grid>
				<Grid container className="padding" style={{ padding: 20 }}>
					<Grid item className={classes.chartContainer} xs={12}>
						<Grid container style={{ height: "100%" }} justify="center" alignItems="center">
							<Grid item>
								<BarChart
									width={600}
									height={310}
									data={dataMovedArray}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 50
									}}
								>
									<CartesianGrid vertical={false} />
									<XAxis
										label={{
											value: "Data Sources",
											position: "insideBottom",
											offset: -40
										}}
										tick={{
											fontFamily: "Nunito",
											fontSize: "10px",
											fill: "#2C2C2C"
										}}
										stroke="#EDEDED"
										tickLine={false}
										dataKey="name"
										interval={0}
										angle={-40}
										textAnchor="end"
									/>
									<YAxis
										tickCount={6}
										tick={{
											fontFamily: "Nunito",
											fontSize: "10px",
											fill: "#2C2C2C"
										}}
										stroke="#EDEDED"
										label={{
											value: "Runs with error",
											angle: -90,
											position: "insideLeft",
											offset: 10
										}}
										tickLine={false}
									/>
									<Tooltip
										cursor={false}
										formatter={(value, name, props) =>
											name === "runs" ? [value, "Error Runs", props] : [value, name, props]
										}
									/>
									{/* <Legend /> */}
									<Bar
										dataKey="runs"
										fill="#F77070"
										barSize={23}
										// onClick={(e) => handleDatasourceClick(e)}
										cursor="pointer"
									/>
									{/* <Brush dataKey="rowsMoved" height={30} stroke="#8884d8" /> */}
								</BarChart>
							</Grid>
						</Grid>
					</Grid>

				</Grid>
			</Grid>
		</Grid>
	);
}
