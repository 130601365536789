import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";
import { SUBSCRIPTION_LIST_LOADED, SUBSCRIPTION_LIST_LOADING } from "redux/actionTypes/actionTypes";
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";

const fetchSubscriptions = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SUBSCRIPTION_LIST_LOADING,
        SUBSCRIPTION_LIST_LOADED,
        {
            module: "subscription",
            method: "getAllSubscriptions",
        },
        HttpErrorHandler
    );
};

export { fetchSubscriptions };
