const CheckForPermission = (requiredPermissions, userPermissions) => {
    let check = false
    if (!Array.isArray(requiredPermissions)) {
        return true;
    }
    requiredPermissions.forEach((requiredPermission) => {
        userPermissions.forEach((userPermission) => {
            if (requiredPermission === userPermission) {
                check = true;
            }
        });
    });
    return check;

}
export default CheckForPermission