/* eslint-disable default-case */
import { SUBSCRIPTION_LIST_LOADED, SUBSCRIPTION_LIST_LOADING } from "redux/actionTypes/actionTypes";

const initialState = {
    subscriptions: [],
    subscriptionsIsLoading: false,
};

export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_LIST_LOADED:
            return {
                ...state,
                subscriptions: action.payload,
            };
        case SUBSCRIPTION_LIST_LOADING:
            return {
                ...state,
                subscriptionsIsLoading: action.payload,
            };
        default:
            return state;
    }
};
