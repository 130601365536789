import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import theme from "./utils/theme";
import Routing from "services/Routing";
import { Provider } from "react-redux";
import { Store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import Notifier from "./common/notifier";

function App() {
    return (
        <Provider store={Store}>
            <PersistGate loading={null} persistor={persistor}>
                <SnackbarProvider>
                    <ThemeProvider theme={theme}>
                        <Notifier />
                        <BrowserRouter>
                            <Routing />
                        </BrowserRouter>
                    </ThemeProvider>
                </SnackbarProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
