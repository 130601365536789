import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class AuthEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `auth`;
        this.transformer = null;
    }
    adminRegister(data) {
        return axios.post(`${this.resourceEndpoint}/custom-user-register`, data.apiData.data);
    }
    adminRegisterWithoutZoho(data) {
        return axios.post(`${this.resourceEndpoint}/custom-register/unlimited`, data.apiData.data);
    }
    pauseAccount(data) {
        return axios.post(`/admin_app/account/pause`, data.apiData);
    }
    resumeAccount(data) {
        return axios.post(`/admin_app/account/resume`, data.apiData);
    }
}

export default AuthEndpoints;
