import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import UiTable from "../../../../common/Table/UiTable";
import { Grid, makeStyles, Button, ButtonBase, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SelectColumnFilter from "../../../../common/Table/selectColumnFilter";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import PauseIcon from "@material-ui/icons/Pause";
import { changeStatusOfReport } from "services/Api/api";
// import successSnackbar from "utils/successSnackbar";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import useApi from "hooks/useApi";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch } from "react-redux";
import { enqueueSnackbar as enqueueSnackbarAction, closeSnackbar as closeSnackbarAction } from "redux/actions/NotifierActions";
import CloseIcon from "@material-ui/icons/Close";
import { fetchReverseSyncDashboardData } from "../../../../redux/actions/dashboardActions";
import { Delete } from "@material-ui/icons";

// import CheckPermissionFunction from "services/CheckPermissionFunction";
// import ConfiguredReportActionButtons from "components/common/ActionButtonGroups/ConfiguredReportActionButtons";
import Image from "../../../../common/UIComponents/Image";
import { Link } from "react-router-dom";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Config from "constants/config";
// import ConfiguredSyncsActionButtons from "components/common/ActionButtonGroups/ConfiguredSyncsActionButtons";
import SyncssActionButtons from "common/ActionButtonGroups/SyncsActionsButtons";
import RowSelectTable from "common/Table/RowSelectTable";
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";
import moment from "moment";
const closeSnackbar = (dispatch, ...args) => dispatch(closeSnackbarAction(...args));
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,

        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
}));

const CustomSwitch = withStyles({
    switchBase: {
        color: "#A5AEB7",
        "&$checked": {
            color: "#23BE82",
        },
        "&$checked + $track": {
            backgroundColor: "#23BE82",
        },
    },
    checked: {},
    track: {},
})(Switch);

export default function ReverseSyncTable({ reverseTableNavClick, reverseSyncSlug, account_id, logsData }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [, statusLoading, changeConfiguredSyncStatus] = useApi();
    const [reportLoading, setReportLoading] = React.useState(null);
    const [isSelected, setIsSelected] = React.useState(false);
    const [, runSyncIsLoading, runAllSync] = useApi();
    const [, pauseSyncIsLoading, pauseAllSync] = useApi();
    const [, deleteSyncIsLoading, deleteAllSync] = useApi();

    // const editPermission = CheckPermissionFunction(["report.edit"]);
    const [skipPageReset, setSkipPageReset] = React.useState(true);
    let selecetedConfiguredId = [];
    const handleStatusChange = async (checked, configured_report_id) => {
        let status = "";
        if (checked) {
            status = "ACTIVE";
        } else {
            status = "PAUSED";
        }
        const data = {
            status,
        };
        setReportLoading(configured_report_id);
        await changeConfiguredSyncStatus({
            module: "bulkActions",
            method: "changeConfiguredSyncStatusAdmin",
            successToast: "Configured sync status changed successfully.",
            apiData: {
                slug: reverseSyncSlug,
                configuredSyncId: configured_report_id,
                data: data,
                account_id: account_id,
            },
        });
        dispatch(fetchReverseSyncDashboardData(logsData, account_id));
        setReportLoading(null);
        reverseTableNavClick(reverseSyncSlug);
    };
    const resumePipelineHandler = async () => {
        try {
            await runAllSync({
                module: "bulkActions",
                method: "syncRun",
                returnResult: true,
                apiData: {
                    data: {
                        account_id: account_id,
                        configured_sync_ids: selecetedConfiguredId,
                    },
                    reverseConnectorSlug: reverseSyncSlug,
                },
                successToast: "Ran Selected Sync ",
            });
            dispatch(fetchReverseSyncDashboardData(logsData, account_id));
        } catch (e) {
            console.log(e);
        }
    };
    const pauseAllPipelineHandler = async () => {
        await pauseAllSync({
            module: "bulkActions",
            method: "syncStatusChange",
            returnResult: true,
            apiData: {
                data: {
                    account_id: account_id,
                    status: "PAUSED",
                    configured_sync_ids: selecetedConfiguredId,
                },
                reverseConnectorSlug: reverseSyncSlug,
            },
            successToast: "Selected Sync Paused",
        });
        dispatch(fetchReverseSyncDashboardData(logsData, account_id));
    };
    const deleteAllPipelineHandler = async () => {
        await deleteAllSync({
            module: "bulkActions",
            method: "deleteSelectedSync",
            returnResult: true,
            apiData: {
                data: {
                    account_id: account_id,
                    configured_report_ids: selecetedConfiguredId,
                    configured_sync_ids: selecetedConfiguredId,
                },
                reverseConnectorSlug: reverseSyncSlug,
            },
            successToast: "Selected Sync Deleted",
        });
        dispatch(fetchReverseSyncDashboardData(logsData, account_id));
    };
    const setSelectedCallback = (rows) => {
        let arr = [];
        rows.forEach((element) => {
            const ele = { ...element };
            arr.push(ele.original.id);
        });
        selecetedConfiguredId = arr;
    };
    const columns = [
        {
            Header: "Configured Syncs Name",
            accessor: "label",
            sortable: false,
            Cell: (props) => (
                <Grid style={{ minWidth: 450 }} container justify="space-between" alignItems="center">
                    <Grid container xs={10} alignItems="center">
                        <Image
                            style={{ height: 20, width: 20, marginRight: 10 }}
                            src={`${Config.imageBaseUrl}images/connectors/icons/${reverseSyncSlug}.png`}
                            name={reverseSyncSlug}
                        />
                        {/* <Link to={`/reverse-connectors/${reverseSyncSlug}/sync/${props.row.original.id}`}> */}
                        <Typography className={false ? "hoverable" : null} variant="subtitle1">
                            {props.row.original.label}
                        </Typography>
                        {/* </Link> */}
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Number of Runs",
            accessor: "number_of_runs",
            Filter: false,
            sortable: true,
        },
        {
            Header: "Last run status",
            accessor: "last_run_status",
            sortable: true,
            Filter: false,
            Actions: (props) => <></>,
            Cell: (props) => (
                <Grid>
                    <Typography variant="subtitle1">
                        {props.row.original.last_run_status === null
                            ? props.row.original.no_of_runs === 0
                                ? "No run initiated"
                                : "Running"
                            : props.row.original.last_run_status}
                    </Typography>
                </Grid>
            ),
        },
        {
            Header: "Last run time",
            Filter: false,
            accessor: "last_run_at",
            sortable: true,
            sortType: (a, b, name, desc) => {
                console.log(a);
                var a1 = new Date(a.values.last_run_at).valueOf();
                var b1 = new Date(b.values.last_run_at).valueOf();
                if (a.values.last_run_at === null || a.values.last_run_at === undefined) {
                    if (!desc) {
                        a1 = new Date("01-01-2500").valueOf();
                        console.log(a1);
                    } else {
                        a1 = new Date("01-01-1800").valueOf();
                    }
                }
                if (b.values.last_run_at === null || b.values.last_run_at === undefined) {
                    if (!desc) {
                        b1 = new Date("01-01-2500").valueOf();
                    } else {
                        b1 = new Date("01-01-1800").valueOf();
                    }
                }
                console.log(a1);
                if (desc) {
                    return a1 > b1 ? 1 : -1;
                }
                return a1 < b1 ? -1 : 1;
            },
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center" style={{ minWidth: 100 }}>
                    <Typography variant="subtitle1">
                        {console.log(props.row.original.last_run_at)}
                        {props.row.original.last_run_at === null || props.row.original.last_run_at === undefined
                            ? "No run initiated"
                            : moment(props.row.original?.last_run_at).format("DD-MMM-YYYY hh:mm:ss A")}
                    </Typography>
                </Grid>
            ),
        },
        {
            Header: "Rows Moved",
            accessor: "rows_moved",
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <>
                    <Typography style={{ fontSize: 12 }} variant="subtitle1">
                        {props.row.original.rows_moved.toLocaleString()}
                    </Typography>
                </>
            ),
        },
        {
            Header: (props) => {
                return (
                    <>
                        <Grid container xs={12}>
                            <Typography style={{ fontSize: 12, width: 150 }} variant="subtitle1">
                                Status
                            </Typography>
                            <CheckForPermissionHOC requiredPermissions={["bulk.syncs"]}>
                                <Grid container xs={12} style={{ marginTop: 30, marginBottom: "20px" }} justifyContent="center">
                                    <Tooltip title="Run Selected Sync">
                                        <Grid item xs={4} container justifyContent="center">
                                            {runSyncIsLoading ? (
                                                <CircularProgress size={15} />
                                            ) : (
                                                <ButtonBase
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        resumePipelineHandler();
                                                    }}
                                                    disableTouchRipple
                                                    disabled={!isSelected}
                                                >
                                                    <PlayArrowIcon
                                                        className={classes.icon}
                                                        style={{ color: `${!isSelected ? "#A5AEB7" : "#2E394B"}` }}
                                                        fontSize="small"
                                                    />
                                                </ButtonBase>
                                            )}
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Pause Seleted Sync">
                                        <Grid item xs={4} container justifyContent="center">
                                            {pauseSyncIsLoading ? (
                                                <CircularProgress size={15} />
                                            ) : (
                                                <ButtonBase
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        resumePipelineHandler();
                                                    }}
                                                    disableTouchRipple
                                                    disabled={!isSelected}
                                                >
                                                    <PauseIcon
                                                        className={classes.icon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            pauseAllPipelineHandler();
                                                        }}
                                                        style={{ color: `${!isSelected ? "#A5AEB7" : "#2E394B"}` }}
                                                        fontSize="small"
                                                    />
                                                </ButtonBase>
                                            )}
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Delete Selected Sync">
                                        <Grid item xs={4} container justifyContent="center">
                                            {deleteSyncIsLoading ? (
                                                <CircularProgress size={15} />
                                            ) : (
                                                <ButtonBase
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        resumePipelineHandler();
                                                    }}
                                                    disableTouchRipple
                                                    disabled={!isSelected}
                                                >
                                                    <Delete
                                                        className={classes.icon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteAllPipelineHandler();
                                                        }}
                                                        style={{ color: `${!isSelected ? "#A5AEB7" : "#2E394B"}` }}
                                                        fontSize="small"
                                                    />
                                                </ButtonBase>
                                            )}
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </CheckForPermissionHOC>
                        </Grid>
                    </>
                );
            },

            Filter: false,
            sortable: false,
            Cell: (props) => {
                return (
                    <Grid style={{ minWidth: 130 }} container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.status}</Typography>
                        {statusLoading && props.row.original.id === reportLoading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <CustomSwitch
                                checked={props.row.original.status !== "PAUSED"}
                                onChange={(e) => handleStatusChange(e.target.checked, props.row.original.id)}
                                size="small"
                            />
                        )}
                    </Grid>
                );
            },
            accessor: "status",
            Filter: SelectColumnFilter,
            filter: "includes",
        },
        // {
        //     Header: (props) => {
        //         return (
        //             <>
        //                 <Typography style={{ fontSize: 12, width: 150 }} variant="subtitle1">
        //                     Actions
        //                 </Typography>
        //             </>
        //         );
        //     },
        //     accessor: "action",
        //     Filter: false,
        //     Cell: (props) => (
        //         <Grid container style={{ minWidth: 100 }}>
        //             <SyncssActionButtons
        //                 reverseConnectorSlug={reverseSyncSlug}
        //                 account_id={account_id}
        //             />
        //         </Grid>
        //     ),
        //     sortable: false,
        // },
    ];

    const dashboard = useSelector((state) => state.dashboard);
    const [data, setData] = React.useState(dashboard.reverseSyncReports);

    React.useEffect(() => {
        setData(dashboard.reverseSyncReports);
    }, [dashboard.reverseSyncReports]);

    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <Box>
            <RowSelectTable
                columns={columns}
                data={data}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                componentName="configured_report"
                reverseSyncSlug={reverseSyncSlug}
                setSelectedCallback={setSelectedCallback}
                setIsSelected={setIsSelected}
            />
        </Box>
    );
}
