import React from "react";
import Typography from "@material-ui/core/Typography";
import UiTable from "../../../../common/Table/UiTable";
import { Grid, makeStyles, Button } from "@material-ui/core";
import Check from "../../../../assets/images/dashboard/check.svg";
import Cross from "../../../../assets/images/dashboard/x.svg";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import Image from "../../../../common/UIComponents/Image";
// import DatasourceActionButtons from "components/common/ActionButtonGroups/DatasourceActionButtons";
import Config from "constants/config";
import SyncssActionButtons from "common/ActionButtonGroups/SyncsActionsButtons";
// import ReverseConnectorActionButtons from "components/common/ActionButtonGroups/ReverseConnectorActionButtons";
import checkForRoles from 'utils/CheckForRoles';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        transition: "initial",
        transitionDuration: "0.1s",
        marginRight: 4,
        "&:hover": {
            cursor: "pointer",
            fontSize: 22,
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
    navBtn: {
        "&:hover": {
            cursor: "pointer",
        },
    },
}));

export default function ReverseConnectorTable({ reverseTableNavClick, account_id }) {
    const classes = useStyles();
    const role = useSelector(state => state.user.user.roles)

    const columns = React.useMemo(
        () => [
            {
                Header: (props) => (
                    <Grid conatiner>
                        <Typography variant="subtitle1">
                            Reverse Connector Name
                            <span style={{ fontSize: 10, marginLeft: 10 }}>
                                <img src={Check} /> - Successful runs, <img src={Cross} /> - Error runs
                            </span>
                        </Typography>
                    </Grid>
                ),
                accessor: "display_label",
                sortable: false,
                Cell: (props) => (
                    <Grid style={{ minWidth: 400 }} container justify="space-between" alignItems="center">
                        <Grid container xs={9} alignItems="center">
                            <Image
                                style={{ height: 20, width: 20, marginRight: 10, borderRadius: 2 }}
                                src={`${Config.imageBaseUrl}images/connectors/icons/${props.row.original.datasource_slug}.png`}
                                name={props.row.original.datasource_slug}
                                alt="icon"
                            />
                            <Typography
                                className={classes.navBtn}
                                onClick={checkForRoles(['Super-Admin', 'Account-Admin', 'Support'], role) ? () => reverseTableNavClick(props.row.original.slug) : () => (null)}
                                variant="subtitle1"
                            >
                                {props.row.original.display_label}
                            </Typography>
                        </Grid>
                        <Grid container xs={3} justify="flex-end">
                            <Typography style={{ color: "#40C77E", marginRight: 15 }} variant="subtitle1">
                                <img src={Check} />
                                {props.row.original.success_runs}{" "}
                            </Typography>
                            <Typography style={{ color: "#F77070" }} variant="subtitle1">
                                <img src={Cross} />
                                {props.row.original.failed_runs}{" "}
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
            {
                Header: "Syncs Configured",
                accessor: "number_of_syncs",
                Filter: false,
                sortable: true,
            },
            {
                Header: "Number of Runs",
                accessor: "success",
                Filter: false,
                sortable: false,
                Cell: (props) => {
                    return (
                        <>
                            <Typography style={{ fontSize: 12 }} variant="subtitle1">
                                {props.row.original.total_runs}
                            </Typography>
                        </>
                    );
                },
            },
            {
                Header: "Rows Moved",
                accessor: "rows_moved",
                Filter: false,
                sortable: true,
                Cell: (props) => (
                    <>
                        <Typography style={{ fontSize: 12 }} variant="subtitle1">
                            {props.row.original.rows_moved}
                        </Typography>
                    </>
                ),
            },
            // {
            //     Header: "Actions",
            //     Filter: false,
            //     Cell: (props) => <SyncssActionButtons reverseConnectorSlug={props.row.original.slug} account_id={account_id} />,
            //     sortable: false,
            // },
        ],
        []
    );

    const dashboard = useSelector((state) => state.dashboard);
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        setData(dashboard.dataDestinations);
    }, [dashboard.dataDestinations]);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <motion.div
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: "-40vh" }}
            // exit={{ opacity: 0, x: "-80vw", transition: {duration: 0.2} }}
            key="expanded"
        >
            <UiTable columns={columns} data={data} setData={setData} updateMyData={updateMyData} skipPageReset={skipPageReset} />
        </motion.div>
    );
}
