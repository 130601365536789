import useApi from "hooks/useApi";
import React from "react";
import { CircularProgress, FormGroup, FormLabel, Grid, Typography } from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Field, Form, Formik } from "formik";
import { makeStyles } from "@material-ui/core";
import { object, number, string } from "yup";

const useStyle = makeStyles((theme) => { });
const AddUsage = (props) => {
    const { invoice_id, customer_id } = useParams();
    const navigate = useNavigate();
    const classes = useStyle();
    const [, updateSubscriptionIsLoading, updateInvoice] = useApi();
    const [dataUsage, dataUsageIsLoading, getDataUsage] = useApi(
        React.useMemo(
            () => ({
                initialValues: [],
                module: "subscription",
                method: "getDataUsage",
                apiData: {
                    invoice_id: invoice_id,
                },
            }),
            []
        )
    );
    let initialValues = {
        row_price: dataUsage?.rows_moved.extra_price,
        row_name: dataUsage?.rows_moved?.name,
        row_description: dataUsage?.rows_moved?.description,
        no_of_rows: dataUsage?.rows_moved?.number_of_rows,
        dc_managed_price: dataUsage?.dc_managed.extra_price,
        dc_managed_name: dataUsage?.dc_managed?.name,
        dc_managed_description: dataUsage?.dc_managed?.description,
        dc_managed_size: dataUsage?.dc_managed?.dc_managed_size,
    };
    const updateSubscriptionfunc = async (values) => {
        try {
            console.log(values);
            await updateInvoice({
                module: "subscription",
                method: "addUsage",
                apiData: {
                    invoice_id: invoice_id,
                    ...values,
                },
            });
            navigate("/invoices");
        } catch (e) { }
    };
    if (dataUsageIsLoading) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container xs={12} direction="column" justify="center" alignItems="center">
            <Grid item sm={9} xs={12} justify="center" style={{ minWidth: "470px" }}>
                <Formik
                    validationSchema={object({
                        row_price: number().required(),
                        row_name: string().required(),
                        row_description: string().required(),
                        dc_managed_price: number().required(),
                        dc_managed_name: string().required(),
                        dc_managed_description: string().required(),
                    })}
                    initialValues={initialValues}
                    onSubmit={async (values, formikHelpers) => {
                        updateSubscriptionfunc(values);
                    }}
                >
                    {({ values, errors, isSubmitting, isValidating, touched, setFieldValue }) => (
                        <Form>
                            <FormGroup>
                                <FormLabel>
                                    <Typography style={{ fontSize: 18, fontWeight: 500 }}>Rows Moved</Typography>
                                </FormLabel>
                                <Field
                                    error={touched.row_price && errors.hasOwnProperty("row_price")}
                                    name="row_price"
                                    as={TextField}
                                    fullWidth
                                    margin="dense"
                                    label="amount"
                                    variant="outlined"
                                    helperText={touched.row_price && errors?.row_price}
                                ></Field>
                                <Field
                                    error={touched.no_of_rows && errors.hasOwnProperty("no_of_rows")}
                                    name="no_of_rows"
                                    as={TextField}
                                    fullWidth
                                    margin="dense"
                                    disabled
                                    label="No of Rows (M)"
                                    variant="outlined"
                                    helperText={touched.no_of_rows && errors?.no_of_rows}
                                ></Field>
                                <Field
                                    error={touched.row_name && errors.hasOwnProperty("row_name")}
                                    name="row_name"
                                    as={TextField}
                                    fullWidth
                                    margin="dense"
                                    label="name"
                                    variant="outlined"
                                    helperText={touched.narow_nameme && errors?.row_name}
                                ></Field>
                                <Field
                                    error={touched.row_description && errors.hasOwnProperty("row_description")}
                                    name="row_description"
                                    as={TextField}
                                    fullWidth
                                    margin="dense"
                                    label="description"
                                    variant="outlined"
                                    helperText={touched.row_description && errors?.row_description}
                                ></Field>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>
                                    <Typography style={{ marginTop: 20, fontSize: 18, fontWeight: 500 }}>
                                        DC Managed Warehouse Usage
                                    </Typography>
                                </FormLabel>
                                <Field
                                    error={touched.dc_managed_price && errors.hasOwnProperty("dc_managed_price")}
                                    name="dc_managed_price"
                                    as={TextField}
                                    fullWidth
                                    margin="dense"
                                    label="amount"
                                    variant="outlined"
                                    helperText={touched.dc_managed_price && errors?.dc_managed_price}
                                ></Field>
                                <Field
                                    error={touched.dc_managed_size && errors.hasOwnProperty("dc_managed_size")}
                                    name="dc_managed_size"
                                    as={TextField}
                                    fullWidth
                                    margin="dense"
                                    disabled
                                    label="DC Managed Usage (GB)"
                                    variant="outlined"
                                    helperText={touched.dc_managed_size && errors?.dc_managed_size}
                                ></Field>
                                <Field
                                    error={touched.dc_managed_name && errors.hasOwnProperty("dc_managed_name")}
                                    name="dc_managed_name"
                                    as={TextField}
                                    fullWidth
                                    margin="dense"
                                    label="name"
                                    variant="outlined"
                                    helperText={touched.dc_managed_name && errors?.dc_managed_name}
                                ></Field>
                                <Field
                                    error={touched.dc_managed_description && errors.hasOwnProperty("dc_managed_description")}
                                    name="dc_managed_description"
                                    as={TextField}
                                    fullWidth
                                    margin="dense"
                                    label="description"
                                    variant="outlined"
                                    helperText={touched.dc_managed_description && errors?.dc_managed_description}
                                ></Field>
                            </FormGroup>

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={values?.row_price === 0 && values?.dc_managed_price === 0}
                                        fullWidth
                                    >
                                        {updateSubscriptionIsLoading ? (
                                            <CircularProgress
                                                style={{
                                                    marginRight: 10,
                                                    color: "white",
                                                }}
                                                size={16}
                                            />
                                        ) : null}
                                        save changes
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    );
};
export default AddUsage;
