import React from "react";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import { IconButton, Grid, CircularProgress } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
// import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
// import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import clsx from "clsx";
import PhoneIcon from '@material-ui/icons/Phone';
import useApi from "hooks/useApi";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "30%",
        padding: 10,
        margin: 8,
    },
    userintro: {
        padding: 0,
        marginBottom: 15,
    },
    userlogo: {
        width: 60,
        height: 60,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #F6F6F6",
        marginRight: 20,
    },
    userhead: {
        fontWeight: "bolder",
        fontSize: 16,
    },
    usersubhead: {
        fontSize: 12,
        color: "#626262",
    },
    icon: {
        color: "#626262",
    },
    listitem: {
        border: "1px solid #F6F6F6",
        marginBottom: 5,
    },
    listitemcontent: {
        display: "flex",
        flex: 1,
    },
    listitemtitle: {
        fontSize: 12,
        flex: 1,
    },
    avatar: {
        height: 50,
        width: 50,
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function UserCard({ params, onClick, index, key }) {
    const classes = useStyles();
    const handleClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleCloseMenu = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <Paper
            key={key}
            className={clsx(classes.root)}
        >
            <List>
                <ListItem button className={classes.userintro}>
                    <Box className={classes.listitemcontent}>
                        <ListItemIcon>
                            <Box className={classes.userlogo}>
                                <Avatar
                                    className={classes.avatar}
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                >
                                    {params?.name?.[0]}
                                </Avatar>
                            </Box>
                        </ListItemIcon>
                        <ListItemText
                            primary={params.name}
                            secondary={params.role}
                            classes={{
                                primary: classes.userhead,
                                secondary: classes.usersubhead,
                            }}
                        />


                    </Box>
                </ListItem>
                <ListItem button className={classes.listitem}>
                    <Box className={classes.listitemcontent}>

                        <Typography className={classes.listitemtitle} style={{ fontWeight: 500 }} align='center'>
                            {params.status}
                        </Typography>
                    </Box>
                </ListItem>

                    <Box className={classes.listitemcontent}>
                       <Grid  style={{paddingLeft:16,paddingRight:16,paddingTop:8,paddingBottomn:8}}>
                            <EmailOutlinedIcon fontSize="small"  style={{color:'gray'}}/>
                            </Grid>
                        <Grid xs={12} style={{paddingLeft:16,paddingRight:16,paddingTop:8,paddingBottomn:8}}>
                            <Typography className={classes.listitemtitle} style={{ wordBreak: 'break-word' }}>
                                {params.email}
                            </Typography>
                        </Grid>
                       
                    </Box>
                    <Box className={classes.listitemcontent}>
                       <Grid  style={{paddingLeft:16,paddingRight:16,paddingTop:8,paddingBottomn:8}}>
                            <PhoneIcon fontSize="small"  style={{color:'gray'}}/>
                            </Grid>
                        <Grid xs={12} style={{paddingLeft:16,paddingRight:16,paddingTop:8,paddingBottomn:8}}>
                            <Typography className={classes.listitemtitle} style={{ wordBreak: 'break-word' }}>
                                {params.phone}
                            </Typography>
                        </Grid>
                       
                    </Box>

                {/* <ListItem button  className={classes.listitem}>
                    <Box className={classes.listitemcontent}>
                        <ListItemIcon>
                            <PhoneOutlinedIcon fontSize="small"/>
                        </ListItemIcon>
                        <Typography className={classes.listitemtitle}>{params.phone}</Typography>
                    </Box>
                </ListItem> */}
                {/* 
                <ListItem button  className={classes.listitem}>
                    <Box className={classes.listitemcontent}>
                        <ListItemIcon>
                            <LocationOnOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography className={classes.listitemtitle}>{params.address}</Typography>
                    </Box>
                </ListItem> */}
            </List>
        </Paper>
    );
}
