import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchWorkspaces } from "redux/actions/dashboardActions";
import WorkspaceTable from "./WorkspaceTable";
import { Grid, Typography, CircularProgress, LinearProgress } from "@material-ui/core";

import NoData from "common/NoData/NoData";

const Workspaces = (props) => {
    const workspaces = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();

    //fetch data on the first mount if not fethched before
    React.useEffect(() => {
        dispatch(fetchWorkspaces(props.account_id));
    }, []);
    console.log(workspaces.workspaces);

    //loading indicator
    if (workspaces.workspacesIsLoading && workspaces.workspaces?.length === 0) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    if (workspaces.workspaces?.length === 0) {
        return <NoData showButton={true} height={"85vh"} componentType="orchestrations" />;
    }

    return (
        <>
            <Grid className="marginBottom" container justifyContent="center">
                <Grid item xs={12} className="pageContainer" style={{ padding: 24 }}>
                    <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 10 }}>
                        <Typography className="size16" variant="h3">
                            Workspaces
                        </Typography>
                    </Grid>
                    {workspaces.workspacesIsLoading ? <LinearProgress /> : null}

                    <WorkspaceTable tableData={workspaces.workspaces} />
                </Grid>
            </Grid>
        </>
    );
};

export default Workspaces;
