import useApi from "hooks/useApi";
import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { useParams } from "react-router";
import { Typography, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Field, Form, Formik, useField } from "formik";
import { makeStyles, InputAdornment, Chip, FormLabel, Select, FormControl, InputLabel, IconButton } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { object, number, array } from "yup";

const useStyle = makeStyles((theme) => {
    return {
        textCenter: {
            textAlign: "center",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            height: 22,
            fontSize: 9,
            marginRight: 5,
        },
        primColor: {
            color: theme.palette.primary.main,
        },
        hr: {
            marginTop: 20,
            marginBottom: 20,
        },
        heading: {
            color: "#2C2C2C",
        },
        inputLabel: {
            position: "absolute",
            left: 10,
            top: -10,
            paddingLeft: 2,
            paddingBottom: 3,
            backgroundColor: "#FFFFFF",
            paddingRight: 45,
            zIndex: 1000,
        },
        select: {
            margin: "13px 0px",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[3],
            padding: theme.spacing(2, 4, 3),
            borderRadius: 10
        },
    };
});
const CustomSelect = (props) => {
    const classes = useStyle();
    const [field] = useField({
        name: props.name,
        type: "select",
        value: props.value,
    });
    console.log(field);
    return (
        <FormControl fullWidth className={classes.select}>
            <InputLabel className={classes.inputLabel}>{props.label}</InputLabel>
            <Select {...props} {...field} onChange={props.onChange}>
                {props.children}
            </Select>
        </FormControl>
    );
};
const EditSubscription = (props) => {
    const subscription_id = props.subscription_id;
    const account_id = props.account_id
    const classes = useStyle();
    const [, updateSubscriptionIsLoading, uodateSubscription] = useApi();
    const [, subscriptionCancelIsLoading, cancelSubscription] = useApi();

    // console.log(subscriptionItemsIsLoading);
    // if (!subscriptionItemsIsLoading) {
    //     console.log(subscriptionItems);
    // }
    // if (subscriptionItemsIsLoading) {
    //     return (
    //         <Grid container item xs={12} justifyContent="center">
    //             <CircularProgress />
    //         </Grid>
    //     );
    // }
    const initialValues = {
        plan_name: props?.subscriptionItems?.plan_name,
        features: props?.subscriptionItems?.features,
        price: props?.subscriptionItems?.plan_price,
        rows: props?.subscriptionItems?.rows,
        price_per_mill: props?.subscriptionItems?.price_per_million,
        addons: props?.subscriptionItems?.addons.map((addon) => {
            let addonObj = {
                addon_code: addon.zoho_addon_code,
                price: addon.addon_price,
            };
            return addonObj;
        }),
        tier: props.subscriptionItems?.metered_slab_values ? JSON.parse(props.subscriptionItems?.metered_slab_values)?.slabs : []
    };

    const updateSubscriptionfunc = async (values) => {
        console.log(values);
        let isAddonEdited = false;
        const defaultAddon = props?.subscriptionItems?.addons.map((addon) => {
            let addonObj = {
                addon_code: addon.addon_code,
                price: addon.addon_price,
            };
            return addonObj;
        });
        let resFor = defaultAddon.filter(
            (item1) => !values.addons.some((item2) => item2.addon_code === item1.addon_code && item2.price == item1.price)
        );
        let resRev = values.addons.filter(
            (item1) => !defaultAddon.some((item2) => item2.addon_code === item1.addon_code && item2.price == item1.price)
        );
        console.log(defaultAddon);
        console.log(values.addons);
        console.log(resFor);
        isAddonEdited = resFor?.length === 0 ? false : true;
        if (resFor.length === 0) {
            isAddonEdited = resRev?.length === 0 ? false : true;
        }

        console.log(isAddonEdited);

        try {
            console.log(values);
            await uodateSubscription({
                module: "subscription",
                method: "editSubscription",
                apiData: {
                    subscription_id: subscription_id,
                    ...values,
                    plan_price:values.price,
                    isAddonEdited: isAddonEdited,
                    account_id:account_id,
                    base_rows:values.rows
                },
                successToast:"Subscription Updated"
            });
        } catch (e) { }
    };
    console.log(props.subscriptionItems?.plan_type);
    const addMore = (setFieldValue, values) => {
        const addons = values.addons;
        console.log(values);
        const addonObject = {
            addon_code: null,
            price: null,
        };
        addons.push(addonObject);
        setFieldValue("addons", addons);
    };
    const deleteAddon = (setFieldValue, values, index) => {
        const addons = values.addons;
        values.addons.splice(index, 1);
        setFieldValue("addons", addons);
    };

    const addMoreTier = (setFieldValue, values) => {
        const tier = values.tier;
        const tierObject = {
            from: null,
            to: null,
            price: null,
        };
        tier.push(tierObject);
        setFieldValue("tier", tier);
    };
    const deleteTier = (setFieldValue, values, index) => {
        const tier = values.tier;
        values.tier.splice(index, 1);
        setFieldValue("tier", tier);
    };
    console.log(props.subscriptionItems);
    console.log(initialValues)
    if (props.subscriptionItems) {
        return (
            <Grid container xs={12} direction="column" justify="center" alignItems="center">
                <Grid item sm={6} xs={12} justify="center" style={{ minWidth: "470px", marginTop: 30 }} className={classes.paper}>
                    <Grid container item xs={12} style={{ paddingTop: 20, borderBottom: '1px solid black', marginBottom: 10 }}>
                        <Grid item style={{ marginBottom: "15px" }} xs={6}>
                            <Typography variant="body1" style={{ fontWeight: 600, fontSize: 16 }}>
                                Customer name - <span style={{ fontWeight: 400 }}>{props.subscriptionItems?.customer_name}</span>
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginBottom: "15px" }} xs={6}>
                            <Typography variant="body1" style={{ fontWeight: 600, fontSize: 16 }}>
                                Type -{" "}
                                <span style={{ fontWeight: 400 }}>
                                    {props.subscriptionItems?.plan_type === "generic" ? "Self Serve" : "Custom"}
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="h3" style={{ marginTop: 10, marginBottom: 10 }}>Plan</Typography>
                    <Formik
                        validationSchema={object({
                            features: array().min(1),
                            price: number(),
                        })}
                        initialValues={initialValues}
                        onSubmit={async (values, formikHelpers) => {
                            updateSubscriptionfunc(values);
                        }}
                    >
                        {({ values, errors, isSubmitting, isValidating, touched, setFieldValue, handleChange }) => (
                            <Form>
                                <FormGroup>
                                    <Field
                                        name="plan_name"
                                        error={touched.id && errors.hasOwnProperty("plan_name")}
                                        as={TextField}
                                        disabled
                                        label="plan name"
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </FormGroup>
                                <Field
                                    error={touched.price && errors.hasOwnProperty("price")}
                                    name="price"
                                    as={TextField}
                                    fullWidth
                                    // disabled={props.subscriptionItems?.plan_type === "generic" ? true : false}
                                    margin="dense"
                                    label="Plan price"
                                    variant="outlined"
                                    helperText={touched.price && errors?.price}
                                ></Field>
                                <Field
                                    error={touched.rows && errors.hasOwnProperty("rows")}
                                    name="rows"
                                    as={TextField}
                                    fullWidth
                                    // disabled={props.subscriptionItems?.plan_type === "generic" ? true : false}
                                    margin="dense"
                                    label="No. of Rows (in M)"
                                    variant="outlined"
                                    helperText={touched.rows && errors?.rows}
                                ></Field>
                                {/* <Field
                                    error={touched.price_per_mill && errors.hasOwnProperty("price_per_mill")}
                                    name="price_per_mill"
                                    as={TextField}
                                    fullWidth
                                    // disabled={props.subscriptionItems?.plan_type === "generic" ? true : false}
                                    margin="dense"
                                    label="Price per million row"
                                    variant="outlined"
                                    helperText={touched.price_per_mill && errors?.price_per_mill}
                                ></Field> */}
                                <FormGroup>
                                    <Field
                                        error={touched.features && errors.hasOwnProperty("features")}
                                        name="features"
                                        as={CustomSelect}
                                        multiple
                                        fullWidth
                                        // disabled={props.subscriptionItems?.plan_type === "generic" ? true : false}
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} className={classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                        margin="dense"
                                        label="Features"
                                        variant="outlined"
                                        helperText={touched.features && errors?.features}
                                    >
                                        {props.subscriptionItems?.all_features.map((ele) => {
                                            return (
                                                <MenuItem key={0} value={ele}>
                                                    {ele}
                                                </MenuItem>
                                            );
                                        })}
                                    </Field>
                                </FormGroup>
                                {values.plan_name !== 'Unlimited' && values.plan_name !== 'Free Plan' && <FormLabel component="legend" style={{ marginTop: 20, marginBottom: 20 }}>
                                    Rows Price Slabs
                                </FormLabel>}
                                {values.plan_name !== 'Unlimited' && values.plan_name !== 'Free Plan' && <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={4} container direction="row" justifyContent="center">
                                        <Grid item container direction="column" xs={12} style={{ marginBottom: 10 }}>
                                            <InputLabel htmlFor="outlined-adornment-amount" style={{ textAlign: 'center' }}>From (Millions)</InputLabel>
                                        </Grid>

                                        {values.tier?.map((e, i) => {
                                            return (
                                                <Grid item container direction="column" xs={12} alignItems="center">
                                                    <FormGroup>
                                                        <Field
                                                            error={
                                                                (touched?.tier === undefined
                                                                    ? touched?.tier
                                                                    : touched?.tier[i]?.lower_limit) &&
                                                                errors?.hasOwnProperty("tier") &&
                                                                errors?.tier[i]?.from
                                                            }
                                                            name={`tier[${i}].lower_limit`}
                                                            key={i}
                                                            value={values.tier[i]?.lower_limit}
                                                            // onChange={(e) => onToTieredChange(e, setFieldValue, values, 0)}
                                                            as={TextField}
                                                            label="From"
                                                            variant="outlined"
                                                            margin="dense"
                                                        />
                                                    </FormGroup>
                                                </Grid> );
                                        })}


                                    </Grid>
                                    <Grid item xs={4} container direction="row" justifyContent="center">
                                        <Grid item container direction="column" xs={12} style={{ marginBottom: 10 }}>
                                            <InputLabel htmlFor="outlined-adornment-amount" style={{ textAlign: 'center' }}>To (Millions)</InputLabel>
                                        </Grid>
                                        {values.tier?.map((e, i) => {
                                            return (
                                                i !== values.tier.length - 1 ?
                                                    <Grid item container direction="column" xs={12} alignItems="center">
                                                        <FormGroup>
                                                            <Field
                                                                error={
                                                                    (touched?.tier === undefined
                                                                        ? touched?.tier
                                                                        : touched?.tier[i]?.upper_limit) &&
                                                                    errors?.hasOwnProperty("tier") &&
                                                                    errors?.tier[i]?.upper_limit
                                                                }
                                                                name={`tier[${i}].upper_limit`}
                                                                key={i}
                                                                value={values.tier[i]?.upper_limit}
                                                                // onChange={(e) => onToTieredChange(e, setFieldValue, values, 0)}
                                                                as={TextField}
                                                                label="To"
                                                                variant="outlined"
                                                                margin="dense"
                                                            />
                                                        </FormGroup>
                                                    </Grid>: <Grid item container direction="column" xs={12} alignItems="center">
                                                        <FormGroup>
                                                            <Field
                                                                as={TextField}
                                                                label="& above"
                                                                name={`lastTier.upper_limit`}
                                                                disabled
                                                                variant="outlined"
                                                                margin="dense"
                                                            />
                                                        </FormGroup>
                                                    </Grid>)
                                        })}

                                    </Grid>
                                    <Grid item xs={4} container direction="row" justifyContent="center">
                                        <Grid item container direction="column" xs={12} style={{ marginBottom: 10 }}>
                                            <InputLabel htmlFor="outlined-adornment-amount" style={{ textAlign: 'center' }}>Price</InputLabel>
                                        </Grid>
                                        {values.tier?.map((e, i) => {
                                            return (
                                                <Grid container item justifyContent="center" spacing={1}>
                                                    <Grid item container xs={9} alignItems="center" justifyContent="center">
                                                        <FormGroup>
                                                            <Field
                                                                error={
                                                                    (touched?.tier === undefined
                                                                        ? touched?.tier
                                                                        : touched?.tier[i]?.price) &&
                                                                    errors?.hasOwnProperty("tier") &&
                                                                    errors?.tier[i]?.price
                                                                }
                                                                name={`tier[${i}].price`}
                                                                key={i}
                                                                value={values.tier[i]?.price}
                                                                // onChange={(e) => onToTieredChange(e, setFieldValue, values, 0)}
                                                                as={TextField}
                                                                label="Price"
                                                                variant="outlined"
                                                                margin="dense"
                                                            />
                                                        </FormGroup>
                                                    </Grid>
                                                    <Grid container item xs={3} alignItems="center" justifyContent="center">
                                                        <IconButton
                                                            onClick={() => deleteTier(setFieldValue, values, i)}
                                                            size="small"
                                                            style={{ margin: "2px" }}
                                                            disableRipple
                                                            disableFocusRipples
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                        {/* <Grid container item justifyContent="center" spacing={1}>
                                            <Grid item container xs={9} alignItems="center" justifyContent="center">
                                                <FormGroup>
                                                    <Field
                                                        error={
                                                            (touched?.tier === undefined
                                                                ? touched?.lastTier
                                                                : touched?.lastTier?.price) &&
                                                            errors?.hasOwnProperty("tier") &&
                                                            errors?.lastTier?.price
                                                        }
                                                        name={`lastTier.price`}
                                                        key={values.lastTier}
                                                        value={values.lastTier?.price}
                                                        // onChange={(e) => onToTieredChange(e, setFieldValue, values, 0)}
                                                        as={TextField}
                                                        label="Price"
                                                        variant="outlined"
                                                        margin="dense"
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid container item xs={3} alignItems="center" justifyContent="center"></Grid>
                                        </Grid> */}
                                    </Grid>
                                </Grid>}
                                {values.plan_name !== 'Unlimited' && values.plan_name !== 'Free Plan' && <Grid xs={12} item container justifyContent="flex-end">
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={() => addMoreTier(setFieldValue, values)}
                                    >
                                        Add More
                                    </Button>
                                </Grid>}
                                {values.plan_name !== 'Unlimited' && values.plan_name !== 'Free Plan' && <FormLabel component="legend" style={{ marginTop: 20, marginBottom: 20 }}>
                                    Addons
                                </FormLabel>}
                                {values.plan_name !== 'Unlimited' && values.plan_name !== 'Free Plan' && values.addons.map((el, i) => (
                                    <Grid container item xs={12}>
                                        <Grid item xs={10} justifyContent="space-between" alignItems="center">
                                            <FormGroup>
                                                <Field
                                                    error={
                                                        (touched?.addons === undefined
                                                            ? touched?.addons
                                                            : touched?.addons[i]?.addon_code) &&
                                                        errors?.hasOwnProperty("addons") &&
                                                        errors?.addons[i]?.addon_code
                                                    }
                                                    name={`addons[${i}].addon_code}`}
                                                    as={CustomSelect}
                                                    fullWidth
                                                    key={i}
                                                    onChange={(e) => {
                                                        setFieldValue(`addons[${i}].addon_code`, e.target.value);
                                                        console.log(values.addons[i]?.addon_code);
                                                        handleChange();
                                                    }}
                                                    value={values.addons[i]?.addon_code}
                                                    defaultValue={values.addons[i]?.addon_code}
                                                    margin="dense"
                                                    label="Addon Code"
                                                    variant="outlined"
                                                >
                                                    {props?.subscriptionItems?.addons_code?.map((ele) => {
                                                        return (
                                                            <MenuItem key={0} value={ele}>
                                                                {ele}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Field>
                                                {props.subscriptionItems?.plan_type !== "generic" ? (
                                                    <Field
                                                        error={
                                                            (touched?.addons === undefined
                                                                ? touched?.addons
                                                                : touched?.addons[i]?.price) &&
                                                            errors?.hasOwnProperty("addons") &&
                                                            errors?.addons[i]?.price
                                                        }
                                                        name={`addons[${i}].price`}
                                                        as={TextField}
                                                        key={i}
                                                        disabled={props.subscriptionItems?.plan_type === "generic" ? true : false}
                                                        value={values.addons[i]?.price}
                                                        fullWidth
                                                        margin="dense"
                                                        label="Price"
                                                        variant="outlined"
                                                    ></Field>
                                                ) : null}
                                            </FormGroup>
                                        </Grid>
                                        <Grid container item xs={2} alignItems="center" justifyContent="center">
                                            <Grid item xs={6}>
                                                <IconButton
                                                    onClick={() => deleteAddon(setFieldValue, values, i)}
                                                    size="small"
                                                    style={{ margin: "2px" }}
                                                    disableRipple
                                                    disableFocusRipples
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                                {values.plan_name !== 'Unlimited' && values.plan_name !== 'Free Plan' && <Grid xs={12} item container justifyContent="flex-end">
                                    <Button
                                        color="primary"
                                        size="small"
                                        style={{ width: "100px", marginRight: 20 }}
                                        variant="contained"
                                        onClick={() => addMore(setFieldValue, values)}
                                    >
                                        Add
                                    </Button>
                                </Grid>}

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button type="submit" color="primary" variant="contained" disabled={false} fullWidth>
                                            {updateSubscriptionIsLoading ? (
                                                <CircularProgress
                                                    style={{
                                                        marginRight: 10,
                                                        color: "white",
                                                    }}
                                                    size={16}
                                                />
                                            ) : null}
                                            save changes
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        );
    }
    return <Grid></Grid>;
};

export default EditSubscription;
