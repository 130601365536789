import axios from "config/axios";
import reverseAxios from "config/reverseAxios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class AccountDetailsStatsEndpoint extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `admin_app`;
        this.transformer = null;
    }
    getDatasourceStats(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;
        return axios.get(`/logs${data.apiData.data}${admin_query}`);
    }
    getConfiguredReportLogs(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;
        return axios.get(`/datasources/${data.apiData.data.datasource_slug}/logs${data.apiData.data.queryString}${admin_query}`);
    }
    getOverallStats(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;
        return axios.get(`/overall_stats${data.apiData.data}${admin_query}`);
    }
    getOverAllRunStats(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;
        console.log(data);
        return axios.get(`/overall_run_stats${data.apiData.data}${admin_query}`);
    }
    getReportEditLogs(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}`;
        return axios.get(
            `/admin_app/datasources/${data.apiData.datasourceSlug}/configured_reports/${data.apiData.reportId}/changelog${admin_query}`
        );
    }
    getRowsMovedByDate(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;

        return axios.get(`/datewise-stats/rows-moved${data.apiData.data}${admin_query}`);
    }
    getDataMovedByDate(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;

        return axios.get(`/datewise-stats/data-moved${data.apiData.data}${admin_query}`);
    }
    getReverseRowsMovedByDate(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;

        return axios.get(`/datewise-stats/reverse-rows-moved${data.apiData.data}${admin_query}`);
    }
    getAllTransformations(data) {
        // let admin_query = `?account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`

        return axios.post(`${this.resourceEndpoint}/get_transformations`, {
            account_id: data.apiData.account_id,
            workspace_id: data.apiData.workspace_id,
        });
    }
    getAccount(data) {
        return axios.post(`${this.resourceEndpoint}/account_details`, { account_id: data.apiData.account_id });
    }
    getReverseConnectorDashboardData(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;
        return reverseAxios.get(`/account/reverse_connector_logs${data.apiData.data}${admin_query}`);
    }
    getReverseSyncDashboardData(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;
        return reverseAxios.get(
            `/reverse-connectors/${data.apiData.data.reverse_sync_slug}/configured-syncs/datewise${data.apiData.data.queryString}${admin_query}`
        );
    }
    getDBTTransformation(data) {
        let admin_query = `?account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;
        return axios.get(`transformations/dbt${admin_query}`);
    }
    getAccountUsers(data) {
        return axios.get(`${this.resourceEndpoint}/users/${data.apiData.account_id}`);
    }
    saveUsageUsers(data) {
        return axios.post(`${this.resourceEndpoint}/save_usage_users/${data.apiData.account_id}`, data.apiData.data);
    }
    getUsageUsers(data) {
        return axios.get(`${this.resourceEndpoint}/get_usage_users/${data.apiData.account_id}`);
    }
    editDbtProject(data) {
        let admin_query = `?account_id=${data.apiData.account_id}&is_admin=${true}`;
        return axios.patch(`transformations/dbt/${data.apiData.dbtTransformationId}${admin_query}`, data.apiData.data);
    }
    getAccountOverallStats(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}`;
        return axios.get(`/admin_app/account/overall_stats${data.apiData.data}${admin_query}`);
    }
    getAccountOverAllRunStats(data) {
        let admin_query = `&account_id=${data.apiData.account_id}&is_admin=${true}`;
        return axios.get(`/admin_app/account/overall_run_stats${data.apiData.data}${admin_query}`);
    }
    getAllOrchestration(data) {
        let admin_query = `?account_id=${data.apiData.account_id}&is_admin=${true}&workspace_id=${data.apiData.workspace_id}`;
        return axios.get(`orchestrations${admin_query}`);
    }
    OrchestrationchangeStatus(data) {
        return axios.patch(`orchestrations/${data.apiData.orchestrationId}/status`, data.apiData.data);
    }
    getAllWorkspaces(data) {
        return axios.get(`/admin_app/accounts/${data.apiData.account_id}/workspaces`);
    }
    getNeoUsers(data) {
        let admin_query = `?to_date=${data.apiData.to}&from_date=${data.apiData.from}`;
        return axios.get(`/admin_app/ask_neo/account/${data.apiData.account_id}/users${admin_query}`);
    }
}

export default AccountDetailsStatsEndpoint;
