import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import UiTable from "../../../../common/Table/UiTable";
import { Grid, makeStyles, Button } from "@material-ui/core";
import Check from "../../../../assets/images/dashboard/check.svg";
import Cross from "../../../../assets/images/dashboard/x.svg";
// import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
// import SettingsIcon from "@material-ui/icons/Settings";
// // import MenuIcon from '@material-ui/icons/Menu';
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import Image from "../../../../common/UIComponents/Image";
// import DatasourceActionButtons from "components/common/ActionButtonGroups/DatasourceActionButtons";
import Config from "constants/config";
import DataSourcesActionButtons from "common/ActionButtonGroups/DatasourcesActionButtons";
import checkForRoles from 'utils/CheckForRoles';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        transition: "initial",
        transitionDuration: "0.1s",
        marginRight: 4,
        "&:hover": {
            cursor: "pointer",
            fontSize: 22,
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
    navBtn: {
        "&:hover": {
            cursor: "pointer",
        },
    },
}));

export default function DataSourceTable({ tableNavClick, account_id }) {
    const classes = useStyles();
    const role = useSelector(state => state.user.user.roles)
    // const [formattingUnit, setFormattingUnit] = React.useState("MB")
    const changeFormattingUnit = (unit) => {
        setSkipPageReset(true);
        switch (unit) {
            case "MB":
                setData((data) =>
                    data.map((el) => ({
                        ...el,
                        data_moved_readable: (el.data_moved / 1000000).toFixed(2) + " MB",
                    }))
                );
                return;
            case "GB":
                setData((data) =>
                    data.map((el) => ({
                        ...el,
                        data_moved_readable: (el.data_moved / 1000000000).toFixed(2) + " GB",
                    }))
                );
                return;
        }
    };
    const columns = React.useMemo(
        () => [
            {
                Header: (props) => (
                    <Grid conatiner>
                        <Typography variant="subtitle1">
                            Datasource Name
                            <span style={{ fontSize: 10, marginLeft: 10 }}>
                                <img src={Check} /> - Successful runs, <img src={Cross} /> - Error runs
                            </span>
                        </Typography>
                        {/* <Typography variant="subtitle2">
							<img src={Check} /> - Success runs, <img src={Cross} /> - Error runs
						</Typography> */}
                    </Grid>
                ),
                accessor: "datasource_name",
                sortable: false,
                Cell: (props) => (
                    <Grid style={{ minWidth: 400 }} container justify="space-between" alignItems="center">
                        <Grid container xs={9} alignItems="center">
                            <Image
                                style={{ height: 20, width: 20, marginRight: 10, borderRadius: 2 }}
                                src={`${Config.imageBaseUrl}images/connectors/icons/${props.row.original.datasource_slug}.png`}
                                name={props.row.original.datasource_slug}
                                alt="icon"
                            />
                            <Typography
                                className={classes.navBtn}
                                onClick={checkForRoles(['Super-Admin', 'Account-Admin', 'Support'], role) ? () => tableNavClick(props.row.original.datasource_slug) : () => (null)}
                                variant="subtitle1"
                            >
                                {props.row.original.datasource_name}
                            </Typography>
                        </Grid>
                        <Grid container xs={3} justify="flex-end">
                            <Typography style={{ color: "#40C77E", marginRight: 15 }} variant="subtitle1">
                                <img src={Check} />
                                {props.row.original.success}{" "}
                            </Typography>
                            <Typography style={{ color: "#F77070" }} variant="subtitle1">
                                <img src={Cross} />
                                {props.row.original.error}{" "}
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
            {
                Header: "Reports Configured",
                accessor: "num_reports",
                Filter: false,
                sortable: true,
            },
            {
                Header: "Number of Runs",
                accessor: "success",
                Filter: false,
                sortable: false,
                Cell: (props) => {
                    return (
                        <>
                            <Typography style={{ fontSize: 12 }} variant="subtitle1">
                                {props.row.original.no_of_runs}
                            </Typography>
                        </>
                    );
                },
            },
            {
                Header: "Rows Moved",
                accessor: "rows_moved",
                Filter: false,
                sortable: true,
                Cell: (props) => (
                    <>
                        <Typography style={{ fontSize: 12 }} variant="subtitle1">
                            {props.row.original.rows_moved.toLocaleString()}
                        </Typography>
                    </>
                ),
            },
            {
                Header: (props) => (
                    <>
                        <Typography style={{ fontSize: 12 }} variant="subtitle1">
                            Data Moved
                        </Typography>
                        <Grid container>
                            <Button
                                variant="contained"
                                onClick={() => changeFormattingUnit("GB")}
                                className={classes.headerButton}
                            >
                                GB
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => changeFormattingUnit("MB")}
                                className={classes.headerButton}
                            >
                                MB
                            </Button>
                        </Grid>
                    </>
                ),
                accessor: "data_moved",
                Filter: false,
                sortable: false,
                Cell: (props) => (
                    <>
                        <Typography style={{ fontSize: 12 }} variant="subtitle1">
                            {props.row.original.data_moved_readable}
                        </Typography>
                    </>
                ),
            },
            // {
            //     Header: "Actions",
            //     Filter: false,
            //     Cell: (props) => (<Grid container style={{ minWidth: 75 }} alignItems="center"><DataSourcesActionButtons datasourceSlug={props.row.original.datasource_slug} account_id={account_id} /></Grid>),
            //     sortable: false,
            // },
        ],
        []
    );
    const formatData = (data) =>
        data.map((el) => ({
            ...el,
            data_moved_readable: (el.data_moved / 1000000).toFixed(2) + " MB",
        }));
    const dashboard = useSelector((state) => state.dashboard);
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        setData(formatData(dashboard.dataSources));
    }, [dashboard.dataSources]);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <motion.div
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: "-40vh" }}
            // exit={{ opacity: 0, x: "-80vw", transition: {duration: 0.2} }}
            key="expanded"
        >
            <UiTable columns={columns} data={data} setData={setData} updateMyData={updateMyData} skipPageReset={skipPageReset} />
        </motion.div>
    );
}
