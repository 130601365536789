import React from "react";
import Table from "common/Table/ServerSideTable";
import { Grid, Button, Switch, Typography, Box, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import useTableApi from "hooks/useTableApi";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import mainMoment from "moment";
import { makeStyles } from "@material-ui/core";
import { LOGS_DATA_LIST, LOGS_DATA_IS_LOADING } from "redux/actionTypes/actionTypes";
const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        transition: "initial",
        transitionDuration: "0.2",
        marginRight: 4,
        fontSize: 17,
        "&:hover": {
            cursor: "pointer",
            fontSize: 20,
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
    boxSuccess: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#23BE82",
        marginRight: 10,
    },
    boxFailure: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#F77070",
        marginRight: 10,
    },
    partialSuccess: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#548EEA",
        marginRight: 10,
    },
    boxRunning: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#FD9E74",
        marginRight: 10,
    },
}));

/**
 * Component to render runs table
 * @component
 * @param {object} param0
 */
export default function LogsDataTable({ tableNavClick, refresh, to, from }) {
    const classes = useStyles();
    // const [formattingUnit, setFormattingUnit] = React.useState("MB")
    const [skipPageReset, setSkipPageReset] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [queryString, setQuertString] = React.useState("");
    const [sortBy, setSortBy] = React.useState([]);
    const dispatch = useDispatch();
    const dcLogs = useSelector((state) => state.logsData);

    const [tableData, count, page, pageSize, tableLoading, onPageChange, onFilterChange, onPageSizeChange] = useTableApi(
        `/admin_app/accounts/logs_data`,
        refresh,
        false,
        queryString
    );
    React.useEffect(() => {
        const toString = moment(from).format("YYYY-MM-DD") + " 23:59:59";
        const fromString = moment(to).format("YYYY-MM-DD") + " 00:00:00";
        const sortColumn = sortBy[0]?.id;
        const sortDirection = sortBy[0]?.desc ? "desc" : "asc";
        console.log(sortColumn);
        console.log(sortDirection);
        let qS = `&from_date=${fromString}&to_date=${toString}&sort_column=${sortColumn}&sort_direction=${sortDirection}`;

        if (sortColumn === undefined) {
            qS = `&from_date=${fromString}&to_date=${toString}&sort_direction=${sortDirection}`;
        }
        console.log(qS);
        setQuertString(qS);
    }, [from, to, sortBy]);
    const formatData = (data) =>
        data.map((el) => ({
            ...el,
            data_moved_readable: (el.data_moved / 1000000).toFixed(2) + " MB",
        }));
    React.useEffect(() => {
        setData(formatData(tableData));
        if (tableData?.length > 0) {
            dispatch({ type: LOGS_DATA_LIST, payload: tableData });
        }
    }, [tableData]);

    React.useEffect(() => {
        dispatch({ type: LOGS_DATA_IS_LOADING, payload: tableLoading });
    }, [tableLoading]);

    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };
    const changeFormattingUnit = (unit) => {
        setSkipPageReset(true);
        switch (unit) {
            case "MB":
                setData((data) =>
                    data.map((el) => ({
                        ...el,
                        data_moved_readable: (el.data_moved / 1000000).toFixed(2) + " MB",
                    }))
                );
                return;
            case "GB":
                setData((data) =>
                    data.map((el) => ({
                        ...el,
                        data_moved_readable: (el.data_moved / 1000000000).toFixed(2) + " GB",
                    }))
                );
                return;
        }
    };
    const columns = React.useMemo(
        () => [
            {
                Header: "Account id",
                Filter: false,
                sortable: true,
                accessor: "account_id",
                Cell: (props) => (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.account_id}</Typography>
                    </Grid>
                ),
            },

            {
                Header: "Name",
                Filter: false,
                accessor: "account_name",
                sortable: true,
                Cell: (props) => (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.account_name}</Typography>
                    </Grid>
                ),
            },
            {
                Header: "Rows Moved",
                accessor: "rows_moved",
                Filter: false,
                sortable: true,
                Cell: (props) => (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.rows_moved.toLocaleString()} </Typography>
                    </Grid>
                ),
            },
            {
                Header: "Reverse Rows Moved",
                accessor: "reverse_rows_moved",
                Filter: false,
                sortable: true,
                Cell: (props) => (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid container xs={9}>
                            <Typography variant="subtitle1">{props.row.original.reverse_rows_moved.toLocaleString()}</Typography>
                        </Grid>
                    </Grid>
                ),
            },
            {
                Header: (props) => (
                    <>
                        <Typography style={{ fontSize: 12 }} variant="subtitle1">
                            Data Moved
                        </Typography>
                        <Grid container>
                            <Button
                                variant="contained"
                                onClick={() => changeFormattingUnit("GB")}
                                className={classes.headerButton}
                            >
                                GB
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => changeFormattingUnit("MB")}
                                className={classes.headerButton}
                            >
                                MB
                            </Button>
                        </Grid>
                    </>
                ),
                accessor: "data_moved",
                Filter: false,
                sortable: true,
                Cell: (props) => (
                    <>
                        <Typography variant="subtitle1" style={{ textAlign: "left" }}>
                            {props.row.original.data_moved_readable}
                        </Typography>
                    </>
                ),
            },
            {
                Header: "Total Rows Moved",
                Filter: false,
                sortable: true,
                accessor: "total_rows_moved",
                Cell: (props) => (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid container xs={4}>
                            <Typography variant="subtitle1">{props.row.original.total_rows_moved.toLocaleString()}</Typography>
                        </Grid>
                    </Grid>
                ),
            },
        ],
        []
    );
    if (dcLogs.logsDataIsLoading && dcLogs.logsData?.length === 0) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Box>
            <Table
                columns={columns}
                data={data}
                count={count}
                tableLoading={tableLoading}
                onPageChange={onPageChange}
                currentPage={page}
                onFilterChange={onFilterChange}
                perPage={pageSize}
                onPageSizeChange={onPageSizeChange}
                updateMyData={updateMyData}
                sortBy={sortBy}
                setSortBy={setSortBy}
                customLoading={true}
            />
        </Box>
    );
}
