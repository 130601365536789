import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Brush } from "recharts";

/**
 *
 * @prop - data, containerHeight, containerStyle, xLabelValue, xLabelOffset, yLabelValue, yLabelOffset, dataKey, isByDate
 * @component {This component receives various props and uses them to create and render BAR CHARTS}
 */

const ChartComponent = ({
    data,
    containerHeight,
    containerStyle,
    xLabelValue,
    xLabelOffset,
    yLabelValue,
    yLabelOffset,
    dataKey,
    isByDate,
}) => {
    return (
        <ResponsiveContainer width={700} height={containerHeight} style={containerStyle}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 55,
                }}
            >
                <CartesianGrid vertical={false} />
                <XAxis
                    label={{
                        value: xLabelValue,
                        position: "insideBottom",
                        offset: xLabelOffset,
                    }}
                    tick={{
                        fontFamily: "Nunito",
                        fontSize: "10px",
                        fill: "#2C2C2C",
                    }}
                    stroke="#EDEDED"
                    tickLine={false}
                    dataKey={isByDate ? "date" : "name"}
                    interval={0}
                    angle={-40}
                    textAnchor="end"
                    height={isByDate ? 90 : 30}
                />
                <YAxis
                    tickCount={5}
                    tick={{
                        fontFamily: "Nunito",
                        fontSize: "9px",
                        fill: "#2C2C2C",
                    }}
                    stroke="#EDEDED"
                    label={{
                        value: yLabelValue,
                        angle: -90,
                        position: "insideLeft",
                        offset: yLabelOffset,
                    }}
                    tickLine={false}
                />
                <Tooltip
                    cursor={false}
                    formatter={(value, name, props) =>
                        name === "rowsMoved" ? [value, "Rows Moved", props] : [value, name, props]
                    }
                />
                {/* <Legend /> */}
                {isByDate && <Brush data={data} dataKey={dataKey} height={20} stroke="#00B5CC" />}
                <Bar dataKey={dataKey} fill="#00B5CC" barSize={23} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default ChartComponent;
