import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import CreatePlan from "./CreatePlan";
import SelectPlan from "./SelectPlan";

const Plan = (props) => {
    const [isCreatePlan, setIsCreatePlan] = useState(props.userData.isSelect);
    // const [planItemPrice, planItemPriceIsLoading, getPlanItemPrice] = useApi(
    //     React.useMemo(
    //         () => ({
    //             module: "subscription",
    //             method: "getAllPlansItemPrice",
    //         }),
    //         []
    //     )
    // );

    const handleRadioChange = (e) => {
        console.log(e.target.value);
        if (e.target.value === "true") {
            setIsCreatePlan(true);
        } else {
            setIsCreatePlan(false);
        }
    };
    // if (planItemPriceIsLoading) {
    //     return (
    //         <Grid container item xs={12} justifyContent="center">
    //             <CircularProgress />
    //         </Grid>
    //     );
    // }
    // console.log(planItemPrice);
    return (
        <Grid container justify="center">
            <Grid container item direction="column" sm={6} xs={10} alignItems="center" justifyContent="center" spacing={6}>
                {/* <Grid item>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="position" value={isCreatePlan} onChange={handleRadioChange}>
                            <FormControlLabel
                                value={false}
                                control={<Radio color="primary" />}
                                label="Create Plan"
                                labelPlacement="bottom"
                            />
                            <FormControlLabel
                                value={true}
                                control={<Radio color="primary" />}
                                label="Select Plan"
                                labelPlacement="bottom"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid> */}
                <Grid>{!isCreatePlan ? <CreatePlan {...props} /> : <SelectPlan planItemPrice={{}} {...props} />}</Grid>
            </Grid>
        </Grid>
    );
};

export default Plan;
