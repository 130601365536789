import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, LinearProgress } from "@material-ui/core";
import NeoUserTable from "./NeoUserTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchNeoUser } from "redux/actions/dashboardActions";
import CheckForPermission from "utils/CheckForPermission";
import checkForRoles from "utils/CheckForRoles";
import DateRangePicker from "../../../../common/DateRangePicker/index";
import moment from "moment";
const UseStyle = makeStyles((theme) => ({
    root: {},
}));

const NeoUsers = ({ account_id }) => {
    const classes = UseStyle();
    const dashboard = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const permissions = user.permissions;
    const role = useSelector((state) => state.user.user.roles);
    const [from, setFrom] = React.useState(new Date());
    const [to, setTo] = React.useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));

    React.useEffect(() => {
        if (from && to) {
            const toString = moment(to).format("YYYY-MM-DD") + " 23:59:59";
            const fromString = moment(from).format("YYYY-MM-DD") + " 00:00:00";
            dispatch(fetchNeoUser(account_id, fromString, toString));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to]);
    if (dashboard.neoUserIsLoading && dashboard.neoUsers.length === 0) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container className={classes.root} direction="row" alignItems="flex-start" justifyContent="center">
            <Grid container xs={12} style={{ paddingLeft: 40, paddingRight: 40 }} justifyContent="center">
                <Grid container xs={12} justifyContent="flex-end">
                    <Grid item>
                        <DateRangePicker from={from} to={to} setFrom={setFrom} setTo={setTo} />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    {dashboard.neoUserIsLoading ? <LinearProgress /> : null}
                </Grid>
                <Grid item xs={12}>
                    <NeoUserTable tableData={dashboard.neoUsers} to={to} from={from} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NeoUsers;
