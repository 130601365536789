import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, LinearProgress } from "@material-ui/core";
import InvoicesTable from "./InvoicesTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices } from "redux/actions/invoiceActions";
import CheckForPermission from "utils/CheckForPermission";
import checkForRoles from "utils/CheckForRoles";

const UseStyle = makeStyles((theme) => ({
    root: {},
}));

const Invoicess = () => {
    const classes = UseStyle();
    const invoices = useSelector((state) => state.invoice);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const permissions = user.permissions;
    const role = useSelector((state) => state.user.user.roles);

    React.useEffect(() => {
        dispatch(fetchInvoices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (invoices.invoicesIsLoading && invoices.invoices.length === 0) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container className={classes.root} direction="row" alignItems="flex-start" justifyContent="center">
            {checkForRoles(["Super-Admin", "Finance"], role) && (
                <Grid container xs={12} style={{ paddingLeft: 40, paddingRight: 40 }} justifyContent="center">
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                        {invoices.invoicesIsLoading ? <LinearProgress /> : null}
                    </Grid>
                    <Grid item xs={12}>
                        <InvoicesTable tableData={invoices.invoices} />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default Invoicess;
