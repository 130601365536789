import React, { useEffect, UseState } from "react";
import { Grid, makeStyles, CircularProgress, Tab, Box, Tabs, useTheme } from "@material-ui/core";
import EditSubscription from "./EditSubscription/Index";
import { useParams } from "react-router";
import Overview from "./Overview/Overview";
import useApi from "hooks/useApi";
import CustomerInvoices from "../../Invoices/CustomerInvoice/index";
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";

const useStyles = makeStyles(() => ({
    tabs: {
        marginBottom: 21,
        marginTop: 5,
    },
    tab: {
        marginBottom: 40,
    },
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 20,
        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    reportName: {
        marginTop: 10,
        position: "absolute",
        right: 0,
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}
const SubscriptionDetail = () => {
    const [value, setValue] = React.useState(0);
    const { subscription_id,account_id } = useParams();
    const [subscriptionItems, subscriptionItemsIsLoading, fetchSubscriptionItems] = useApi(
        React.useMemo(
            () => ({
                initialValues: [],
                module: "subscription",
                method: "getAllSubscriptionsItems",
                apiData: {
                    subscription_id: subscription_id,
                    account_id:account_id
                },
            }),
            []
        )
    );
    const classes = useStyles();
    const theme = useTheme();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // useEffect(() => {
    //     if (subscription_id!=='unlimited')
    //     fetchSubscriptionItems({
    //         initialValues: [],
    //         module: "subscription",
    //         method: "getAllSubscriptionsItems",
    //         apiData: {
    //             subscription_id: subscription_id,
    //         },
    //     })

    // }, [])
    

    if (subscriptionItemsIsLoading) {
        return (
            <Grid container item xs={12} justifyContent="center">
                <CircularProgress />
            </Grid>
        );
    }
    if (subscription_id==='unlimited'){
        return (
            <Grid justify="center" container style={{ padding: 30 }}>
                <Grid item className="pageContainer" xs={12}>
                    <Box className="tabs">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            centered={false}
                            className={classes.tabs}
                        >
                            <CheckForPermissionHOC requiredPermissions={["subscription.edit"]}>
                                <Tab label="Edit" onClick={() => setValue(1)} {...a11yProps(1)} />
                            </CheckForPermissionHOC>
        
                        </Tabs>
                    </Box>

                    
                    {/* <CheckForPermissionHOC requiredPermissions={["subscription.edit"]}> */}
                    <TabPanel className={classes.tab} value={value} index={0} dir={theme.direction}>
                        <EditSubscription subscriptionItems={subscriptionItems} subscription_id={subscription_id} account_id={account_id}/>
                    </TabPanel>
                    {/* </CheckForPermissionHOC> */}

                </Grid>
            </Grid>
        );
    }

    if (subscriptionItems) {
        return (
            <Grid justify="center" container style={{ padding: 30 }}>
                <Grid item className="pageContainer" xs={12}>
                    <Box className="tabs">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            centered={false}
                            className={classes.tabs}
                        >
                            <Tab label="Overview" {...a11yProps(0)} />
                            <CheckForPermissionHOC requiredPermissions={["subscription.edit"]}>
                                <Tab label="Edit" onClick={() => setValue(1)} {...a11yProps(1)} />
                            </CheckForPermissionHOC>
                            <CheckForPermissionHOC requiredPermissions={["invoice.view"]}>
                                <Tab label="Invoices" onClick={() => setValue(2)} {...a11yProps(2)} />
                            </CheckForPermissionHOC>
                        </Tabs>
                    </Box>

                    <TabPanel className={classes.tab} value={value} index={0} dir={theme.direction}>
                        <Overview subscription={subscriptionItems} />
                    </TabPanel>
                    {/* <CheckForPermissionHOC requiredPermissions={["subscription.edit"]}> */}
                    <TabPanel className={classes.tab} value={value} index={1} dir={theme.direction}>
                        <EditSubscription subscriptionItems={subscriptionItems} subscription_id={subscription_id} account_id={account_id}/>
                    </TabPanel>
                    {/* </CheckForPermissionHOC> */}
                    {/* <CheckForPermissionHOC requiredPermissions={["invoice.view"]}> */}
                    <TabPanel className={classes.tab} value={value} index={2} dir={theme.direction}>
                        <CustomerInvoices
                            customerId={subscriptionItems?.subscription_data?.subscription?.customer.customer_id}
                            tab={true}
                        />
                    </TabPanel>
                    {/* </CheckForPermissionHOC> */}

                </Grid>
            </Grid>
        );
    } else {
        return <Grid></Grid>;
    }
};

export default SubscriptionDetail;
