import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const NoData = ({ showButton, link, height, componentType, routeData }) => {
	const navigate = useNavigate();
	return (
		<Grid container justify="center" alignItems="center" style={{ height: height, width: "100%" }}>
			<Grid item>
				<Typography variant="subtitle1">
					No {componentType} available
				</Typography>
				{/* {showButton ? (
					<Grid container justify="center">
						<Button
							size="small"
							color="primary"
							variant="contained"
							onClick={() => navigate(link, routeData)}
						>
							Add {componentType}
						</Button>
					</Grid>
				) : null} */}
			</Grid>
		</Grid>
	);
};

export default NoData;
