import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Field, Form, Formik } from "formik";
import { CircularProgress, FormGroup, MenuItem, Grid, Typography } from "@material-ui/core";
import { object, string, email, array } from "yup";
import { useDispatch } from "react-redux";
import { getRoles } from "services/Api/api";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import useApi from "hooks/useApi";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AddAdminUserForm = (props) => {
    const dispatch = useDispatch();
    const [roles, setRoles] = React.useState([]);
    // const [rolesIsLoading, setRolesIsLoading] = React.useState(false);
    const [, addUserIsLoading, addUserHook] = useApi();
    const [, editUserIsLoading, editUserHook] = useApi();
    const [, AdminUserRolesIsLoading, getAdminUserRoles] = useApi();
    const [users, getUserIsLoading, getUser] = useApi()
    const [accountAdminRoleId, setAccountAdminRoleId] = useState(null)
    const [userExist, setUserExist] = useState(false)
    const [username, setUsername] = useState(null)


    React.useEffect(async () => {
        const roles = await getAdminUserRoles({
            module: "adminUser",
            method: "getAdminRoles",
            returnResult: true,
            throwError: true,
        });
        setRoles(roles)
        roles.forEach(async (r) => {
            console.log(r.name)

            if (r.name === 'Account-Admin') {
                console.log(r)
                setAccountAdminRoleId(r.id)
                await getUser({
                    initialValues: [],
                    module: 'adminUser',
                    method: 'getAllAccounts',
                })
            }
        })
    }, []);

    const [initialValues, setInitialValues] = React.useState({
        name: "",
        role: [],
        email: "",
        password: "",
        selectedUsers: []
    });
    const handleSubmit = async (values, helpers) => {
        if (props.edit !== null && props.edit === true ? true : false) {
            try {
                if (values.password === 'default') {
                    values.password = null
                }
                await editUserHook({
                    module: "adminUser",
                    method: "editAdminUser",
                    apiData: {
                        data: {
                            role: values.role,
                            user_id: props.user.user_id,
                            selectedAccounts: values.selectedUsers,
                            name: values.name,
                            password: values.password
                        },
                    },
                    successToast: "User Updated",
                    returnResult: true,
                    throwError: true,
                });
                props.setEdit(false)
                props.setUser(null)
                props.setAddUserModal(false)

            } catch (e) {
                console.log(e);
                HttpErrorHandler(dispatch, e.response?.data?.message);
            }

        } else {
            try {
                await addUserHook({
                    module: "adminUser",
                    method: "addAdminUser",
                    successToast: "User Added",
                    apiData: {
                        data: {
                            name: values.name,
                            role: values.role,
                            email: values.email,
                            password: values.password,
                            selectedUsers: values.selectedUsers
                        }
                    },
                    returnResult: true,
                    throwError: true,

                });
                props.setAddUserModal(false)

            } catch (e) {
                console.log(e);
                if (e.response.status === 403) {
                    setUserExist(true)
                    console.log(e.response?.data?.data)
                    setUsername(e.response?.data?.data)
                }
                HttpErrorHandler(dispatch, e.response?.data?.message);
            }
        }
    };

    const handleUserExistRouteSubmit = async (values) => {
        try {
            await addUserHook({
                module: "adminUser",
                method: "addAdminUserExisting",
                successToast: "User Added",
                apiData: {
                    data: {
                        name: values.name,
                        role: values.role,
                        email: values.email,
                        password: values.password,
                        selectedUsers: values.selectedUsers
                    }
                },
                returnResult: true,
                throwError: true,

            });
            setUserExist(false)
            props.setAddUserModal(false)

        } catch (e) {
            console.log(e);
            HttpErrorHandler(dispatch, e.response?.data?.message);
        }
    }
    let validationSchema = {};
    validationSchema = object({
        email: string().required().email(),
        password: string().required().min(5),
        name: string().required(),
        role: array()
    });
    if (props.edit !== null && props.edit === true && initialValues.name === "" && props.user) {
        setInitialValues({
            name: props.user.name,
            role: props.user.role,
            email: props.user.email,
            password: 'default',
            selectedUsers: props.user?.selectedUsers
        });
    }
    if (!props.user && props.edit) {
        return <div></div>
    }
    const handleChangeRole = (roles) => {
        let role_ids = roles?.map((e) => e.id)
        console.log(role_ids)
        console.log(accountAdminRoleId)
        console.log(role_ids.includes(accountAdminRoleId))

        if (role_ids.includes(accountAdminRoleId)) {
            getUser({
                initialValues: [],
                module: 'adminUser',
                method: 'getAllAccounts',
            })
        }


    }
    console.log(props.user?.role_id)
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            enableReinitialize={true}
        >
            {({ values, errors, isSubmitting, isValidating, touched, handleChange, setFieldValue }) => (
                <Grid container item xs={12}>
                    {!userExist ? <Form style={{ width: '100%' }}>

                        <FormGroup style={{ width: '100%' }}>
                            <Field
                                error={touched.name && errors.hasOwnProperty("name")}
                                name="name"
                                as={TextField}
                                label="Name"
                                variant="outlined"
                                fullWidth
                                //  disabled={props.edit !== null && props.edit === true ? true : false}
                                margin="dense"
                                helperText={touched.name && errors?.name}
                            />
                        </FormGroup>
                        {console.log(values)
                        }

                        {AdminUserRolesIsLoading ? (
                            <CircularProgress size={30} />
                        ) : (
                            < Autocomplete
                                options={roles}
                                name='Roles'
                                disableCloseOnSelect
                                disableClearable
                                limitTags={3}
                                openOnFocus={true}
                                getOptionLabel={(option) =>
                                    `${option?.name}`
                                }
                                getOptionSelected={(option, value) => {
                                    return value?.id === option?.id;
                                }}
                                value={values.role}
                                fullWidth
                                onChange={(e, newValue, reason, detail) => {
                                    console.log("fdsk;fsdl")
                                    handleChange(e)
                                    handleChangeRole(newValue)
                                    setFieldValue('role', newValue)

                                }}
                                size="small"
                                multiple={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        label={'Roles'}
                                        variant="outlined"
                                    />
                                )} />
                        )}
                        {values.role?.map((e) => e.id).includes(accountAdminRoleId) && (
                            getUserIsLoading ? (
                                <CircularProgress size={30} />
                            ) : (
                                users && < Autocomplete

                                    options={users}
                                    name='selectedUsers'
                                    disableCloseOnSelect
                                    disableClearable
                                    limitTags={3}
                                    openOnFocus={true}
                                    getOptionLabel={(option) =>
                                        `${option?.name}`
                                    }
                                    getOptionSelected={(option, value) => {
                                        return value?.id === option?.id;
                                    }}
                                    value={values.selectedUsers}
                                    fullWidth
                                    onChange={(e, newValue, reason, detail) => {
                                        handleChange(e)
                                        console.log(newValue)
                                        console.log(e)
                                        setFieldValue('selectedUsers', newValue)

                                    }}
                                    size="small"
                                    multiple={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label={'Selected Users'}
                                            variant="outlined"
                                        />
                                    )}
                                // disabled={
                                //     props.levelData.data.is_editable === 0 ? true : !props.isEditing && props.mode === "EDIT"
                                // }
                                />)
                        )}
                        {console.log(values)}

                        <FormGroup>
                            <Field
                                error={touched.email && errors.hasOwnProperty("email")}
                                name="email"
                                as={TextField}
                                label="Email Id"
                                variant="outlined"
                                margin="dense"
                                disabled={props.edit !== null && props.edit === true ? true : false}
                                helperText={touched.email && errors?.email}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Field
                                error={touched.password && errors.hasOwnProperty("password")}
                                name="password"
                                as={TextField}
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                fullWidth
                                type="password"
                                margin="dense"
                                // disabled={props.edit !== null && props.edit === true ? true : false}
                                helperText={touched.password && errors?.password}
                            />
                        </FormGroup>

                        <Button
                            type="submit"
                            disabled={isSubmitting || isValidating || addUserIsLoading || editUserIsLoading}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            {props.edit ? "Update User" : "Add User"}
                        </Button>
                        {props.click && (
                            <Button
                                disabled={isSubmitting || isValidating || addUserIsLoading || editUserIsLoading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={props.click}
                            >
                                Cancel
                            </Button>
                        )}
                    </Form> : <Grid xs={12} container >
                        <Grid xs={12} >
                            <Typography variant="body1" style={{ fontSize: 16, wordBreak: 'break-word' }}>A user with email <span style={{ fontWeight: 500 }}>{values.email}</span> and name <span style={{ fontWeight: 500 }}>{username}</span> already exist </Typography>
                            <Typography variant="body1" style={{ fontSize: 14, fontWeight: 500, marginTop: 10 }}>Do you wish to continue with above name and email?</Typography>

                        </Grid>
                        <Grid container justifyContent="space-between" xs={12} style={{ marginTop: 50 }}>
                            <Grid item xs={5}>
                                <Button
                                    onClick={() => { handleUserExistRouteSubmit(values) }}
                                    disabled={isSubmitting || isValidating || addUserIsLoading || editUserIsLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    Add User
                                </Button>
                            </Grid>
                            <Grid item xs={5}>

                                <Button
                                    disabled={isSubmitting || isValidating || addUserIsLoading || editUserIsLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => setUserExist(false)}
                                >
                                    Go Back
                                </Button>

                            </Grid>
                        </Grid>

                    </Grid>}
                </Grid>
            )
            }
        </Formik >
    );
};

export default AddAdminUserForm;
