import { useState, useEffect } from "react";

const useFilterAndSort = (array, filterKey, sortKey) => {
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState(null);
    const [order, setOrder] = useState(null);
    const [resultArray, setResultArray] = useState(list);
    function compareAtoZ(a, b) {
        if (a[sortKey] < b[sortKey]) {
            return -1;
        }
        if (a[sortKey] > b[sortKey]) {
            return 1;
        }
        return 0;
    }
    function compareZtoA(a, b) {
        if (a[sortKey] > b[sortKey]) {
            return -1;
        }
        if (a[sortKey] < b[sortKey]) {
            return 1;
        }
        return 0;
    }
    useEffect(() => {
        setList(array);
    }, [array]);
    useEffect(() => {
        if (filter !== null) {
            if (filter === "") {
                setResultArray(list);
            } else {
                const newArray = list.filter((el) => el[filterKey].toLowerCase().includes(filter.toLowerCase()));
                setResultArray(newArray);
            }
        } else {
            setResultArray(list);
        }
    }, [filter, list]);
    useEffect(() => {
        if (order !== null) {
            const copyArray = [...resultArray];
            let newArray = [];
            if (order === "ASC") {
                newArray = copyArray.sort(compareAtoZ);
            } else if (order === "DESC") {
                newArray = copyArray.sort(compareZtoA);
            }
            setResultArray(newArray);
        } else {
            const newArray =
                filter !== null ? list.filter((el) => el[filterKey].toLowerCase().includes(filter.toLowerCase())) : list;
            setResultArray(newArray);
        }
    }, [order, list]);
    const onFilterChange = (value) => setFilter(value.trim());
    const onChangeSort = (value) => setOrder(value);

    return [resultArray, onFilterChange, onChangeSort, setList, setResultArray];
};

export default useFilterAndSort;
