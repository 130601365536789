import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";
import { INVOICE_LIST_LOADED, INVOICE_LIST_LOADING, LOGS_DATA_IS_LOADING, LOGS_DATA_LIST } from "redux/actionTypes/actionTypes";
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";

const fetchInvoices = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        INVOICE_LIST_LOADING,
        INVOICE_LIST_LOADED,
        {
            module: "subscription",
            method: "getAllInvoices",
        },
        HttpErrorHandler
    );
};

const fetchLogsData = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        LOGS_DATA_IS_LOADING,
        LOGS_DATA_LIST,
        {
            module: "logsData",
            method: "fetchLogsData",
        },
        HttpErrorHandler
    );
};

export { fetchInvoices, fetchLogsData };
