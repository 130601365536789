import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, LinearProgress } from "@material-ui/core";
import SubscriptionTable from "./SubscriptionTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptions } from "redux/actions/subscriptionActions";

const UseStyle = makeStyles((theme) => ({
    root: {},
}));

const Subscriptions = () => {
    const classes = UseStyle();
    const dispatch = useDispatch();
    const subscriptions = useSelector((state) => state.subscription);

    React.useEffect(() => {
        dispatch(fetchSubscriptions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    console.log(subscriptions.subscriptions);
    if (subscriptions.subscriptionsIsLoading && subscriptions.subscriptions.length === 0) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container className={classes.root} direction="row" alignItems="flex-start" justifyContent="flex-start">
            <Grid container xs={12} style={{ paddingLeft: 40, paddingRight: 40 }} justifyContent="center">
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    {subscriptions.subscriptionsIsLoading ? <LinearProgress /> : null}
                </Grid>
                <Grid item xs={12}>
                    <SubscriptionTable tableData={subscriptions.subscriptions} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Subscriptions;
