import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "common/ServerSideTable";
import useTableApi from "hooks/useTableApi";
import { Grid, makeStyles } from "@material-ui/core";
import AdminUserActions from "common/ActionButtonGroups/AdminUserActions";
/**
 * Component to render runs table
 * @component
 * @param {object} param0
 */
export default function AdminUsersTable({ tableNavClick, refresh, setEdit, setUser, setAddUserModal }) {
    const columns = React.useMemo(
        () => [
            {
                Header: "Id",
                Filter: false,
                sortable: false,
                Cell: (props) => (
                    <Grid container style={{ minWidth: 125 }} justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original?.user_id}</Typography>
                    </Grid>
                ),
            },
            {
                Header: "Name",
                Filter: false,
                sortable: false,
                Cell: (props) => (
                    <Grid container style={{ minWidth: 175 }} justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original?.name?.split(",")[0]}</Typography>
                    </Grid>
                ),
            },
            {
                Header: "Role",
                Filter: false,
                sortable: false,
                Cell: (props) => (
                    <Grid container style={{ minWidth: 175 }} justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original?.role_name.split(",").join(", ")}</Typography>
                    </Grid>
                ),
            },

            {
                Header: "Actions",
                Filter: false,
                Cell: (props) => (
                    <Grid container style={{ minWidth: 75 }} alignItems="center">
                        <AdminUserActions
                            userId={props.row.original.user_id}
                            setEdit={setEdit}
                            setUser={setUser}
                            setAddUserModal={setAddUserModal}
                        />
                        {/* <MenuIcon className={classes.icon} fontSize="small" />
                            <ScheduleOutlinedIcon className={classes.icon} fontSize="small" /> */}
                    </Grid>
                ),
                sortable: false,
            },
        ],
        []
    );

    const [tableData, count, page, pageSize, tableLoading, onPageChange, onFilterChange, onPageSizeChange] = useTableApi(
        `/admin_app/get_admin_users`,
        refresh
    );

    return (
        <Box>
            <Table
                columns={columns}
                data={tableData}
                count={count}
                tableLoading={tableLoading}
                onPageChange={onPageChange}
                currentPage={page}
                onFilterChange={onFilterChange}
                perPage={pageSize}
                onPageSizeChange={onPageSizeChange}
            />
        </Box>
    );
}
