import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import clsx from "clsx";
import CallMadeIcon from "@material-ui/icons/CallMade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import DataMoved from "./Modals/DataMoved";
import RowsMoved from "./Modals/RowsMoved";
import ErrorModal from "./Modals/ErrorModal";
import ReportsConfigured from "./Modals/ReportsConfigured";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 90,
        maxHeight: 80,
    },
    container: {
        padding: "13px",
        color: "#23BE82",
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        marginBottom: 7,
        height: 30,
        width: 30,
        background: "#EAFFEE 0% 0% no-repeat padding-box",
        borderRadius: "50%",
    },
    crossIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        marginBottom: 7,
        height: 30,
        width: 30,
        background: "#FFE5E5 0% 0% no-repeat padding-box",
        borderRadius: "50%",
    },
    barIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        marginBottom: 7,
        height: 30,
        width: 30,
        background: "#FFF3ED 0% 0% no-repeat padding-box",
        borderRadius: "50%",
    },
    subtitle: {
        marginTop: -7,
    },
    crossicon: {
        color: "#E50000",
    },
    baricon: {
        color: "#ff9966",
    },
    openModal: {
        position: "relative",
        bottom: 16,
        left: 13,
        color: "#626262",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const Card = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [showArrow, setShowArrow] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Paper
                onMouseEnter={() => setShowArrow(true)}
                onMouseLeave={() => setShowArrow(false)}
                id="cardContainer"
                className={classes.root}
            >
                <Box className={classes.container}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center">
                                <Box
                                    className={clsx({
                                        [classes.iconContainer]: props.check === "success",
                                        [classes.crossIconContainer]: props.check === "errors",
                                        [classes.barIconContainer]: props.check === "configured",
                                    })}
                                >
                                    {props.check === "success" ? <CheckCircleOutlineIcon className={classes.checkicon} /> : null}
                                    {props.check === "errors" ? <CancelOutlinedIcon className={classes.crossicon} /> : null}
                                    {props.check === "configured" ? <AssessmentOutlinedIcon className={classes.baricon} /> : null}
                                </Box>
                                <Typography variant="h3" className={classes.subtitle}>
                                    {props.num}
                                </Typography>
                            </Grid>
                        </Grid>
                        {props.hasCard && props.workspace_id ? (
                            <Grid item>
                                {showArrow ? (
                                    <Fade in={showArrow} timeout={400}>
                                        <CallMadeIcon
                                            fontSize="small"
                                            onClick={handleOpen}
                                            className={clsx(classes.openModal, "hoverable")}
                                        />
                                    </Fade>
                                ) : null}
                            </Grid>
                        ) : null}
                    </Grid>
                    <Typography variant="h6">{props.msg}</Typography>
                </Box>
            </Paper>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Paper elevation={0} style={{ width: props.check !== "errors" ? "65vw" : "81vw" }}>
                        {props.msg === "Runs with errors" ? (
                            <ErrorModal from={props.from} to={props.to} close={handleClose} workspace_id={props.workspace_id}/>
                        ) : null}
                        {props.msg === "Data Moved" ? <DataMoved from={props.from} to={props.to} close={handleClose} account_id={props.account_id}  workspace_id={props.workspace_id}/> : null}
                        {props.msg === "Rows Moved" ? <RowsMoved from={props.from} to={props.to} close={handleClose} account_id={props.account_id}  workspace_id={props.workspace_id}/> : null}
                        {props.msg === "Total reports configured" ? (
                            <ReportsConfigured from={props.from} to={props.to} close={handleClose}  workspace_id={props.workspace_id} />
                        ) : null}
                    </Paper>
                </Fade>
            </Modal>
        </>
    );
};

export default Card;
