
import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import { Delete, Edit } from "@material-ui/icons";
import clsx from "clsx";
import WarningPopup from "common/WarningPopup";
import useApi from "hooks/useApi";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,

        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        borderRadius: 10,
        padding: theme.spacing(2, 4, 3),
    },
    font15: {
        fontSize: 15,
    },
}));

export default function DBCustomerActionItems({ userId, fontSize, setEdit, setUser, setAddUserModal }) {
    const classes = useStyles();
    const [, deleteUserIsLoading, deleteUser] = useApi();
    const [, getUserIsLoading, getUser] = useApi();
    const [CancelConsentOpen, setCancelConsentOpen] = React.useState(false);

    const cancelSubscriptionConsent = () => {
        setCancelConsentOpen(true);
    };

    const getAdminUser = async () => {
        try {
            const user = await getUser({
                module: 'adminUser',
                method: 'getAdminUser',
                returnResult: true,
                apiData: {
                    userId: userId
                },
                throwError: true
            })
            setEdit(true)
            setUser(user)
            setAddUserModal(true)
        }
        catch (e) {
            throw e
        }

    }
    const deleteAdminUser = async () => {
        try {
            const user = await deleteUser({
                module: 'adminUser',
                method: 'deleteAdminUser',
                returnResult: true,
                apiData: {
                    userId: userId
                },
                successToast: "User Deleted",

            })
            setCancelConsentOpen(false)
        }
        catch (e) {
            console.log(e)
        }

    }

    return (
        <div>

            <WarningPopup openPopup={CancelConsentOpen} closePopup={() => setCancelConsentOpen(false)} headingText="Are you sure to delete this user" submitAction={deleteAdminUser} submitActionIsLoading={deleteUserIsLoading} />
            <Tooltip title="Edit User">
                {getUserIsLoading ? (
                    <CircularProgress size={15} />
                ) : (
                    <Edit
                        className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                        onClick={(e) => {
                            e.stopPropagation();
                            getAdminUser();
                        }}
                        fontSize="small"
                    />
                )}
            </Tooltip>
            <Tooltip title="Delete User">
                {deleteUserIsLoading ? (
                    <CircularProgress size={15} />
                ) : (
                    <Delete
                        className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                        onClick={(e) => {
                            e.stopPropagation();
                            cancelSubscriptionConsent()
                        }}
                        fontSize="small"
                    />
                )}
            </Tooltip>

        </div>
    );
}
