/* eslint-disable default-case */
import {
    INVOICE_LIST_LOADED,
    INVOICE_LIST_LOADING,
    LOGS_DATA_IS_LOADING,
    LOGS_DATA_LIST,
    NEO_ACCOUNTS_DATA_IS_LOADING,
    NEO_ACCOUNTS_DATA_LIST,
} from "redux/actionTypes/actionTypes";

const initialState = {
    logsData: [],
    logsDataIsLoading: false,
    neoAccountsData: [],
    neoAccountsDataIsLoading: false,
};

export const logsDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGS_DATA_LIST:
            return {
                ...state,
                logsData: action.payload,
            };
        case LOGS_DATA_IS_LOADING:
            return {
                ...state,
                logsDataIsLoading: action.payload,
            };
        case NEO_ACCOUNTS_DATA_LIST:
            return {
                ...state,
                neoAccountsData: action.payload,
            };
        case NEO_ACCOUNTS_DATA_IS_LOADING:
            return {
                ...state,
                neoAccountsDataIsLoading: action.payload,
            };

        default:
            return state;
    }
};
