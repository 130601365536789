/* eslint-disable default-case */
import { CUSTOMER_LIST_LOADED, CUSTOMER_LIST_LOADING } from "redux/actionTypes/actionTypes";

const initialState = {
    customers: [],
    customersIsLoading: false,
};

export const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_LIST_LOADED:
            return {
                ...state,
                customers: action.payload,
            };
        case CUSTOMER_LIST_LOADING:
            return {
                ...state,
                customersIsLoading: action.payload,
            };
        default:
            return state;
    }
};
