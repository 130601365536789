// Onboarding

export const PLAN_LIST_LOADED = "PLAN_LIST_LOADED";
export const ADDONS_LIST_LOADED = "ADDONS_LIST_LOADED";
export const PLAN_LIST_LOADING = "PLAN_LIST_LOADING";
export const ADDONS_LIST_LOADING = "ADDONS_LIST_LOADING";

// Customers

export const CUSTOMER_LIST_LOADED = "CUSTOMER_LIST_LOADED";
export const CUSTOMER_LIST_LOADING = "CUSTOMER_LIST_LOADING";

// Subscription

export const SUBSCRIPTION_LIST_LOADED = "SUBSCRIPTION_LIST_LOADED";
export const SUBSCRIPTION_LIST_LOADING = "SUBSCRIPTION_LIST_LOADING";

// Invoice

export const INVOICE_LIST_LOADED = "INVOICE_LIST_LOADED";
export const INVOICE_LIST_LOADING = "INVOICE_LIST_LOADING";

// User

export const SET_USER_IS_LOADING = "SET_USER_IS_LOADING";
export const SET_USER = "SET_USER";
export const ME_USER_IS_LOADING = "ME_USER_IS_LOADING";

// Dashboard
export const FETCH_CONFIGURED_REPORTS_LIST = "FETCH_CONFIGURED_REPORTS_LIST";
// export const SET_DASHBOARD_IS_LOADING = "SET_DASHBOARD_IS_LOADING"
export const SET_CONFIGURED_REPORTS_LIST_IS_LOADING = "SET_CONFIGURED_REPORTS_LIST_IS_LOADING";
export const FETCH_DATASOURCES = "FETCH_DATASOURCES";
export const FETCH_OVERALL_STATS = "FETCH_OVERALL_STATS";
export const FETCH_OVERALL_RUN_STATS = "FETCH_OVERALL_RUN_STATS";
export const FETCH_ROWS_MOVED_BY_DATE = "FETCH_ROWS_MOVED_BY_DATE";
export const SET_ROWS_MOVED_BY_DATE_IS_LOADING = "SET_ROWS_MOVED_BY_DATE_IS_LOADING";
export const SET_RECENT_ACTIVITIES_IS_LOADING = "SET_RECENT_ACTIVITIES_IS_LOADING";
export const FETCH_RECENT_ACTIVITIES = "FETCH_RECENT_ACTIVITIES";
export const SET_OVERALL_STATS_IS_LOADING = "SET_OVERALL_STATS_IS_LOADING";
export const SET_OVERALL_RUN_STATS_IS_LOADING = "SET_OVERALL_RUN_STATS_IS_LOADING";
export const SET_DATASOURCES_STATS_IS_LOADING = "SET_DATASOURCES_STATS_IS_LOADING";
export const FETCH_DATA_MOVED_BY_DATE = "FETCH_DATA_MOVED_BY_DATE";
export const SET_DATA_MOVED_BY_DATE_IS_LOADING = "SET_DATA_MOVED_BY_DATE_IS_LOADING";
export const SET_TABLE_SCHEMA_IS_LOADING = "SET_TABLE_SCHEMA_IS_LOADING";
export const SET_TABLE_SCHEMA = "SET_TABLE_SCHEMA";
export const SET_REVERSE_SYNC_REPORTS_LIST_IS_LOADING = "SET_REVERSE_SYNC_REPORTS_LIST_IS_LOADING";
export const FETCH_REVERSE_SYNC_REPORTS_LIST = "FETCH_REVERSE_SYNC_REPORTS_LIST";
export const SET_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING = "SET_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING";
export const FETCH_REVERSE_ROWS_MOVED_BY_DATE = "FETCH_REVERSE_ROWS_MOVED_BY_DATE";
export const FETCH_DATA_DESTINATIONS = "FETCH_DATA_DESTINATIONS";
export const SET_DATA_DESTINATIONS_STATS_IS_LOADING = "SET_DATA_DESTINATIONS_STATS_IS_LOADING";
export const DATASOURCE_LIST_LOADED = "DATASOURCE_LIST_LOADED";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const FETCH_EDIT_LOGS = "FETCH_EDIT_LOGS";
export const SET_EDIT_LOGS_IS_LOADING = "SET_EDIT_LOGS_IS_LOADING";
export const TRANSFORMATIONS_LIST_LOADED = "TRANSFORMATIONS_LIST_LOADED";
export const SET_TRANSFORMATIONS_IS_LOADING = "SET_TRANSFORMATIONS_IS_LOADING";
export const SET_DBT_TRANSFORMATIONS_LOADING = "SET_DBT_TRANSFORMATIONS_LOADING";
export const SET_DBT_TRANSFORMATIONS = "SET_DBT_TRANSFORMATIONS";
export const SET_ORCHESTRATIONS_LOADING = "SET_ORCHESTRATIONS_LOADING";
export const SET_ORCHESTRATIONS = "SET_ORCHESTRATIONS";
export const SET_WORKSPACES_IS_LOADING = "SET_WORKSPACES_IS_LOADING";
export const SET_WORKSPACES = "SET_WORKSPACES";

// Logs Data
export const LOGS_DATA_LIST = "LOGS_DATA_LIST";
export const LOGS_DATA_IS_LOADING = "LOGS_DATA_IS_LOADING";

export const NEO_ACCOUNTS_DATA_LIST = "NEO_ACCOUNTS_DATA_LIST";
export const NEO_ACCOUNTS_DATA_IS_LOADING = "NEO_ACCOUNTS_DATA_IS_LOADING";

export const NEO_USER_DATA_LIST = "NEO_USER_DATA_LIST";
export const NEO_USER_DATA_IS_LOADING = "NEO_USER_DATA_IS_LOADING";
