import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import AdminUsersTable from './AdminUsersTable'
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddAdminUserForm from './AddAdminUserForm';

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#FAFAFA",
    },
    root: {
        flexGrow: 1,
        marginLeft: 30,
        marginRight: 20,
    },
    tabPanel: {
        backgroundColor: "#FAFAFA",
    },
    tab: {
        textTransform: "capitalize",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 10
    },
}));
const AdminUser = () => {
    const classes = useStyles()
    const [addUserModal, setAddUserModal] = React.useState(0)
    const [edit, setEdit] = React.useState(false)
    const [user, setUser] = React.useState(null)

    return (
        <Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={addUserModal}
                onClose={() => setAddUserModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disablePortal
            >
                <Fade in={addUserModal} unmountOnExit>
                    <Grid
                        className={classes.paper}
                        container
                        item
                        alignItems='center'
                        justifyContent="center"
                        style={{ width: "40vw", height: "50vh", overflowY: "scroll" }}
                    >
                        <Grid container item xs={10} justifyContent='center'>
                            {/* <Typography variant='h3' style={{ fontSize: '18px', marginBottom: 16 }}>{edit ? `Add User` : `Update User`}</Typography> */}

                            <Grid container item xs={10} justifyContent='center'>
                                <Grid container item xs={12} justifyContent='center'>
                                    <AddAdminUserForm user={user} edit={edit} setEdit={setEdit} setUser={setUser} setAddUserModal={setAddUserModal} />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Fade>
            </Modal>
            <Grid container xs={12} justifyContent='center'>
                <Grid style={{ marginTop: 20 }}>
                    <Button variant='contained' color='primary' style={{ borderRadius: 40 }} onClick={() => {
                        setEdit(false)
                        setAddUserModal(true)
                    }}>Add New Admin User</Button>
                </Grid>

            </Grid>
            <Grid container xs={12} justifyContent='center'>
                <AdminUsersTable setEdit={setEdit} setUser={setUser} setAddUserModal={setAddUserModal} />
            </Grid>
        </Grid>
    )
}

export default AdminUser;