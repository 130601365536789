import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TransformTable from "../../../../common/Table/TranformTable";
import { Grid, makeStyles, Switch } from "@material-ui/core";
// import Shuffle from "../../assets/images/transformations/shuffle.svg"
// import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
// import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchDbtTransformations } from "redux/actions/dashboardActions";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useApi from "hooks/useApi";
import { warehouseSlugImgMapper } from "constants/warehouses";
import Image from "common/UIComponents/Image";
import CloudOutlinedIcon from "@material-ui/icons/CloudOutlined";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
const moment = require("moment-timezone");
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        transition: "initial",
        transitionDuration: "0.2",
        marginRight: 4,
        fontSize: 17,
        "&:hover": {
            cursor: "pointer",
            fontSize: 20,
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
    icon: {
        height: 24,
        width: 24,
    },
}));

const CustomSwitch = withStyles({
    switchBase: {
        color: "#A5AEB7",
        "&$checked": {
            color: "#23BE82",
        },
        "&$checked + $track": {
            backgroundColor: "#23BE82",
        },
    },
    checked: {},
    track: {},
})(Switch);

export default function TransformationTable({ tableData, account_id }) {
    const classes = useStyles();
    const timezone = "Asia/Kolkata";
    const dispatch = useDispatch();
    const [reportLoading, setReportLoading] = React.useState(null);

    const [, statusLoading, editProject] = useApi();

    const handleStausChange = async (checked, dbtTransformationId) => {
        let status = "";
        if (checked) {
            status = "ACTIVE";
        } else {
            status = "PAUSED";
        }
        const data = {
            status,
        };
        setReportLoading(dbtTransformationId);
        await editProject({
            module: "accountDetails",
            method: "editDbtProject",
            apiData: {
                dbtTransformationId: dbtTransformationId,
                data: {
                    data: data,
                    entity: "status",
                },
                account_id: account_id,
            },
            returnResult: true,
            successToast: "DBT project updated successfully.",
        });
        dispatch(fetchDbtTransformations(account_id));
        setReportLoading(null);
    };

    const columns = [
        {
            Header: "Name",
            accessor: "name",
            sortable: false,
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center">
                    <Grid container>
                        {/* <Link to={`/transformations/dbt/edit/${props.row.original.id}`}> */}
                        <Typography
                            className={false ? "hoverable" : null}
                            // onClick={() =>
                            // 	editPermission
                            // 		? history.push(`/transformations/edit/${props.row.original.id}`)
                            // 		: null
                            // }
                            variant="subtitle1"
                        >
                            {props.row.original.name}
                        </Typography>
                        {/* </Link> */}
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Created On",
            accessor: "created_at",
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">
                            {moment(props.row.original.created_at).tz(timezone).format("YYYY-MM-DD, hh:mm:ss A")}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Last run status",
            accessor: "last_run_status",
            sortable: true,
            Filter: false,
            Actions: (props) => <></>,
            Cell: (props) => (
                <Grid>
                    <Typography variant="subtitle1">
                        {props.row.original.last_run_status === null
                            ? props.row.original.no_of_runs === 0
                                ? "No run initiated"
                                : "Running"
                            : props.row.original.last_run_status}
                    </Typography>
                </Grid>
            ),
        },
        {
            Header: "Last run time",
            Filter: false,
            accessor: "last_run_timestamp",
            sortable: true,
            sortType: (a, b, name, desc) => {
                var a1 = new Date(a.values.last_run_timestamp).valueOf();
                var b1 = new Date(b.values.last_run_timestamp).valueOf();
                if (a.values.last_run_timestamp === null) {
                    if (!desc) {
                        a1 = new Date("01-01-2500").valueOf();
                        console.log(a1);
                    } else {
                        a1 = new Date("01-01-1800").valueOf();
                    }
                }
                if (b.values.last_run_timestamp === null) {
                    if (!desc) {
                        b1 = new Date("01-01-2500").valueOf();
                    } else {
                        b1 = new Date("01-01-1800").valueOf();
                    }
                }
                console.log(a1);
                if (desc) {
                    return a1 > b1 ? 1 : -1;
                }
                return a1 < b1 ? -1 : 1;
            },
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center" style={{ minWidth: 100 }}>
                    <Typography variant="subtitle1">
                        {props.row.original.last_run_timestamp === null
                            ? "No run initiated"
                            : moment(props.row.original?.last_run_timestamp).format("DD-MMM-YYYY hh:mm:ss A")}
                    </Typography>
                </Grid>
            ),
        },
        {
            Header: "Warehouse",
            accessor: "warehouse",
            Filter: false,
            sortable: false,
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center">
                    <Grid container alignItems="center">
                        <Image
                            src={warehouseSlugImgMapper["redshift"]}
                            alt={"redshift"}
                            className={classes.icon}
                            name={"redshift"}
                        />
                        <Typography variant="subtitle1">{props.row.original?.data_warehouse?.name}</Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Repo & Branch",
            accessor: "branch",
            Filter: false,
            sortable: false,
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center">
                    <Grid container alignItems="center">
                        <CloudOutlinedIcon style={{ fontSize: 14, margin: "0px 5px" }} />
                        <Typography variant="subtitle1">
                            {props.row.original?.configuration_json?.configuration?.repository?.name}
                        </Typography>
                        <FolderOpenOutlinedIcon style={{ fontSize: 14, margin: "0px 5px" }} />

                        <Typography variant="subtitle1">
                            {props.row.original?.configuration_json?.configuration?.branch?.name}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Status",
            Filter: false,
            sortable: false,
            Cell: (props) => {
                return (
                    <Grid style={{ minWidth: 130 }} container justify="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.project_status}</Typography>
                        {statusLoading && props.row.original.id === reportLoading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <CustomSwitch
                                checked={props.row.original.project_status !== "PAUSED"}
                                onChange={(e) => handleStausChange(e.target.checked, props.row.original.id)}
                                size="small"
                            />
                        )}
                    </Grid>
                );
            },
            accessor: "project_status",
            // Filter: SelectColumnFilter,
            // filter: 'includes',
        },
        // {
        //     Header: "Actions",
        //     Filter: false,
        //     Cell: (props) => (
        //         <Grid container style={{ minWidth: 100 }}>
        //             <DBTTransformationActionButtons transformationId={props.row.original.id} />
        //         </Grid>
        //     ),
        //     sortable: false,
        // },
    ];
    const [data, setData] = React.useState(null);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <Box>
            <TransformTable
                columns={columns}
                data={tableData}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                link="/transformations/dbt/add"
                showCreateBtn={false}
            />
        </Box>
    );
}
