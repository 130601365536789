import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Paper, CircularProgress, LinearProgress } from "@material-ui/core";
import CustomerTable from "./CustomerTable";
import useApi from "hooks/useApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../../redux/actions/customerActions";

const UseStyle = makeStyles((theme) => ({
    root: {},
}));

const Customers = () => {
    const classes = UseStyle();
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customer);

    React.useEffect(() => {
        dispatch(fetchCustomers());
    }, []);

    if (customers.customersIsLoading && customers.customers.length === 0) {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container className={classes.root} direction="row" alignItems="flex-start" justifyContent="flex-start">
            <Grid container item xs={12} style={{ paddingLeft: 40, paddingRight: 40 }} justifyContent="center">
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    {customers.customersIsLoading ? <LinearProgress /> : null}
                </Grid>
                <CustomerTable tableData={customers.customers} />
            </Grid>
        </Grid>
    );
};

export default Customers;
