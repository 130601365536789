const config = {
  frontendUrl: process.env.REACT_APP_FRONTEND_URL,
  backendUrl: process.env.REACT_APP_DC_BACKEND_URL,
  authUrl: process.env.REACT_APP_AUTH_URL,
  responseType: process.env.REACT_APP_RESPONSE_TYPE,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL,
  scopes: process.env.REACT_APP_SCOPES,
  dataApiUrl: process.env.REACT_APP_DATA_API_URL,
  imageBaseUrl: process.env.REACT_APP_IMAGE_BASE_URL,
  reverseApiUrl: process.env.REACT_APP_REVERSE_API_URL,
  redirectUrlForOauth:
    process.env.REACT_APP_FRONTEND_URL + "oauth/generic/callback",
};

export default config;
