import React from 'react'
import { Grid, Paper } from "@material-ui/core"
import TransformationTable from "./TransformationTable"

import { useDispatch, useSelector } from "react-redux"
import { fetchTransformations } from "../../../../redux/actions/dashboardActions"
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
// import {SchedulingComponent} from "../common/Scheduling/Scheduling"
import NoData from "../../../../common/NoData/NoData"

const Transform = (props) => {
    const transformations = useSelector(state => state.dashboard.transformations)
    const isLoading = useSelector(state => state.dashboard.isLoading)
    const dispatch = useDispatch();
    console.log(transformations)

    //fetch data on the first mount if not fethched before
    React.useEffect(() => {
        dispatch(fetchTransformations(props.account_id,props.workspace_id))
    }, [])

    //loading indicator
    if (isLoading && transformations?.length === 0) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        )
    }

    if (transformations && transformations?.length === 0) {
        return (
            <NoData link="/transformations/add" showButton={true} height={"85vh"} componentType="transformation" />
        )
    }
    if (transformations === undefined) {
        return <Grid></Grid>
    }

    return (
        <>
            <Grid className="marginBottom" container justify="center" xs={12}>

                <Grid item className="pageContainer" xs={11}>
                    {/* <Paper elevation={0}> */}
                    {isLoading ? <LinearProgress /> : null}
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography className="size16" variant="h3" >
                            Transformations
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TransformationTable tableData={transformations} account_id={props.account_id} />
                    </Grid>
                    {/* </Paper> */}
                </Grid>
            </Grid>
            {/* <SchedulingComponent /> */}
        </>
    )
}

export default Transform
