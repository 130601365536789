import React from "react";
import Grid from "@material-ui/core/Grid";
import { getToken } from "services/Api/api";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { getUser } from '../../services/Api/api'
import config from "constants/config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../redux/actions/userActions";

const Callback = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const meLoading = useSelector(state => state.user.meUserIsLoading)
    const [loading, setLoading] = React.useState(false);
    const params = new URLSearchParams(location.search);

    React.useEffect(() => {
        (async function asyncCallback() {
            try {
                setLoading(true);
                const res = await getToken(params.get("authorization_code"));
                axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.access_token}`;
                console.log(res.data);
                const user = await getUser();
                dispatch({ type: "SET_USER", payload: user.data.data });
                Cookies.set("accessToken", res.data.access_token);
                Cookies.set("refreshToken", res.data.refresh_token);
                Cookies.set("expiresIn", new Date().getTime() + res.data.expires_in * 1000);
                setLoading(false);
                navigate("/");
            } catch (e) {
                console.log(e);
                setLoading(false);
                window.localStorage.clear();
                window.location.href = `${config.authUrl}auth?grant_type=${config.responseType}&client_id=${config.clientId}&redirect_url=${config.redirectUrl}&scopes=${config.scopes}`;
            }
        })();
    }, []);
    if (loading || meLoading) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "100vh", width: "100vw" }}>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <Grid container justify="center" alignItems="center" style={{ height: "100vh" }}>
            <CircularProgress />
        </Grid>
    );
};

export default Callback;
