import React from "react";

export default function Image(props) {
	return (
		<img
			style={props.style}
			className={props.className}
			src={props.src}
			onError={(e) => {
				e.target.onerror = null;
				e.target.src = `https://ui-avatars.com/api/?name=${props.name}&background=FD9567&color=fff`;
			}}
			alt={props.alt ? props.alt : "icon"}
		/>
	);
}
