import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class UserEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `admin_app`;
        this.transformer = null;
    }
    getUser(data) {
        return axios.get(`${this.resourceEndpoint}/me`);
    }
    getVerificationLink(data){
        return axios.post(`${this.resourceEndpoint}/email_verfication_link`,data.apiData)
    }

}

export default UserEndpoints;
