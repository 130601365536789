import { Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	Legend,
	Brush
} from "recharts";

const useStyles = makeStyles(() => ({
	tabs: {
		marginBottom: 1,
		marginTop: 5
	},
	tab: {
		marginBottom: 40
	}
}));

const parseData = (data) => {
	let parsedData = [];
	data.forEach((el) => {
		parsedData.push({
			name: el.datasource_name,
			reports: el.num_reports
		});
	});
	return parsedData;
};

export default function DataModel({ close }) {
	const dashboard = useSelector((state) => state.dashboard);
	const dataMovedArray = parseData(dashboard.dataSources);
	return (
		<Grid container justify="center" className="padding" style={{ padding: 20 }}>
			<Grid item xs={12}>
				<Grid container justify="space-between" alignItems="center">
					<Grid item>
						<Typography className="size16 boldText">Data Pipelines Configured</Typography>
					</Grid>
					<Grid item>
						<CloseIcon className="hoverable" onClick={() => close()} fontSize="small" />
					</Grid>
				</Grid>

				<Grid container justify="center" style={{ margin: "40px 0px" }}>
					<Grid item>
						<ResponsiveContainer width={700} height={320}>
							<BarChart
								data={dataMovedArray}
								margin={{
									top: 5,
									right: 30,
									left: 20,
									bottom: 55
								}}
							>
								<CartesianGrid vertical={false} />
								<XAxis
									label={{
										value: "Data Sources",
										position: "insideBottom",
										offset: -50
									}}
									tick={{
										fontFamily: "Nunito",
										fontSize: "10px",
										fill: "#2C2C2C"
									}}
									stroke="#EDEDED"
									tickLine={false}
									dataKey="name"
									interval={0}
									angle={-40}
									textAnchor="end"
								/>
								<YAxis
									tickCount={5}
									tick={{
										fontFamily: "Nunito",
										fontSize: "9px",
										fill: "#2C2C2C"
									}}
									stroke="#EDEDED"
									label={{
										value: "Data Size (In MB)",
										angle: -90,
										position: "insideLeft",
										offset: 10
									}}
									tickLine={false}
								/>
								<Tooltip
									cursor={false}
									formatter={(value, name, props) =>
										name === "reports"
											? [value, "Pipelines Configured", props]
											: [value, name, props]
									}
								/>
								{/* <Legend /> */}
								<Bar dataKey="reports" fill="#00B5CC" barSize={23} />
								{/* <Brush dataKey="dataMoved" height={30} stroke="#8884d8" /> */}
							</BarChart>
						</ResponsiveContainer>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
