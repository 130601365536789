import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import InvoicesTable from "../InvoicesTable";
import useApi from "hooks/useApi";
import { useParams } from "react-router";

const UseStyle = makeStyles((theme) => ({
    root: {},
}));

const Invoicess = (props) => {
    const classes = UseStyle();
    let { customer_id } = useParams();
    if (props.tab) {
        customer_id = props.customerId;
    }
    console.log(customer_id);
    const [invoices, invoiceIsLoading, fetchInvoices] = useApi(
        React.useMemo(
            () => ({
                initialValues: [],
                module: "subscription",
                method: "getInvoicesForACustomer",
                apiData: {
                    customer_id: customer_id,
                },
            }),
            []
        )
    );

    if (invoiceIsLoading) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    if (!invoiceIsLoading && invoices) {
        return (
            <Grid container className={classes.root} direction="row" alignItems="flex-start" justifyContent="center">
                <Grid container xs={12} style={{ paddingLeft: 40, paddingRight: 40 }} justifyContent="center">
                    <Grid item xs={12}>
                        <InvoicesTable tableData={invoices} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    return <Grid></Grid>;
};

export default Invoicess;
