import BaseApiTransformation from "../DataTransformations/BaseApiTransformation";
class BaseEndpointsClass {
	async makeRequest(data) {
		const baseTransformer = new BaseApiTransformation();
		if (this[data.method] === undefined) {
			throw new Error("data.method not present in data for api call");
		}
		let preTransformedData = data;
		if (this.transformer !== null && this.transformer[`${data.method}PreTransformer`] !== undefined) {
			preTransformedData = this.transformer[`${data.method}PreTransformer`](data);
		}
		preTransformedData = baseTransformer.preApiCallTransformation(preTransformedData);
		const response = await this[data.method](preTransformedData);
		let postTransformedData = baseTransformer.postApiCallTransformation(response, data);
		if (this.transformer !== null && this.transformer[`${data.method}PostTransformer`] !== undefined) {
			postTransformedData = this.transformer[`${data.method}PostTransformer`](postTransformedData, data);
		}
		return postTransformedData;
	}
}

export default BaseEndpointsClass;
