import { Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { fetchDataMovedByDate } from "redux/actions/dashboardActions";

import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Brush,
} from "recharts";
const moment = require("moment-timezone");

const useStyles = makeStyles(() => ({
    tabs: {
        marginBottom: 1,
        marginTop: 5,
    },
    tab: {
        marginBottom: 40,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const parseData = (data, unit) => {
    let parsedData = [];
    data.forEach((el) => {
        parsedData.push({
            name: el.datasource_name,
            dataMoved:
                unit === "MB"
                    ? (el.data_moved / 1000000).toFixed(2)
                    : (el.data_moved / 1000000000).toFixed(2),
        });
    });
    return parsedData;
};

const parseDateData = (data, unit, timezone) => {
    let parsedData = [];
    data.forEach((el) => {
        parsedData.push({
            date: moment(el.date)?.format("dddd MM DD YYYY"),
            dataMoved:
                unit === "MB"
                    ? (el.data_moved / 1000000).toFixed(2)
                    : (el.data_moved / 1000000000).toFixed(2),
        });
    });
    return parsedData;
};

export default function DataModel({ close, from, to, account_id,workspace_id }) {
    const classes = useStyles();
    const timezone = useSelector((state) => state?.users?.timezone);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [currentUnit, setCurrentUnit] = React.useState("MB");
    const dashboard = useSelector((state) => state.dashboard);
    const dataMovedArray = parseData(dashboard.dataSources, currentUnit);
    const theme = useTheme();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const dataMovedByDate = parseDateData(
        dashboard.dataMovedByDate,
        currentUnit,
        timezone
    );
    React.useEffect(() => {
        const toString = moment(from).format("YYYY-MM-DD") + " 23:59:59";
        const fromString = moment(to).format("YYYY-MM-DD") + " 00:00:00";
        const queryString = `?from_date=${fromString}&to_date=${toString}`;
        dispatch(fetchDataMovedByDate(queryString, account_id,workspace_id));
    }, []);
    return (
        <Grid container justify="center" className="padding" style={{ padding: 20 }}>
            <Grid item xs={12}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography className="size16 boldText">
                            Data Moved
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CloseIcon
                            className="hoverable"
                            onClick={() => close()}
                            fontSize="small"
                        />
                    </Grid>
                </Grid>
                {/* tabs */}
                <Box className="tabs">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        centered={false}
                        className={classes.tabs}
                    >
                        <Tab label="By Datasource" {...a11yProps(0)} />
                        <Tab label="By Date" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                {/* 1st Tab Pannel */}
                <TabPanel
                    className={classes.tab}
                    value={value}
                    index={0}
                    dir={theme.direction}
                >
                    <Grid container spacing={1} style={{ marginLeft: 15 }}>
                        <Grid item>
                            <Button
                                onClick={() => setCurrentUnit("GB")}
                                variant="contained"
                                size="small"
                                color="primary"
                            >
                                <Typography
                                    style={{ color: "white" }}
                                    variant="subtitle2"
                                >
                                    In GB
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => setCurrentUnit("MB")}
                                variant="contained"
                                size="small"
                                color="primary"
                            >
                                <Typography
                                    style={{ color: "white" }}
                                    variant="subtitle2"
                                >
                                    In MB
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid item>
                            <ResponsiveContainer width={700} height={320}>
                                <BarChart
                                    data={dataMovedArray}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 55,
                                    }}
                                >
                                    <CartesianGrid vertical={false} />
                                    <XAxis
                                        label={{
                                            value: "Data Sources",
                                            position: "insideBottom",
                                            offset: -50,
                                        }}
                                        tick={{
                                            fontFamily: "Nunito",
                                            fontSize: "10px",
                                            fill: "#2C2C2C",
                                        }}
                                        stroke="#EDEDED"
                                        tickLine={false}
                                        dataKey="name"
                                        interval={0}
                                        angle={-40}
                                        textAnchor="end"
                                    />
                                    <YAxis
                                        tickCount={5}
                                        tick={{
                                            fontFamily: "Nunito",
                                            fontSize: "9px",
                                            fill: "#2C2C2C",
                                        }}
                                        stroke="#EDEDED"
                                        label={{
                                            value: `Data Size (In ${currentUnit})`,
                                            angle: -90,
                                            position: "insideLeft",
                                            offset: 10,
                                        }}
                                        tickLine={false}
                                    />
                                    <Tooltip
                                        cursor={false}
                                        formatter={(value, name, props) =>
                                            name === "dataMoved"
                                                ? [value, "Data Moved", props]
                                                : [value, name, props]
                                        }
                                    />
                                    {/* <Legend /> */}
                                    <Bar
                                        dataKey="dataMoved"
                                        fill="#00B5CC"
                                        barSize={23}
                                    />
                                    {/* <Brush dataKey="d ataMoved" height={30} stroke="#8884d8" /> */}
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid>
                    </Grid>
                </TabPanel>

                {/* 2nd Tab Pannel */}
                <TabPanel
                    className={classes.tab}
                    value={value}
                    index={1}
                    dir={theme.direction}
                >
                    <Grid container spacing={1} style={{ marginLeft: 15 }}>
                        <Grid item>
                            <Button
                                onClick={() => setCurrentUnit("GB")}
                                variant="contained"
                                size="small"
                                color="primary"
                            >
                                <Typography
                                    style={{ color: "white" }}
                                    variant="subtitle2"
                                >
                                    In GB
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => setCurrentUnit("MB")}
                                variant="contained"
                                size="small"
                                color="primary"
                            >
                                <Typography
                                    style={{ color: "white" }}
                                    variant="subtitle2"
                                >
                                    In MB
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid item style={{ position: "relative", top: 30 }}>
                            <ResponsiveContainer
                                width={700}
                                height={360}
                                style={{ top: 30 }}
                            >
                                <BarChart
                                    data={dataMovedByDate}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 55,
                                    }}
                                >
                                    <CartesianGrid vertical={false} />
                                    <XAxis
                                        label={{
                                            value: "Date",
                                            position: "insideBottom",
                                            offset: 10,
                                        }}
                                        tick={{
                                            fontFamily: "Nunito",
                                            fontSize: "10px",
                                            fill: "#2C2C2C",
                                        }}
                                        stroke="#EDEDED"
                                        tickLine={false}
                                        dataKey="date"
                                        interval={0}
                                        angle={-40}
                                        textAnchor="end"
                                        height={90}
                                    />
                                    <YAxis
                                        tickCount={5}
                                        tick={{
                                            fontFamily: "Nunito",
                                            fontSize: "9px",
                                            fill: "#2C2C2C",
                                        }}
                                        stroke="#EDEDED"
                                        label={{
                                            value: `Data Moved(In ${currentUnit})`,
                                            angle: -90,
                                            position: "insideLeft",
                                            offset: 10,
                                        }}
                                        tickLine={false}
                                    />
                                    <Tooltip
                                        cursor={false}
                                        formatter={(value, name, props) =>
                                            name === "dataMoved"
                                                ? [value, "Data Moved", props]
                                                : [value, name, props]
                                        }
                                    />
                                    {/* <Legend /> */}
                                    <Brush
                                        data={dataMovedByDate}
                                        dataKey="dataMoved"
                                        height={20}
                                        stroke="#00B5CC"
                                    />
                                    <Bar
                                        dataKey="dataMoved"
                                        fill="#00B5CC"
                                        barSize={23}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    );
}
