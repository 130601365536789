import React from "react";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import DetailsIcon from "@material-ui/icons/Details";
import { Navigate, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Link } from "react-router-dom";
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,
        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
}));

export default function SubscriptionActionButtons({ subscription_id, fontSize }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const fetchSubscriptionId = async () => {
        navigate(`/subscription/${subscription_id}`);
    };

    return (
        <div>
            <CheckForPermissionHOC requiredPermissions={["usage.view"]}>

                <Tooltip title="Subscription details">
                    <DetailsIcon
                        className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                        onClick={(e) => {
                            e.stopPropagation();
                            fetchSubscriptionId();
                        }}
                        fontSize="small"
                    />
                </Tooltip>
            </CheckForPermissionHOC>
        </div>
    );
}
