import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "common/Table/Table";
import { Grid } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import SubscriptionActionButtons from "common/ActionButtonGroups/SubscriptionActionButtons";
import SelectColumnFilter from "common/SelectFilter";

export default function SubscriptionTable({ tableData }) {
    const navigate = useNavigate()
    const columns = [
        {
            Header: "Subscription Number",
            accessor: "subscription_number",
            Filter: true,
            sortable: true,
        },
        {
            Header: "Name",
            accessor: "customer_name",
            sortable: true,
            Cell: (props) => (
                <Grid style={{ minWidth: 150, cursor: 'pointer' }} container justify="space-between" alignItems="center" onClick={() => navigate(`/subscription/${props.row.original.subscription_id}`)}>
                    <Grid container>
                        <Typography variant="subtitle1">{props.row.original.customer_name}</Typography>
                    </Grid>
                </Grid>
            ),
        },

        {
            Header: "Activated On",
            accessor: "activated_at",
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <Grid style={{ minWidth: 150 }} container justify="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">
                            {moment(props.row.original.created_time).format("YYYY-MM-DD")}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Amount",
            accessor: "amount",
            Filter: true,
            sortable: true,
        },
        {
            Header: "Next Billing Date",
            accessor: "next_billing_at",
            Filter: false,
            sortable: true,
        },
        {
            Header: "Status",
            accessor: "status",
            Filter: SelectColumnFilter,
            sortable: true,
        },
        {
            Header: "Plan",
            accessor: "plan_name",
            Filter: false,
            sortable: true,
        },
        {
            Header: "Actions",
            Filter: false,
            Cell: (props) => (
                <Grid container style={{ minWidth: 75 }} alignItems="center">
                    {console.log(props.row.original.subscription_id)}
                    <SubscriptionActionButtons subscription_id={props.row.original.subscription_id} />
                    {/* <MenuIcon className={classes.icon} fontSize="small" />
                        <ScheduleOutlinedIcon className={classes.icon} fontSize="small" /> */}
                </Grid>
            ),
            sortable: false,
        },
    ];
    const [data, setData] = React.useState(null);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <Box>
            <Table
                columns={columns}
                data={tableData}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
            />
        </Box>
    );
}
