import React, { useState } from "react";
import { Grid, Typography, makeStyles, Paper, Button, CircularProgress, TextField } from "@material-ui/core";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import useApi from "hooks/useApi";
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";
const useStyles = makeStyles((theme) => ({
    left: {
        backgroundColor: "#FBFBFB",
        padding: 10,
    },
    right: {
        backgroundColor: "white",
        padding: 20,
        borderRadius: 10,
    },
    button: {
        backgroundColor: "red",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const Overview = (props) => {
    const classes = useStyles();
    console.log(props.subscription);
    const customer = props.subscription?.subscription_data?.subscription?.customer;
    const subscription = props.subscription?.subscription_data?.subscription;
    const [trialDays, setTrialDays] = useState(0);
    const [openExtendTrialModal, setOpenExtendTrialModal] = useState(false);
    const [CancelConsentOpen, setCancelConsentOpen] = useState(false);
    const [, extentTrialIsLoading, setExtendTrialDays] = useApi();
    const [, subscriptionCancelIsLoading, cancelSubscription] = useApi();
    const cancelSubscriptionConsent = () => {
        setCancelConsentOpen(true);
    };
    const cancelSubscriptionFunc = async () => {
        console.log("cancel");
        try {
            await cancelSubscription({
                module: "subscription",
                method: "cancel",
                successToast: "Subscription Cancelled",
                apiData: {
                    subscription_id: subscription.subscription_id,
                },
                throwError: true,
            });
            setCancelConsentOpen(false);
        } catch (e) {
            console.log(e);
        }
    };

    const extendTrialDays = async () => {
        try {
            await setExtendTrialDays({
                module: "subscription",
                method: "extendTrial",
                successToast: "Trial Extended",
                apiData: {
                    subscription_id: subscription.subscription_id,
                    trialDays: trialDays,
                },
                throwError: true,
            });
            setOpenExtendTrialModal(false);
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <Grid container justify="center">
            <Grid container item xs={11}>
                <Grid item xs={4} className={classes.left}>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openExtendTrialModal}
                        onClose={() => setOpenExtendTrialModal(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        disablePortal
                    >
                        <Fade in={openExtendTrialModal} unmountOnExit>
                            <Grid
                                className={classes.paper}
                                container
                                justifyContent="center"
                                style={{ width: "30vw", height: "25vh", overflowY: "scroll" }}
                            >
                                <Grid item xs={12} style={{ marginBotton: 30 }}>
                                    <Typography variant="h3"> Extend Trial Days</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        label="number of days"
                                        variant="outlined"
                                        type="number"
                                        value={trialDays}
                                        onChange={(e) => setTrialDays(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" onClick={() => extendTrialDays()}>
                                        {extentTrialIsLoading ? (
                                            <CircularProgress
                                                style={{
                                                    marginRight: 10,
                                                    color: "white",
                                                }}
                                                size={16}
                                            />
                                        ) : null}
                                        Extend
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={CancelConsentOpen}
                        onClose={() => setCancelConsentOpen(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        disablePortal
                    >
                        <Fade in={CancelConsentOpen} unmountOnExit>
                            <Grid
                                className={classes.paper}
                                container
                                justifyContent="center"
                                style={{ width: "30vw", height: "25vh", overflowY: "scroll" }}
                            >
                                <Grid item xs={12} style={{ marginBotton: 30 }}>
                                    <Typography variant="h3"> Are you sure to cancel this subscription</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ marginBotton: 30 }}>
                                    <Typography variant="body1">Subscription will be set to non renewing</Typography>
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <CheckForPermissionHOC requiredPermissions={["subscription.cancel"]}>

                                            <Button variant="contained" fullWidth color="primary" onClick={cancelSubscriptionFunc}>
                                                {subscriptionCancelIsLoading ? (
                                                    <CircularProgress
                                                        style={{
                                                            marginRight: 10,
                                                            color: "white",
                                                        }}
                                                        size={16}
                                                    />
                                                ) : null}
                                                Yes
                                            </Button>
                                        </CheckForPermissionHOC>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            onClick={() => setCancelConsentOpen(false)}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Fade>
                    </Modal>
                    <Grid item xs={12} style={{ padding: 10 }}>
                        <Grid>
                            <Typography variant="h3">
                                Company name -<span style={{ fontWeight: 500 }}> {customer?.company_name}</span>{" "}
                            </Typography>
                            <Typography variant="body1">{customer.display_name}</Typography>
                            <Typography variant="body1">{customer.email}</Typography>
                        </Grid>
                        <Grid style={{ marginTop: 30 }}>
                            <Typography variant="h3"> Subscripton Options </Typography>
                            <Grid style={{ marginTop: 15 }}>
                                <Grid container>
                                    <Grid item>
                                        <Typography variant="body1">Subscription ID</Typography>
                                    </Grid>
                                    <Grid item style={{ marginLeft: 10 }}>
                                        <Typography variant="substitle1">{subscription.subscription_id}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <Typography variant="body1">Subscription No.</Typography>
                                    </Grid>
                                    <Grid item style={{ marginLeft: 10 }}>
                                        <Typography variant="substitle1">{subscription.subscription_number}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ marginTop: 30 }}>
                            <Typography variant="h3"> Other Details</Typography>
                            <Grid item container style={{ marginTop: 15 }}>
                                <Grid container>
                                    <Grid item>
                                        <Typography variant="body1"> Repeat Every</Typography>
                                    </Grid>
                                    <Grid item style={{ marginLeft: 10 }}>
                                        <Typography variant="substitle1">
                                            {subscription.interval} {subscription.interval_unit}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item>
                                        <Typography variant="body1"> Activation Date </Typography>
                                    </Grid>
                                    <Grid item style={{ marginLeft: 10 }}>
                                        <Typography variant="substitle1">
                                            {" "}
                                            {moment(subscription.activated_at).format("Do MMM YYYY")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={8} justifyContent="center" alignItems="start" className={classes.right}>
                    <Grid container item xs={12} justifyContent="space-evenly">
                        <Grid item>
                            {" "}
                            <Typography variant="h6" style={{ fontSize: 16, marginBottom: 10 }}>
                                Amount
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: "500" }}>
                                {subscription.amount}
                            </Typography>
                            <Typography variant="subtitle1">
                                {subscription.interval} {subscription.interval_unit}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {" "}
                            <Typography variant="h6" style={{ fontSize: 16, marginBottom: 10 }}>
                                Next Billing Date
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: "500" }}>
                                {subscription.next_billing_at}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {" "}
                            <Typography variant="h6" style={{ fontSize: 16, marginBottom: 10 }}>
                                Status
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: "500" }}>
                                {subscription.status}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid container item xs={12}>
                            <Typography variant="h3" style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}>
                                Plan and addons
                            </Typography>
                            <Grid
                                container
                                justifyContent="space-between"
                                style={{ backgroundColor: "#FBFBFB", borderBottom: "1px grey solid", padding: 5 }}
                                alignItems="center"
                                xs={12}
                            >
                                <Grid item xs={4}>
                                    <Typography variant="h3" style={{ width: 200 }}>
                                        Plan details
                                    </Typography>{" "}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h3" align="center" style={{ minWidth: 150 }}>
                                        Amount
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h3" align="center" style={{ minWidth: 150 }}>
                                        Features
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                style={{ marginTop: 15, padding: 10 }}
                                alignItems="center"
                                xs={12}
                            >
                                <Grid item xs={4}>
                                    <Typography variant="body1" style={{ margin: 0, width: 200 }}>
                                        {props.subscription.plan_type === "custom" ? "Custom" : props.subscription.plan_name}
                                    </Typography>{" "}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1" style={{ minWidth: 150 }} align="center">
                                        ${props.subscription.plan_price}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" align="right" style={{ maxWidth: 200 }}>
                                        {props.subscription.features.join(" , ")}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {props?.subscription?.addons?.length !== 0 && (
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    style={{
                                        backgroundColor: "#FBFBFB",
                                        marginTop: 30,
                                        borderBottom: "1px grey solid",
                                        padding: 5,
                                    }}
                                    alignItems="center"
                                    xs={12}
                                >
                                    {" "}
                                    <Grid item xs={4}>
                                        <Typography variant="h3" style={{ width: 200 }}>
                                            Addons details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h3" align="center" style={{ minWidth: 150 }}>
                                            Amount
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h3" align="center" style={{ minWidth: 150 }}>
                                            Features
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {props?.subscription?.addons?.map((ele) => {
                                return (
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        style={{ marginTop: 30, padding: 10 }}
                                        alignItems="center"
                                        xs={12}
                                    >
                                        {" "}
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ margin: 0, width: 200 }}>
                                                {ele.type === "custom" ? "Custom" : ele.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" align="center" style={{ minWidth: 150 }}>
                                                $ {ele.addon_price}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography
                                                variant="body1"
                                                align="center"
                                                style={{ minWidth: 150, fontSize: 12, fontWeight: 500 }}
                                            >
                                                {ele.features[0]}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                            <Grid container justifyContent="flex-end" style={{ marginTop: 30 }}>
                                <Grid item xs={4}>
                                    <Typography variant="h5" align="right">
                                        {" "}
                                        Total Amount &nbsp;
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h5" style={{ fontWeight: 600 }} align="left">
                                        {" "}
                                        - $ {subscription.amount}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ borderTop: "1px solid #000", marginTop: 20 }}>
                        <Typography variant="h3" style={{ fontSize: 20, marginBottom: 15, marginTop: 30 }}>
                            Actions{" "}
                        </Typography>
                        <Grid container item xs={12} justifyContent="space-between">
                            <Grid container item xs={4} justifyContent="flex-end" alignItems="start">
                                <CheckForPermissionHOC requiredPermissions={["subscription.cancel"]}>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={
                                            subscription.status === "non_renewing" || subscription.status === "cancelled"
                                                ? true
                                                : false
                                        }
                                        className={classes.button}
                                        onClick={cancelSubscriptionConsent}
                                    >
                                        Cancel Subscription
                                    </Button>
                                </CheckForPermissionHOC>
                            </Grid>
                            <Grid container item xs={4} justifyContent="flex-end" alignItems="start">
                                <CheckForPermissionHOC requiredPermissions={["subscription.edit"]}>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => setOpenExtendTrialModal(true)}
                                    >
                                        Extend Trial
                                    </Button>
                                </CheckForPermissionHOC>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Overview;
