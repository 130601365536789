import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MailIcon from "@material-ui/icons/Mail";
import Tooltip from "@material-ui/core/Tooltip";

import VisibilityIcon from "@material-ui/icons/Visibility";
import clsx from "clsx";
import useApi from "hooks/useApi";
import { Link } from "react-router-dom";
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,
        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
}));

export default function InvoiceActionButtons({ invoiceId, customerId, isPending, fontSize = undefined }) {
    const classes = useStyles();
    const [invoice, invoideIsLoading, fetchInvoice] = useApi();
    const [invoiceMail, invoiceMailIsLoading, sendInvoiceMail] = useApi();
    const invoicePreview = async (invoiceId) => {
        try {
            const invoiceRes = await fetchInvoice({
                module: "subscription",
                method: "getInvoice",
                apiData: {
                    invoice_id: invoiceId,
                },
                returnResult: true,
            });
            console.log(invoiceRes);
            if (invoiceRes?.invoice?.invoice_url) {
                window.open(invoiceRes?.invoice?.invoice_url);
            }
        } catch (e) { }
    };
    const sendInvoiceMailFunc = async (invoiceId) => {
        try {
            await sendInvoiceMail({
                module: "subscription",
                method: "sentInvoiceMail",
                apiData: {
                    invoice_id: invoiceId,
                },
                successToast: "Email sent",
            });
        } catch (e) { }
    };
    return (
        <div>
            <CheckForPermissionHOC requiredPermissions={["invoice.edit"]}>

                {isPending && <Tooltip title="Add Usage">
                    <Link to={`/invoices/addUsage/${invoiceId}/${customerId}`}>
                        <EditOutlinedIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            fontSize="small"
                        />
                    </Link>
                </Tooltip>}
            </CheckForPermissionHOC>
            <CheckForPermissionHOC requiredPermissions={["invoice.send"]}>

                <Tooltip title="Send Invoice">
                    {invoiceMailIsLoading ? (
                        <CircularProgress size={15} />
                    ) : (
                        <MailIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                sendInvoiceMailFunc(invoiceId);
                            }}
                        />
                    )}
                </Tooltip>
            </CheckForPermissionHOC>
            <CheckForPermissionHOC requiredPermissions={["invoice.view"]}>

                <Tooltip title="Preview Invoice">
                    {invoideIsLoading ? (
                        <CircularProgress size={15} />
                    ) : (
                        <VisibilityIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                invoicePreview(invoiceId);
                            }}
                        />
                    )}
                </Tooltip>
            </CheckForPermissionHOC>
        </div>
    );
}
