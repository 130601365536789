import { Typography, LinearProgress, CircularProgress } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import DataSourceTable from "./DataSourceTable";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ReportTable from "./ReportTable";
import { fetchReportLogs } from "../../../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import NoData from "../../../../common/NoData/NoData";
import { AnimatePresence, motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        padding: 20,
        marginBottom: 30,
        minHeight: 300,
    },
}));

/**
 *
 * @prop - from, to
 * @component {This component renders either DATASOURCE Table or REPORT Table based on "table" state.}
 */

const ForwardStats = ({ from, to, account_id,workspace_id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => state.dashboard);
    const [datasourceSlug, setDatasourceSlug] = React.useState(null);
    const [table, setTable] = React.useState("DATASOURCE");

    /**
     *
     * @param {*} datasource_slug
     * @function - This function fetches Report Logs and changes "table" state in order to display Report Table.
     */

    const tableNavClick = (datasource_slug) => {
        setTable("REPORT");
        setDatasourceSlug(datasource_slug);
    };

    /**
     *
     * @param - none
     * @function - This function empties Report Logs and changes "table" state in order to display Datasource Table.
     */

    const goback = () => {
        setTable("DATASOURCE");
        dispatch({ type: "FETCH_CONFIGURED_REPORTS_LIST", payload: [] });
        setDatasourceSlug(null);
    };

    /**
     * @function - This useEffect fetches Report Logs on component mount(if datasourceSlug exists) and whenever date range is changed.
     */

    React.useEffect(() => {
        if (datasourceSlug !== null) {
            const toString = from.toISOString().split("T")[0] + " 23:59:59";
            const fromString = to.toISOString().split("T")[0] + " 00:00:00";
            const queryString = `?from_date=${fromString}&to_date=${toString}`;
            const data = {
                queryString,
                datasource_slug: datasourceSlug,
            };
            dispatch(fetchReportLogs(data, account_id,workspace_id));
        }
    }, [from, to,workspace_id]);

    React.useEffect(() => {
        if (datasourceSlug !== null && table === "REPORT") {
            const toString = from.toISOString().split("T")[0] + " 23:59:59";
            const fromString = to.toISOString().split("T")[0] + " 00:00:00";
            const queryString = `?from_date=${fromString}&to_date=${toString}`;
            const data = {
                queryString,
                datasource_slug: datasourceSlug,
            };
            dispatch(fetchReportLogs(data, account_id,workspace_id));
        }
    }, [table]);
    const prepareData = (datasourceSlug) => {
        const toString = from.toISOString().split("T")[0] + " 23:59:59";
        const fromString = to.toISOString().split("T")[0] + " 00:00:00";
        const queryString = `?from_date=${fromString}&to_date=${toString}`;
        const data = {
            queryString,
            datasource_slug: datasourceSlug,
        };
        return data
    }

    return (
        <Grid className={classes.tableContainer}>
            {table === "DATASOURCE" ? (
                <Typography className="size16" variant="h3">
                    Data Sources
                </Typography>
            ) : (
                <Grid container>
                    <KeyboardBackspaceIcon style={{ marginRight: 10 }} className="hoverable" onClick={() => goback()} />
                    <Typography variant="h3">Data Pipelines</Typography>
                </Grid>
            )}
            {dashboard.dataSources.length === 0 ? (
                <NoData
                    link="/datasource"
                    showButton={true}
                    height={300}
                    componentType="datasource"
                    routeData={{ tab: "exploreConnectors" }}
                />
            ) : (
                <AnimatePresence>
                    {table === "DATASOURCE" ? (
                        <DataSourceTable tableNavClick={tableNavClick} account_id={account_id} />
                    ) : (
                        <motion.div key="collapsed">
                            {dashboard.configuredReportsIsLoading && dashboard.configuredReports.length === 0 ? (
                                <Grid container justify="center" alignItems="center" style={{ height: 500 }}>
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                <>
                                    {dashboard.configuredReportsIsLoading ? (
                                        <>
                                            <LinearProgress />
                                        </>
                                    ) : null}

                                    <ReportTable datasourceSlug={datasourceSlug} tableNavClick={tableNavClick} account_id={account_id} logsData={prepareData(datasourceSlug)} workspace_id={workspace_id} />
                                </>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </Grid>
    );
};

export default ForwardStats;
