import React, { useState } from "react";
import { Select, TextField } from "@material-ui/core";
import { Typography, MenuItem, Grid, Button } from "@material-ui/core";

const SelectPlan = (props) => {
    const [plan, setPlan] = useState(null);
    const [planPrice, setPlanPrice] = useState(null);
    const handleClick = (event) => {
        const myValue = event.target.value;
        console.log(event.target);
        setPlan(event.target.value);
        props.setUserData({
            ...props.userData,
            plan: myValue,
            isSelect: true,
        });
    };
    const onPlanPriceChange = (e) => {
        if (!isNaN(e.target.value)) {
            setPlanPrice(e.target.value);
        }
    };
    const onNext = () => {
        props.setUserData({
            ...props.userData,
            isSelect: true,
            planPrice: planPrice,
        });
        props.setActiveStep(2);
    };

    return (
        <Grid item container xs={12} spacing={2} direction="row" justifyContent="center" alignItems="center">
            <Grid container item xs={12}>
                <Typography variant="h5" align="center">
                    Select a plan
                </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="center" spacing={1}>
                <Select
                    fullWidth
                    inputProps={{ "aria-label": "Without label", "margin-top": "20px" }}
                    variant="outlined"
                    style={{
                        backgroundColor: "#FFFFFF",
                    }}
                    label="Plan"
                    name="plan"
                    margin="dense"
                    onChange={handleClick}
                    value={plan}
                >
                    {props.planItemPrice?.map((e) => {
                        return (
                            <MenuItem value={e.zoho_plan_code}>
                                {e.name} - ${e.price}
                            </MenuItem>
                        );
                    })}
                </Select>
                <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="price"
                    style={{ height: "40px" }}
                    onChange={(e) => onPlanPriceChange(e)}
                    fullWidth
                    value={planPrice}
                ></TextField>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Button variant="contained" color="primary" fullWidth onClick={() => props.setActiveStep(0)}>
                        Previous
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" color="primary" fullWidth onClick={() => onNext()}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SelectPlan;
