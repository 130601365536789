import React from 'react'
import { Link } from "react-router-dom"
import MaUTable from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Button from '@material-ui/core/Button'
import CircularProgress from "@material-ui/core/CircularProgress"
import Pagination from '@material-ui/lab/Pagination';


import {
    usePagination,
    useRowSelect,
    useTable,
} from 'react-table'
import { matchSorter } from 'match-sorter'
import { TextField, Typography, Grid, makeStyles } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    tableHead: {
        backgroundColor: "#FAFAFA"
    },
    tableRow: {
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: "#FAFAFA"
        }
    },
    pagination: {
        marginTop: 10,
        marginBottom: 10,
        marginRight: 10
    },
    tableCellContainer: {
        paddingRight: 15,
        paddingLeft: 15,
    }
}))



const EnhancedTable = ({
    columns,
    data,
    updateMyData,
    skipPageReset,
    link,
    showCreateBtn,
    count,
    tableLoading,
    onPageChange,
    currentPage,
    onFilterChange,
    perPage,
    onPageSizeChange
}) => {

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageReset,
            updateMyData,
        },
        usePagination,
        useRowSelect,
    )

    const handleChangePage = (event, newPage) => {
        onPageChange(newPage)
        // gotoPage(newPage - 1)
    }

    const handleChangeRowsPerPage = event => {
        onPageSizeChange(Number(event.target.value))
        setPageSize(Number(event.target.value))
    }

    const classes = useStyles()


    // Render the UI for your table
    return (
        <> <Grid item xs={4} style={{ marginTop: 20 }}>
            <Grid container alignItems="center">
                <Grid item xs={2}>
                    <Typography className="boldText" variant="h6">Filter : </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        style={{ backgroundColor: "#FFFFFF" }}
                        id="outlined-basic"
                        label="Search Query here"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => onFilterChange(e.target.value)}
                        margin="dense"
                    />
                </Grid>
            </Grid>
        </Grid>
            <Grid container justify="flex-end">
                <TableFooter className="fullWidth">
                    <Grid container justify="space-between">

                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <TableRow>
                                        <TablePagination
                                            className="transtable"
                                            style={{ paddingRight: 20, borderRight: "unset" }}
                                            rowsPerPageOptions={[
                                                5,
                                                10,
                                                25,
                                            ]}
                                            colSpan={3}
                                            count={data.length}
                                            rowsPerPage={perPage}
                                            page={pageIndex}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            labelDisplayedRows={() => `Showing ${(currentPage - 1) * pageSize}-${(currentPage) * pageSize > count ? count : (currentPage) * pageSize} of ${count !== -1 ? count : `more than ${(currentPage + 1) * pageSize}`} records`}
                                        />
                                    </TableRow>
                                </Grid>
                                {showCreateBtn ? (
                                    <Grid item>
                                        <Link to={link}>
                                            <Button style={{ marginTop: 7 }} variant="contained" color="primary">Create New</Button>
                                        </Link>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </TableFooter>
            </Grid>
            <TableContainer>
                {tableLoading ? (
                    <Grid container justify="center" alignItems="center" style={{ height: 400, width: "100%" }}>
                        <CircularProgress />
                    </Grid>
                ) : count === 0 ? (
                    <Grid container justify="center" alignItems="center" style={{ height: 400, width: "100%" }}>
                        <Typography className="graytext" variant="subtitle1">No logs data present.</Typography>
                    </Grid>
                ) :
                    <MaUTable {...getTableProps()}>
                        <TableHead className={classes.tableHead}>
                            {headerGroups.map((headerGroup, i) => (
                                <TableRow key={i} style={{ height: 50 }} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, i) => (
                                        <TableCell
                                            key={i}
                                            {...(!column.sortable
                                                ? column.getHeaderProps()
                                                : column.getHeaderProps(column.getSortByToggleProps()))}
                                        >
                                            <Grid container>
                                                {column.render('Header')}
                                                {column.sortable ? (
                                                    <TableSortLabel
                                                        active={column.isSorted}
                                                        // react-table has a unsorted state which is not treated here
                                                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                    />
                                                ) : null}
                                            </Grid>
                                            {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <TableRow key={i} className={classes.tableRow} {...row.getRowProps()} hover>
                                        {row.cells.map((cell, i) => {
                                            return (
                                                <TableCell key={i} className={classes.tableCellContainer} {...cell.getCellProps()}>
                                                    <Typography style={{ textAlign: "right" }} variant="subtitle1">{cell.render('Cell')}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </MaUTable>
                }
                <Grid container justify="flex-end">
                    <TableFooter>
                        <TableRow>
                            <Pagination className={classes.pagination} size="small" count={parseInt(count / perPage) + 1} page={currentPage} color="primary" onChange={handleChangePage} />
                        </TableRow>
                    </TableFooter>
                </Grid>
            </TableContainer>
        </>
    )
}

EnhancedTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
}

export default EnhancedTable
